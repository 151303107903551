<template>
  <div
    v-if="item.progress === statusMap.PROGRESS"
    class="bg-fill-warning text-spot-warning flex flex-nowrap items-center gap-0.5 rounded-full p-1"
  >
    <IconWrapper :icon="IconTaskProgressSharp" :size="16" />
    <div class="caption-1 pt-0.5 leading-[12px] whitespace-nowrap">
      {{ progressPercent }}
    </div>
  </div>
  <ToolTip
    v-else-if="Object.keys(statusMap).length > 1"
    :tooltip-text="tooltipText"
    class="cursor-pointer overflow-hidden rounded-full"
  >
    <IconWrapper
      :class="progressIconWrapperBackground"
      class="p-1"
      :icon="progressIcon"
      :size="16"
      :fill="progressIconFill"
    />
  </ToolTip>
</template>
<script setup>
import IconTaskProgressSharp from '@/assets/icons/custom/misc/IconTaskProgressSharp.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  statusMap: {
    type: Object,
    required: true,
  },
});

const progressPercent = computed(() => {
  return (
    (props.item?.progress_percentage * 100).toLocaleString('de-DE', {
      maximumFractionDigits: 0,
    }) + '%'
  );
});

const progressIconWrapperBackground = computed(() => {
  switch (props.item?.progress) {
    case props.statusMap.SUCCESS:
      return 'bg-fill-success';
    case props.statusMap.ERROR:
      return 'bg-fill-error';
    case props.statusMap.PROGRESS:
      return 'bg-fill-warning';
    default:
      return '';
  }
});

const progressIcon = computed(() => {
  switch (props.item?.progress) {
    case props.statusMap.SUCCESS:
      return 'task_alt';
    case props.statusMap.ERROR:
      return 'error';
    case props.statusMap.PROGRESS:
      return 'layers';
    default:
      return '';
  }
});

const progressIconFill = computed(() => {
  switch (props.item?.progress) {
    case props.statusMap.SUCCESS:
      return 'text-spot-success';
    case props.statusMap.ERROR:
      return 'text-spot-error';
    case props.statusMap.PROGRESS:
      return 'text-spot-warning';
    default:
      return '';
  }
});

const tooltipText = computed(() => {
  switch (props.item?.progress) {
    case props.statusMap.SUCCESS:
      return 'Berechnung erfolgreich abgeschlossen';
    case props.statusMap.ERROR:
      return `Berechnung konnte nicht\nerfolgreich abgeschlossen werden`;
    case props.statusMap.PROGRESS:
      return 'Szenario wird berechnet';
    default:
      return '';
  }
});
</script>
