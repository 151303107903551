<template>
  <div>
    <LandingPageHeader />
    <div class="default-layout">
      <RouterView v-slot="{ Component }">
        <Transition :name="getTransitionName" mode="out-in">
          <div v-if="Component" class="flex flex-col gap-5">
            <component :is="Component" />
          </div>
          <slot v-else />
        </Transition>
      </RouterView>
    </div>
  </div>
</template>

<script setup>
import LandingPageHeader from '@/apps/landing-page/components/LandingPageHeader.vue';
import { usePageTransitionStore } from '@/composables/usePageTransitionStore';

const { getTransitionName } = usePageTransitionStore();
</script>

<style lang="scss">
.default-layout {
  min-height: calc(100vh - 222px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  position: relative;

  @media (max-width: 1440px) {
    margin: 0 20px;
  }

  @media (min-width: 1441px) {
    width: 1440px;
    margin: 0 auto;
  }
}

.enable-x-scroll {
  @media (max-width: 1440px) {
    overflow-x: auto;
    width: 1440px;
  }
}
</style>
