<template>
  <div
    class="standard-elevation-2 flex w-full items-center rounded-md bg-white p-2"
  >
    <input
      v-model="location"
      type="text"
      placeholder="Suchbegriff"
      class="bg-infra-$gray-highlight w-full focus:outline-hidden"
      @input="toggleSearch"
    />
    <div
      v-if="!location"
      class="pointer-events-none flex h-6 w-6 items-center justify-center"
    >
      <ZoomIcon />
    </div>
    <div
      v-else
      class="flex h-6 w-6 cursor-pointer items-center justify-center"
      @click="closeSearchbar"
    >
      <VIcon>mdi-close</VIcon>
    </div>
  </div>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core';
import { removeMarker } from '@/mapbox/main';
import ZoomIcon from '@/assets/icons/ZoomIcon.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const location = ref(null);

watch(
  () => props.mapStore.showSearch,
  (newVal) => {
    if (!newVal) {
      location.value = null;
    }
  },
);

function toggleSearch() {
  if (!props.mapStore.showSearch) {
    props.mapStore.setSearchbar(true);
  }
  searchLocation();
}

const searchLocation = useDebounceFn(() => {
  props.mapStore.fetchSearchResults(location.value);
}, 500);

function closeSearchbar() {
  props.mapStore.resetSearch();
  location.value = null;
  removeMarker();
}
</script>
