import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import i18n from './vue-i18n';
import { VDataTable, VDataTableServer } from 'vuetify/components/VDataTable';

export default createVuetify({
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
    locale: 'de',
  },
  components: {
    VDataTable,
    VDataTableServer,
  },
});
