<template>
  <div
    v-if="panelData"
    class="standard-elevation-2 animate-none overflow-visible rounded-md bg-white"
    :style="panelPositions['infoPanel']"
  >
    <div v-show="show">
      <div
        class="hide-scrollbar relative flex w-[360px] flex-col items-start gap-2.5 overflow-y-auto rounded-md p-2.5"
        :style="{ maxHeight: containerHeight - 160 + 'px' }"
      >
        <InfoPanelHeader
          :map-store
          :panel-name="panelData.name"
          :updated-info="panelData.updatedInfo"
          :panel-data="panelData"
          @close-click="show = false"
        >
          <template #after-title>
            <slot name="after-title" />
          </template>
        </InfoPanelHeader>
        <InfoPanelSubtitles
          :map-store
          :subtitles="panelData.subtitles"
          :feature="feature"
        />
        <InfoPanelContent
          :feature="feature"
          :panel-data="panelData"
          @close-info-panel="show = false"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import InfoPanelContent from '@/apps/features/map/info-panel/InfoPanelContent.vue';
import InfoPanelHeader from '@/apps/features/map/info-panel/InfoPanelHeader.vue';
import InfoPanelSubtitles from '@/apps/features/map/info-panel/InfoPanelSubtitles.vue';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { usePanel } from '@/composables/usePanel';

const props = defineProps({
  feature: Object,
  featureId: [Number, String],
  mapStore: {
    type: Object,
    required: true,
  },
  panelObjects: {
    type: Array,
    required: true,
  },
});

const show = ref(false);
const featureData = ref(null);

const { panelPositions, togglePanel } = usePanel('infoPanel', true);

watch(show, () => {
  togglePanel();
});

const { year: scenarioYear, refreshFeature } = storeToRefs(props.mapStore);

const { height: containerHeight } = useWindowSize();

const panelData = ref(null);
const panel = ref(null);

const panelsByLayerId = {};
for (const panelObject of props.panelObjects) {
  for (const layerId of panelObject.layerIds) {
    panelsByLayerId[layerId] = panelObject;
  }
}

watch(
  [() => props.feature, refreshFeature],
  async ([newFeature]) => {
    // new feature was clicked or feature refresh was triggered by feature patch
    if (!newFeature) {
      show.value = false;
      return;
    }
    panel.value = panelsByLayerId[newFeature.layer.id];
    if (!panel.value) {
      console.log('no panel found for layer with id ', newFeature.layer.id);
      return;
    }
    featureData.value = await panel.value.requestFeatureData(
      newFeature,
      props.mapStore,
    );
    props.mapStore.setClickedFeatureData(
      newFeature.layer.id,
      featureData.value,
    );
  },
  { immediate: true },
);

watch(
  [featureData, scenarioYear],
  async (
    [newFeatureData, newScenarioYear],
    [oldFeatureData, oldScenarioYear],
  ) => {
    // feature data or scenario year changed, thus recalculate panel data
    if (newFeatureData === null) return;
    if (newFeatureData === oldFeatureData && !show.value) {
      return;
    }
    panelData.value = panel.value.calculatePanelData(
      newFeatureData,
      newScenarioYear,
      props.mapStore,
    );
    panelData.value.id = props.featureId;
    show.value = true;
  },
);
</script>

<style>
.info-panel-more-options {
  padding: 8px 5px;
  width: 100%;
  &:hover {
    background-color: var(--color-infra-background);
  }
}
</style>
