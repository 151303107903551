<template>
  <div class="flex w-full flex-col gap-4">
    <div
      v-if="showTabHeaders"
      class="border-disabled-neutral border-b-[2px]"
      :class="{ 'mx-2': !hideTabWindows }"
    >
      <VTabs
        v-model="activeTab"
        :items="filteredTabs"
        class="text-neutral mb-[-2px]"
        :slider-color="activeColor"
        density="compact"
      >
        <template #tab="{ item: tabItem }">
          <VTab
            :value="tabItem"
            class="px-5 tracking-normal normal-case"
            :ripple="false"
            :disabled="isTabDisabled(tabItem.index)"
          >
            <div
              class="flex items-center justify-center gap-2"
              :class="
                activeTab.title === tabItem.title
                  ? 'text-color1'
                  : 'text-title-neutral'
              "
            >
              <IconWrapper
                v-if="tabItem.icon"
                :icon="tabItem.icon"
                :type="tabItem.iconType"
              />
              <Component
                :is="props.customElementTag"
                :class="computedClass(tabItem)"
              >
                {{ tabItem.title }}
                <slot :name="`post-tab-title-${tabItem.index}`" />
              </Component>
            </div>
          </VTab>
        </template>
      </VTabs>
    </div>
    <VTabsWindow
      v-if="!hideTabWindows"
      v-model="activeTab.title"
      :class="customWindowClasses"
      :style="computedStyle"
    >
      <template v-for="(tabContent, index) in tabContents" :key="index">
        <VTabsWindowItem
          :transition="!disableTransitions"
          :reverse-transition="!disableTransitions"
          :value="tabContent.value"
          class="vtab-item-custom"
          :eager
        >
          <slot :name="tabContent.slotName" />
        </VTabsWindowItem>
      </template>
    </VTabsWindow>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  showTabHeaders: {
    type: Boolean,
    default: true,
  },
  customWindowClasses: {
    type: String,
    default: '',
  },
  disableTransitions: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
  tabsWindowHeightSubstract: {
    type: Number,
    default: 330,
  },
  maxHeightIsHeight: {
    type: Boolean,
    default: false,
  },
  preventWindowScroll: {
    type: Boolean,
    default: false,
  },
  hideTabAtIndex: {
    type: [Number, Array],
    default: null,
  },
  hideTabWindows: {
    type: Boolean,
    default: false,
  },
  customElementTag: {
    type: String,
    default: 'div',
  },
  disableTabIndexes: {
    type: [Array, null],
    default: null,
  },
});

defineExpose({ setActiveTab });

const style = getComputedStyle(document.documentElement);
const activeColor = style.getPropertyValue('--color-color1');

const activeTab = ref(props.tabs[0]);

const isTabDisabled = (index) => {
  return (
    Array.isArray(props.disableTabIndexes) &&
    props.disableTabIndexes.includes(index)
  );
};

function setActiveTab(tab = null) {
  if (tab) {
    activeTab.value = tab;
  } else if (activeTab.value !== props.tabs[0]) {
    activeTab.value = props.tabs[0];
  }
}

const tabContents = computed(() => {
  // Check if hideTabAtIndex is an array or a single number
  const hiddenIndexes = Array.isArray(props.hideTabAtIndex)
    ? props.hideTabAtIndex
    : [props.hideTabAtIndex];

  return Array.from({ length: props.tabs.length }, (_, index) => {
    const slotName = `slot${String(index)}`;
    return {
      value: props.tabs[index].title,
      slotName: slotName,
      isVisible: !hiddenIndexes.includes(index),
    };
  }).filter((tab) => tab.isVisible);
});

const emit = defineEmits(['updated:tab']);

// watch activeTab and emit event
watch(
  activeTab,
  async (newVal) => {
    emit('updated:tab', newVal);

    await nextTick();

    if (!props.preventWindowScroll) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  },
  { immediate: true },
);

watch(
  () => props.disableTabIndexes,
  (newDisabledIndexes) => {
    const disabledIndexes = Array.isArray(newDisabledIndexes)
      ? newDisabledIndexes
      : [];
    const activeTabs = props.tabs.filter(
      (_, index) => !disabledIndexes.includes(index),
    );
    if (activeTabs.length > 0) activeTab.value = activeTabs[0];
  },
  { immediate: true }, // Run logic on component mount
);

const filteredTabs = computed(() => {
  // Check if hideTabAtIndex is an array or a single number
  const hiddenIndexes = Array.isArray(props.hideTabAtIndex)
    ? props.hideTabAtIndex
    : [props.hideTabAtIndex];

  return props.tabs
    .map((tab, index) => ({ ...tab, index }))
    .filter((_, index) => !hiddenIndexes.includes(index));
});

const computedStyle = computed(() => {
  const heightValue = `calc(100vh - ${props.tabsWindowHeightSubstract}px)`;
  const height = props.maxHeightIsHeight ? heightValue : '100%';

  return {
    height,
    maxHeight: height,
  };
});

const computedClass = (tabItem) => {
  if (props.customElementTag.startsWith('h')) {
    return 'pb-[1px]';
  }
  return activeTab.value.title === tabItem.title
    ? 'subtitle-2 pt-[3px]'
    : 'body-2 pt-[3px]';
};
</script>

<style lang="scss" scoped>
.vtab-item-custom {
  overflow-y: auto;
  padding: 8px;
  height: 100%;
}
</style>
