<template>
  <div v-if="loaded" class="relative h-screen w-screen">
    <LayerPanel v-if="!mapStore.searchResults" v-show="showMainMenu" :map-store>
      <template #layer-toggles="slotProps">
        <RestrictionFilterToggle v-bind="slotProps" />
      </template>
    </LayerPanel>

    <MapView2MapActions :map-store />
    <BaseMap
      :map-store
      :selected-analysis-id="analysisSelected"
      :layer-config="LayerConfig"
      :panel-objects="panelObjects"
      :fly-to-function="flyToBBox"
      :extend-left-click-functionality="highlightProject"
      @map-style-loaded-signal="onMapStyleLoad()"
    >
      <template #after-title>
        <IconWrapper
          v-if="mapStore.clickedFeatureData.layerId === LAYER_KEY__PV_PROJECT"
          :icon="IconGotoChart"
          :size="20"
          fill="text-color1"
          hover="hover:text-active-color1"
          class="cursor-pointer"
          @click="navigateToDetailProject()"
        />
        <IconWrapper
          v-if="
            mapStore.clickedFeatureData.layerId ===
            LAYER_KEY__CLUSTER_PV_PROJECT
          "
          :icon="IconGotoChart"
          :size="20"
          fill="text-color1"
          hover="hover:text-active-color1"
          class="cursor-pointer"
          @click="navigateToDetailCluster()"
        />
        <DeleteDialog
          v-if="
            mapStore.clickedFeatureData.layerId === LAYER_KEY__EXTERNAL_PROJECT
          "
          :id="mapStore.clickedFeatureData.id"
          :content-text="
            'Referenzen geclusterter externer Projekte gehen verloren.' +
            '\nDieser Vorgang kann nicht rückgängig gemacht werden.'
          "
          name-deleted-item="Externes Projekt"
          :dto="ExternalProjectDto"
          @instance-removed="deleteExternalProject()"
        >
          <IconWrapper
            :icon="'delete_forever'"
            :size="20"
            fill="text-color1"
            hover="hover:text-active-color1"
            class="cursor-pointer"
          />
        </DeleteDialog>
      </template>
    </BaseMap>
    <div class="top-controls absolute w-full">
      <ControlBar :map-store />
      <AnalysisSelector
        v-if="mapStore.selectedAnalysis && mapStore.selectedAnalysis.id"
        v-model="analysisSelected"
        :items="analyses"
        :map-store
        :disabled-callback="(item) => item.progress !== 'success'"
        @update:expanded="startFetchingAnalysis(true)"
      >
        <template #before-title>
          <SlideContainer
            ref="filterContainerEl"
            title="Filter"
            :prevent-close-from-outside="true"
          >
            <template #activator="{ openModal, modalIsOpen }">
              <ButtonEl
                icon="filter_alt"
                class="standard-elevation-0-dark h-10 w-10 rounded-sm"
                :color="
                  modalIsOpen
                    ? 'color1'
                    : projectFilterActive || clusterFilterActive
                      ? 'success'
                      : 'color2'
                "
                @click="openModal"
              />
            </template>
            <template #header-action>
              <div class="mr-auto">
                <ToolTip
                  v-if="
                    (filterTab.id === 'pv-project' && projectFilterActive) ||
                    (filterTab.id === 'cluster' && clusterFilterActive)
                  "
                  tooltip-text="Filter zurücksetzen"
                >
                  <IconWrapper
                    icon="restart_alt"
                    fill="text-color1"
                    class="cursor-pointer"
                    @click="resetFilter"
                  />
                </ToolTip>
                <h4 v-else class="text-color1">(inaktiv)</h4>
              </div>
            </template>
            <FormTabs
              :disable-transitions="true"
              :prevent-window-scroll="true"
              :tabs="[
                {
                  title: 'Einzelprojekte',
                  icon: 'all_out',
                  id: 'pv-project',
                },
                {
                  title: 'Cluster',
                  icon: 'bubble_chart',
                  id: 'cluster',
                },
              ]"
              :eager="true"
              custom-element-tag="h4"
              @updated:tab="filterTab = $event"
            >
              <template #post-tab-title-0>
                <div
                  v-if="projectFilterActive"
                  class="bg-success absolute top-1 right-2 h-2 w-2 rounded-full"
                />
              </template>
              <template #post-tab-title-1>
                <div
                  v-if="clusterFilterActive"
                  class="bg-success absolute top-1 right-2 h-2 w-2 rounded-full"
                />
              </template>
              <template #slot0>
                <FilterProject
                  ref="filterProjectEl"
                  :municipalities
                  :filter-analysis-in-map="mapStore.selectedAnalysis.id"
                  @apply:filter="applyFilterOnMap($event, 'project')"
                />
              </template>
              <template #slot1>
                <FilterCluster
                  ref="filterClusterEl"
                  :municipalities
                  :filter-analysis-in-map="mapStore.selectedAnalysis.id"
                  @apply:filter="applyFilterOnMap($event, 'cluster')"
                />
              </template>
            </FormTabs>
          </SlideContainer>
        </template>
        <template #title-icon="{ item }">
          <TaskProgress
            :item="item.data"
            :status-map="{
              PROGRESS: 'in_progress',
            }"
          />
        </template>
        <template #title-selected>
          <div class="caption-1 flex whitespace-nowrap">Potenzialanalyse</div>
          <h5 class="max-w-60 truncate whitespace-nowrap">
            {{ analyses.find((item) => item.id === analysisSelected)?.title }}
          </h5>
        </template>
        <template #additional-info="{ item }">
          <div class="border-disabled-neutral w-full border-b"></div>
          <div class="text-neutral flex w-full flex-col gap-1.5">
            <div class="text-title-neutral flex justify-between">
              <h6>Manuelle Analyse:</h6>
              <h6 class="text-core-dark">
                {{ item.data.is_manual ? ' Ja' : ' Nein' }}
              </h6>
            </div>
            <div
              class="text-title-neutral flex items-center justify-between gap-1.5"
            >
              <h6>Status:</h6>
              <div
                class="flex gap-1 rounded-xs p-1"
                :class="getListProgressIcon(item.data.progress).color"
              >
                <IconWrapper
                  :icon="getListProgressIcon(item.data.progress).icon"
                  :size="16"
                />
                <h6>{{ getListProgressIcon(item.data.progress).label }}</h6>
              </div>
            </div>
          </div>
        </template>
      </AnalysisSelector>
      <LegendControls
        :map-store
        :show-legend="showLegend"
        @toggle-legend-state="showLegend = !showLegend"
      />
    </div>
    <div class="bottom-controls absolute w-full">
      <MapControls :map-store v-bind="$attrs" />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-2/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import { panelObjects } from '@/apps/usecase-2/map/info-panel/Info-panel-objects';
import { fitBBox } from '@/mapbox/main';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import AnalysisSelector from '@/apps/features/map/control-options/components/AnalysisSelector.vue';
import { useMapStore } from './map-store';
import axios from '@/utils/axiosHelper';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { useRoute, useRouter } from 'vue-router';
import IconGotoChart from '@/assets/icons/custom/misc/IconGotoChart.vue';
import { useToastStore } from '@/stores/toast-store';
import { LAYER_KEY__PV_PROJECT } from '@/apps/usecase-2/map/layer-config/potential-analysis';
import { LAYER_KEY__EXTERNAL_PROJECT } from '@/apps/usecase-2/map/layer-config/external-project';
import RestrictionFilterToggle from '@/apps/usecase-2/map/layer-panel/RestrictionFilterToggle.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FilterProject from '@/apps/usecase-2/result-evaluation/components/FilterProject.vue';
import FilterCluster from '@/apps/usecase-2/result-evaluation/components/FilterCluster.vue';
import { useSyncGeoMapMunicipalitySelection } from '@/composables/syncGeoMapMunicipalitySelection';
import MapView2MapActions from './components/MapView2MapActions.vue';
import { ExternalProjectDto } from '@/apps/usecase-2/map/external-project-dto';
import TaskProgress from '@/apps/features/task-progress/TaskProgress.vue';
import {
  applyProjectFilterOnMap,
  applyClusterFilterOnMap,
  highlightFeaturesProject,
  highlightFeaturesCluster,
  removeProjectFilterOnMap,
  removeClusterFilterOnMap,
  highlightProject,
  showClusterLayers,
} from '@/apps/usecase-2/map/utils';
import { LAYER_KEY__CLUSTER_PV_PROJECT } from '@/apps/usecase-2/map/layer-config/cluster';

const { municipalities } = useSyncGeoMapMunicipalitySelection();

const { showToast, hideToast } = useToastStore();
const mapStore = useMapStore();

const { mapActionActive, showMainMenu } = toRefs(mapStore);

const router = useRouter();
const route = useRoute();

const showLegend = ref(false);
const loaded = ref(false);

const filterContainerEl = ref(null);
const filterProjectEl = ref(null);
const filterClusterEl = ref(null);

async function applyFilterOnMap(event, type) {
  if (type === 'project') {
    await applyProjectFilterOnMap(event, mapStore);
    filterProjectEl.value.isLoading = false;
    showToast({
      color: 'success',
      message: 'Filter für Einzelprojekte erfolgreich angewendet',
    });
    filterContainerEl.value.closeModalFromButton();
  } else if (type === 'cluster') {
    await applyClusterFilterOnMap(event, mapStore);
    filterClusterEl.value.isLoading = false;
    showToast({
      color: 'success',
      message: 'Filter für Cluster erfolgreich angewendet',
    });
    filterContainerEl.value.closeModalFromButton();
  }
}

function resetFilter() {
  if (filterTab.value.id === 'pv-project') {
    removeProjectFilterOnMap(mapStore);
    filterProjectEl.value.resetFilterSet(true);
  } else if (filterTab.value.id === 'cluster') {
    removeClusterFilterOnMap(mapStore);
    filterClusterEl.value.resetFilterSet(true);
  }
}

onMounted(() => {
  initializeMap();
});

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
  mapStore.initializeMapActions();
}

async function flyToBBox() {
  let bbox;
  if (projectIdSelected.value) {
    const resp = await axios({
      method: 'GET',
      url: `/api/usecase-2/bbox-project/${projectIdSelected.value}/`,
    });
    bbox = resp.data.bbox;
    highlightFeaturesProject(projectIdSelected.value);
  } else if (clusterIdSelected.value) {
    showClusterLayers(mapStore);
    const resp = await axios({
      method: 'GET',
      url: `/api/usecase-2/bbox-cluster/${clusterIdSelected.value}/`,
    });
    bbox = resp.data.bbox;
    highlightFeaturesCluster(clusterIdSelected.value);
  } else {
    const resp = await axios({
      method: 'GET',
      url: `/api/usecase-2/bbox-analysis/${analysisSelected.value}/`,
    });
    bbox = resp.data.bbox;
  }
  if (bbox === null) {
    showToast({
      color: 'error',
      message: 'Keine Potenzialflächen',
    });
  } else {
    fitBBox(bbox);
  }
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

const analyses = ref([]);
await fetchAnalyses();
const analysisSelected = ref(
  Number(route.params.analysisId) ||
    Number(analyses.value.filter((e) => e.progress === 'success')[0].id),
);
const projectIdSelected = ref(Number(route.query.projectId) || null);
const clusterIdSelected = ref(Number(route.query.clusterId) || null);

async function fetchAnalyses() {
  try {
    const analysesData = await axios({
      method: 'GET',
      url: '/api/usecase-2/analysis/',
      params: { no_pagination: true },
    });
    analyses.value = analysesData.data.results.sort((a, b) => {
      const priority = { in_progress: 1, success: 2, other: 3 };
      const aPriority = priority[a.progress] || priority.other;
      const bPriority = priority[b.progress] || priority.other;

      // Then sort by priority
      if (aPriority !== bPriority) return aPriority - bPriority;

      // For "in_progress" items, sort by progress_percentage in descending order
      if (aPriority === priority.in_progress) {
        return b.progress_percentage - a.progress_percentage;
      }

      // For other items, sort alphabetically by title
      return a.title.localeCompare(b.title);
    });
  } catch (error) {
    console.error('Error fetching analyses:', error);
    showToast({
      color: 'error',
      message: 'Fehler beim Laden der Analysen',
    });
  }
}

await router.push({
  name: 'mapView2',
  params: { analysisId: analysisSelected.value },
});

mapStore.selectedAnalysis = analyses.value.find(
  (s) => s.id === analysisSelected.value,
);

if (!(mapStore.selectedAnalysis && mapStore.selectedAnalysis.id)) {
  hideToast();
  showToast({
    color: 'error',
    message: 'Potenzialanalyse existiert nicht.',
    timeOut: 5000,
  });
  router.push({ name: 'UseCase2' });
}

async function onMapStyleLoad() {
  await mapStore.fetchAndFilterBatteryOption();
}

watch(analysisSelected, async (newVal) => {
  // reset route query to prevent zoom to project on analysis change and remove filters
  projectIdSelected.value = null;
  clusterIdSelected.value = null;
  removeProjectFilterOnMap(mapStore);
  removeClusterFilterOnMap(mapStore);
  await router.push({
    name: 'mapView2',
    params: { analysisId: newVal },
  });
  mapStore.selectedAnalysis = analyses.value.find((s) => s.id === newVal);
  await flyToBBox();
  await mapStore.fetchAndFilterBatteryOption();
});
// ------------ Interval reference
const interval = ref();
startFetchingAnalysis();

function startFetchingAnalysis(immediate = false) {
  // Fetch analyses immediately if the immediate flag is true
  if (immediate) {
    fetchAnalyses();
    stopFetchingScenarios(); // Stop any existing interval
    // Start the interval to fetch analyses every 5 seconds
    interval.value = setInterval(fetchAnalyses, 5000);
  } else {
    // Start a new interval if one is not already running
    if (!interval.value) {
      interval.value = setInterval(fetchAnalyses, 5000);
    }
  }
}

function stopFetchingScenarios() {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
}

onBeforeUnmount(() => {
  hideToast();
  showMainMenu.value = false;
  mapActionActive.value = null;
  stopFetchingScenarios();
  removeProjectFilterOnMap(mapStore);
  removeClusterFilterOnMap(mapStore);
});

function getListProgressIcon(progress) {
  const progressMap = {
    success: {
      icon: 'task_alt',
      color: 'text-spot-success bg-fill-success',
      label: 'Erfolgreich',
    },
    in_progress: {
      icon: 'pending_actions',
      color: 'text-spot-warning bg-fill-warning',
      label: 'Projekt angelegt',
    },
    exited: {
      icon: 'cancel',
      color: 'text-spot-error bg-fill-error',
      label: 'Abbruch',
    },
  };

  return progressMap[progress];
}
// -------- CLUSTER AND PROJECT MAP LOGIC

const filterTab = ref({});
const projectFilterActive = computed(() => {
  const projectFilter = mapStore.filters[LAYER_KEY__PV_PROJECT];
  if (typeof projectFilter === 'undefined') return false;
  return Object.keys(projectFilter).includes('projectFilter');
});
const clusterFilterActive = computed(() => {
  const clusterFilter = mapStore.filters[LAYER_KEY__CLUSTER_PV_PROJECT];
  if (typeof clusterFilter === 'undefined') return false;
  return Object.keys(clusterFilter).includes('clusterFilter');
});
// ------------- info panel header slot actions
function navigateToDetailProject() {
  const projectId = mapStore.clickedFeatureData.id;
  router.push({
    name: 'UseCase2ProjectResultOverview',
    params: { projectId },
  });
}
function navigateToDetailCluster() {
  const clusterId = mapStore.clickedFeatureData.id;
  router.push({
    name: 'UseCase2ClusterOverview',
    params: { clusterId },
  });
}
async function deleteExternalProject() {
  mapStore.setShowInfoPanel(false);
  await mapStore.fetchExternalProjects();
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
