<template>
  <div>
    <TheLabel :label="label" :disabled="readOnly" />
    <div
      ref="dropdownRef"
      class="dropdown-multiple relative w-full"
      :class="{ 'pointer-events-none': false && sortedItems.length === 0 }"
    >
      <div
        class="text-neutral flex h-10 cursor-pointer items-center justify-between"
        :class="{
          'h-[30px]': small,
          'bg-active-area': !customBackground,
          [customBackground]: customBackground,
        }"
        @click="toggleDropdown"
      >
        <div
          class="text-neutral w-full overflow-hidden pr-20 pl-3 whitespace-nowrap"
          :class="{
            'body-2': !small,
            'body-3': small,
          }"
        >
          <span v-if="!dropdownOpen" @click.stop="toggleDropdown">{{
            placeholderText
          }}</span>
        </div>
        <DropDownIcons
          :custom-background="customBackground"
          :selected-items="selectedItemValues"
          :clearable="!readOnly && clearable"
          @remove-clicked="handleClearClick"
        />
      </div>
      <div
        v-if="dropdownOpen"
        class="dropdown-animation border-line-active-neutral absolute top-0 left-0 z-10 w-full overflow-hidden rounded-sm border-2 bg-white"
        @wheel.stop
      >
        <div
          class="text-neutral bg-subtle flex items-center justify-between py-3"
          :class="{
            'py-2!': small,
          }"
        >
          <div
            v-if="items.length !== 0"
            class="text-neutral w-full overflow-hidden pl-3 whitespace-nowrap"
            :class="{
              'body-2': !small,
              'body-3': small,
            }"
          >
            <InputEl
              :size="small ? 'm' : 'l'"
              suffix="search"
              :suffix-icon="true"
              :model-value="filter"
              class="w-full"
              @click.stop
              @update:model-value="filter = $event"
            />
          </div>

          <DropDownIcons
            :custom-background="customBackground"
            :selected-items="selectedItemValues"
            :clearable="!readOnly && clearable"
            :dropdown-open="true"
            @arrow-clicked="toggleDropdown"
            @remove-clicked="handleClearClick"
          />
        </div>
        <div
          class="overflow-y-auto"
          :style="{
            'max-height': `${maxHeightDropdown}px`,
          }"
        >
          <CheckboxEl
            v-if="optionSelectAll && sortedItems.length > 1"
            v-model="selectAll"
            border-width="2px"
            :disabled="readOnly"
            class="gap-3 px-3 py-2.5"
            @update:model-value="toggleSelectAll"
          >
            <span
              :class="{
                'body-2': !small,
                'body-3': small,
              }"
              >{{ selectAllName }}</span
            ></CheckboxEl
          >
          <div v-for="item in sortedItems" :key="item">
            <CheckboxEl
              v-model="selectedItemValues"
              :value="item"
              :disabled="
                readOnly ||
                (ensureOneSelected &&
                  selectedItemValues.length === 1 &&
                  (dataKey
                    ? item[valueKey] === selectedItemValues[0][valueKey]
                    : item === selectedItemValues[0]))
              "
              border-width="2px"
              class="gap-3 px-3 py-2.5"
            >
              <span
                :class="{
                  'body-2 pt-0.5': !small,
                  'body-3': small,
                }"
                >{{ dataKey ? item[dataKey] : item }}</span
              ></CheckboxEl
            >
            <div v-if="groupData">
              <CheckboxEl
                v-for="subItem in item.gemarkungen"
                :key="subItem.id"
                v-model="selectedSubItems"
                :value="subItem"
                border-width="2px"
                class="gap-3 px-3 py-2.5"
              >
                <span
                  :class="{
                    'body-2': !small,
                    'body-3': small,
                  }"
                  >{{ subItem }}</span
                ></CheckboxEl
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import DropDownIcons from './DropDownIcons.vue';
import TheLabel from '../label/TheLabel.vue';
import InputEl from '../input/InputEl.vue';
import CheckboxEl from '../CheckboxEl/CheckboxEl.vue';
import { onClickOutside, onKeyStroke } from '@vueuse/core';

const props = defineProps({
  small: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  items: { type: Array, default: () => [] },
  itemTypeAll: { type: String, default: 'Gemeinden' },
  allSelectedLabel: { type: String, default: '' },
  selectAllName: { type: String, default: 'Alle auswählen' },
  label: { type: String, default: null },
  customBackground: { type: String, default: null },
  initSelectAll: { type: Boolean, default: false },
  selectAllOnItemsChange: { type: Boolean, default: false },
  maxHeightDropdown: { type: Number, default: 200 },
  placeholder: { type: String, default: 'Bitte auswählen' },
  dataKey: { type: String, default: null },
  valueKey: { type: String, default: 'value' },
  groupData: { type: Boolean, default: false },
  optionSelectAll: { type: Boolean, default: true },
  modelValue: { type: Array, default: () => [] },
  ensureOneSelected: { type: Boolean, default: false },
  readOnly: { type: Boolean, default: false },
});

const emits = defineEmits(['update:modelValue']);

const dropdownOpen = ref(false);
const selectAll = ref(false);
const selectedSubItems = ref([]);
const filter = ref('');
const dropdownRef = ref(null);

onClickOutside(dropdownRef, () => {
  dropdownOpen.value = false;
});

onKeyStroke('Escape', () => {
  dropdownOpen.value = false;
});

onMounted(() => {
  if (props.initSelectAll) emits('update:modelValue', props.items);
});

watch(
  () => props.items,
  () => {
    if (props.selectAllOnItemsChange) {
      emits('update:modelValue', props.items);
    }
  },
  { deep: true, immediate: true },
);

const sortedItems = computed(() => {
  const items = [...props.items].filter((item) => item);
  const filteredItems = filterItems(items, filter.value, props.dataKey);

  filteredItems.sort((a, b) => {
    if (props.dataKey) {
      if (!a[props.dataKey] || !b[props.dataKey]) {
        // Check if the values at dataKey are defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a[props.dataKey].localeCompare(b[props.dataKey]);
    } else {
      if (!a || !b) {
        // Check if a or b is defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a.localeCompare(b);
    }
  });
  return filteredItems;
});

const selectedItemValues = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (
      props.ensureOneSelected &&
      value.length === 0 &&
      props.modelValue.length === 1
    ) {
      return;
    }
    emits('update:modelValue', value);
  },
});

const placeholderText = computed(() => {
  const {
    items,
    itemTypeAll,
    dataKey,
    placeholder,
    modelValue,
    allSelectedLabel,
  } = props;

  // If no items are selected
  if (selectedItemValues.value.length === 0) {
    if (placeholder) {
      return placeholder;
    }
    return modelValue[0];
  }

  // If all items are selected
  if (selectedItemValues.value.length === items.length) {
    if (allSelectedLabel) {
      return `${allSelectedLabel}`;
    } else if (itemTypeAll !== 'null') {
      return `Alle ${items.length} ${itemTypeAll} ausgewählt`;
    } else {
      return 'Alle ausgewählt';
    }
  }
  // If more than 3 items are selected
  else if (selectedItemValues.value.length >= 3) {
    if (itemTypeAll !== 'null') {
      return `${selectedItemValues.value.length} ${itemTypeAll} ausgewählt`;
    } else {
      return `${selectedItemValues.value.length} ausgewählt`;
    }
  }

  // sort selectedItems value (displayed as placeholder)
  const sortedPlacedholders = [...selectedItemValues.value].sort((a, b) => {
    if (dataKey) {
      if (!a[dataKey] || !b[dataKey]) {
        // Check if the values at dataKey are defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a[dataKey].localeCompare(b[dataKey]);
    } else {
      if (!a || !b) {
        // Check if a or b is defined
        return 0; // If either value is not defined, consider them equal for sorting purposes
      }
      return a.localeCompare(b);
    }
  });

  // Return the truncated list of selected items
  const itemMapper = dataKey ? (item) => item[dataKey] : (item) => item;
  return truncateItems(sortedPlacedholders.map(itemMapper));
});

function toggleDropdown() {
  dropdownOpen.value = !dropdownOpen.value;
}

function toggleSelectAll() {
  if (selectAll.value) {
    selectedItemValues.value = [...sortedItems.value];
  } else {
    if (props.ensureOneSelected) {
      selectedItemValues.value = [sortedItems.value[0]];
    } else {
      selectedItemValues.value = [];
    }
  }
}

function handleClearClick() {
  selectAll.value = false;
  toggleSelectAll();
}

function truncateItems(text) {
  const selectedItemsLength = 5;
  return text.length > selectedItemsLength
    ? text.slice(0, selectedItemsLength).join(', ') + '...'
    : text.join(', ');
}

function filterItems(items) {
  // Provide a default empty string if districtFilter.value is null or undefined
  const filterText = (filter.value || '').trim().toLowerCase();

  // Proceed with filtering and sorting
  return items.filter((item) => {
    const toFilter = props.dataKey ? item[props.dataKey] : item;
    return toFilter.toLowerCase().includes(filterText);
  });
}

watch(
  () => selectedItemValues.value,
  (newValues) => {
    selectAll.value = newValues.length === props.items.length;
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss">
.dropdown-animation {
  animation: dropdown-animation 0.2s ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
