<template>
  <template v-for="item in model" :key="item.value">
    <div v-if="!('hidden' in item) || !item.hidden" class="flex">
      <label
        :class="{
          'body-3 min-h-[32px] p-2': small,
          'body-2 min-h-10 p-3': !small,
        }"
        :style="
          levelAdjustments[level].indent
            ? `padding-left: ${levelAdjustments[level].indent}px !important;`
            : null
        "
        class="hover:bg-subtle flex w-full cursor-pointer items-center"
      >
        <input
          :value="item.checked"
          :checked="item.checked"
          class="appearance-none"
          type="checkbox"
          :name="item.value"
          @click="selectItem(item)"
        />
        <span class="checkmark mr-3"></span>
        <span :class="levelAdjustments[level].fontClass">{{ item.name }}</span>
      </label>
      <div
        v-if="'expanded' in item && hasMoreSubItems(item)"
        class="align-self-center ml-auto"
      >
        <v-icon
          v-if="!item.expanded"
          icon="mdi-plus"
          @click="(e: Event) => expandItem(item, true, e)"
        />
        <v-icon
          v-if="item.expanded"
          icon="mdi-minus"
          @click="(e: Event) => expandItem(item, false, e)"
        />
      </div>
    </div>
    <drop-down-multiple-grouped-list
      v-if="hasMoreSubItems(item) && (!('expanded' in item) || item.expanded)"
      v-model="item.items"
      :small="small"
      :level-adjustments="levelAdjustments"
      :level="level + 1"
      :expandable="'expanded' in item"
      :expanded="'expanded' in item && item.expanded"
    />
  </template>
</template>

<script setup lang="ts">
interface Props {
  small: boolean;
  levelAdjustments: Record<number, { indent: number; fontClass: string }>;
  level: number;
  expandable: boolean;
  expanded: boolean;
}

withDefaults(defineProps<Props>(), {
  small: false,
});

interface Item {
  expanded: boolean;
  checked: boolean;
  items?: Item[];
  value: any;
  name: any;
}
const model = defineModel<Item[]>();

const hasMoreSubItems = (item: Item) => {
  return 'items' in item && Array.isArray(item.items) && item.items.length > 0;
};

const selectItem = (item: Item, newVal: boolean | null = null) => {
  const newValue = newVal === null ? !item.checked : newVal;
  item.checked = newValue;
  let root = item;
  if (hasMoreSubItems(root)) {
    for (let subItem of root.items ?? []) {
      selectItem(subItem, newValue);
    }
  }
};

const expandItem = (item: Item, newVal: boolean, event: Event) => {
  item.expanded = newVal;
  event.stopPropagation();
};
</script>

<style lang="scss" scoped>
/* Hide the browser's default checkbox */
/* Create a custom checkbox */
.checkmark {
  min-height: 16px;
  min-width: 16px;
  position: relative;
  border: 2px solid var(--color-title-neutral);
  background-color: white;
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.dropdown-multiple input:checked ~ .checkmark {
  background-color: var(--color-color1);
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dropdown-multiple input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.dropdown-multiple .checkmark:after {
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 12px;
  border: 1px solid white;
  border-width: 0 2px 2px 0;
}
</style>
