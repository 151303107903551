<template>
  <div class="grid grid-cols-3">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="flex cursor-pointer flex-col items-center"
      :class="[
        {
          'tab--active': modelValue === index,
        },
      ]"
      @click="setActiveTab(index)"
    >
      <IconWrapper
        :icon="tab.icon"
        :style="{ color: tabFillColor(index) }"
        class="p-2.5"
      />
      <div
        class="bottom-line h-1.5 w-full"
        :style="{ backgroundColor: tabFillColor(index) }"
        :class="{
          'rounded-l-1': index === 0,
          'rounded-r-1': index === tabs.length - 1,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  validCards: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Number,
    default: 0,
  },
  disableAutomaticTabSwitching: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clickTab', 'update:modelValue']);

const activatedTabs = ref(Array(props.tabs.length).fill(false));

watchEffect(() => {
  activatedTabs.value[props.modelValue] = true;
});

function setActiveTab(index) {
  if (!props.disableAutomaticTabSwitching) {
    emit('update:modelValue', index);
  } else {
    emit('clickTab', index);
  }
  activatedTabs.value[index] = true;
}

function tabFillColor(index) {
  const isActive = props.modelValue === index;
  const isValid =
    activatedTabs.value[index] && props.validCards.includes(index);
  const isValidLessOne = Array.from(
    { length: props.tabs.length - 1 },
    (_, i) => index - i - 1,
  ).some((i) => activatedTabs.value[i] && props.validCards.includes(i));

  if (isActive && !isValid) {
    return '#000099'; // Edit Active tab color
  } else if (isActive && isValid) {
    return '#70A638'; // Valid Active tab color
  } else if (!isActive && isValid) {
    return '#70A638'; // Valid Inactive tab color
  } else if (!isActive && isValidLessOne) {
    return '#C3BCB6'; // Color for inactive tabs
  } else {
    return '#C3BCB6'; // Color for deactivated tabs
  }
}
</script>
