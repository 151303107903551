<template>
  <input
    ref="fileInput"
    type="file"
    accept=".csv"
    class="hidden"
    @change="handleFileUpload"
  />
  <ButtonEl
    icon="list_alt"
    text="Upload PLZ-Liste als CSV"
    variant="primary"
    color="color2"
    @click="$refs.fileInput.click()"
  />
</template>

<script setup lang="ts">
import { PostalCode } from '@/composables/syncGeoMapPostalCodeSelection';
import { useToastStore } from '@/stores/toast-store';
import { ref } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';

const fileInput = ref<HTMLInputElement>();
const { showToast } = useToastStore();

const showErrorToast = (message: string) =>
  showToast({
    color: 'error',
    timeout: 3000,
    message,
  });

const props = defineProps<{
  availablePostalCodes: PostalCode[];
  onSelect: (postalCodeIds: string[]) => void;
}>();

const emit = defineEmits(['change']);

const handleFileUpload = async (event: Event) => {
  const text = await readFileInput(event);
  if (!text) return;
  const uploadedPostalCodes = extractPostalCodes(text);

  if (uploadedPostalCodes.length === 0) {
    showErrorToast(
      'Keine gültigen PLZs in der CSV-Datei gefunden. PLZs müssen 5-stellig sein.',
    );
    return;
  }

  const validPostalCodes = props.availablePostalCodes.filter((postalCode) =>
    uploadedPostalCodes.includes(parseInt(postalCode.name)),
  );

  if (validPostalCodes.length === 0) {
    showErrorToast(
      'Keine der PLZs aus der CSV-Datei sind für die Auswahl verfügbar.',
    );
    return;
  }

  props.onSelect(validPostalCodes.map((postalCode) => postalCode.id));
  if (uploadedPostalCodes.length === validPostalCodes.length) {
    showToast({
      color: 'success',
      timeout: 3000,
      message: `${validPostalCodes.length} PLZs erfolgreich ausgewählt`,
    });
    return;
  }

  showToast({
    color: 'warning',
    timeout: -1,
    message: `Nur ${validPostalCodes.length} von ${uploadedPostalCodes.length} gegebenen PLZs gefunden und ausgewählt.`,
  });
};

const readFileInput = async (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0];
  if (!file) return;

  try {
    const text = await file.text();
    if (fileInput.value) {
      fileInput.value.value = '';
    }
    return text;
  } catch (error) {
    console.error('Error reading CSV file:', error);
    showErrorToast('Fehler beim Lesen der CSV-Datei');
    return;
  }
};

const extractPostalCodes = (text: string) =>
  text
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => /^\d{5}$/.test(line))
    .map((code) => parseInt(code));
</script>
