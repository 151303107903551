<template>
  <FormTabs
    v-show="!geoTabIsLoading"
    ref="formTabs"
    :key="formTabsKey"
    :tabs
    :hide-tab-at-index
    :eager="true"
    :disable-transitions="true"
    :tabs-window-height-substract
    :max-height-is-height="true"
    :class="{ hidden: geoTabIsLoading }"
    :disable-tab-indexes
  >
    <template #slot0>
      <PotentialAnalysisGeoSelection
        v-if="!geoTabIsLoading"
        v-model="potentialAnalysis"
        :read-only
        :form-rules
        :potentials-names
        @title-unique="emit('update:titleIsUnique', $event)"
      />
    </template>
    <template #slot1>
      <PotentialAnalysisRestrictions v-model="potentialAnalysis" :read-only />
    </template>
    <template #slot2>
      <PotentialAnalysisAreaSetting
        v-model="potentialAnalysis"
        :read-only
        :form-rules
      />
    </template>
    <template #slot3>
      <PotentialAnalysisAreaQualification
        v-model="potentialAnalysis"
        :read-only
        :meta-data
      />
    </template>
    <template #slot4>
      <PotentialAnalysisPvSetting
        v-model="potentialAnalysis"
        :read-only
        :form-rules
      />
    </template>
    <template #slot5>
      <PotentialAnalysisGridConnection
        v-model="potentialAnalysis"
        :read-only
        :form-rules
        :meta-data
      />
    </template>
  </FormTabs>
  <div
    v-show="geoTabIsLoading"
    :class="[
      'ml-2 flex w-full items-center justify-center',
      { hidden: !geoTabIsLoading },
    ]"
    :style="{ height: `calc(100vh - ${loadingSpinnerHeightSubstract}px)` }"
  >
    <LoadingSpinner class="standard-elevation-0-dark w-full rounded-lg" />
  </div>
</template>

<script setup>
import { tabs } from '@/apps/usecase-2/potential-analysis/configs/potential-analysis-data';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import PotentialAnalysisGeoSelection from './PotentialAnalysisGeoSelection.vue';
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue';
import PotentialAnalysisRestrictions from './PotentialAnalysisRestrictions.vue';
import PotentialAnalysisAreaSetting from './PotentialAnalysisAreaSetting.vue';
import PotentialAnalysisAreaQualification from './PotentialAnalysisAreaQualification.vue';
import PotentialAnalysisPvSetting from './PotentialAnalysisPvSetting.vue';
import PotentialAnalysisGridConnection from './PotentialAnalysisGridConnection.vue';
import { ref, watch, watchEffect } from 'vue';
import { convertMetaDataToFormRules, restFetch } from '@/utils/rest-utils';
import { PotentialAnalysisDto } from '../potential-analysis-dto';
import { useToastStore } from '@/stores/toast-store';
import { RESTRequestValidator } from '@/utils/rest-request-validator';

const props = defineProps({
  potentialsData: {
    type: Array,
    default: null,
  },
  potentialsNames: {
    type: Array,
    default: null,
  },
  hideTabAtIndex: {
    type: [Number, Array],
    default: null,
  },
  formData: {
    type: Object,
    default: null,
  },
  tabsWindowHeightSubstract: {
    type: Number,
    default: 280,
  },
  loadingSpinnerHeightSubstract: {
    type: Number,
    default: 225,
  },
  metaData: {
    type: Object,
    default: null,
  },
  disableReadOnly: {
    type: Boolean,
    default: false,
  },
  disableTabIndexes: {
    type: [Array, null],
    default: null,
  },
});

const formTabsKey = ref(0);
const formTabs = ref(null);
const geoTabIsLoading = ref(false);
const readOnly = ref(false);
const potentialAnalysis = ref(new PotentialAnalysisDto());
const fieldValidator = ref({});

const formRules = convertMetaDataToFormRules(props.metaData);

const { showToast } = useToastStore();

const emit = defineEmits([
  'update:isValid',
  'update:titleIsUnique',
  'update:readOnly',
  'update:potentialAnalysis',
  'update:userSelect',
]);

async function fillForm(id = null, title = null) {
  formTabsKey.value++;

  // Handle new analysis creation
  if (!id) {
    readOnly.value = false;
    potentialAnalysis.value = new PotentialAnalysisDto();
    potentialAnalysis.value.title = title;
    return;
  }

  // Handle new analysis creation based on existing analysis
  if (id && title) {
    readOnly.value = false;
    geoTabIsLoading.value = true;

    // Initialize a new PotentialAnalysisDto
    potentialAnalysis.value = new PotentialAnalysisDto();

    // Fetch existing analysis data and copy properties
    const existingAnalysisData = await restFetch(PotentialAnalysisDto, id);
    Object.assign(potentialAnalysis.value, {
      ...existingAnalysisData,
      id: null,
      title,
      municipalities: existingAnalysisData.municipalities.map(String),
    });
    if (!potentialAnalysis.value.cluster_apply_analysis) {
      const defaultValues = new PotentialAnalysisDto();
      potentialAnalysis.value.cluster_max_transformer_load_kw =
        defaultValues.cluster_max_transformer_load_kw;
      potentialAnalysis.value.cluster_min_connected_load_kw =
        defaultValues.cluster_min_connected_load_kw;
      potentialAnalysis.value.cluster_max_connected_load_kw =
        defaultValues.cluster_max_connected_load_kw;
      potentialAnalysis.value.cluster_consider_battery =
        defaultValues.cluster_consider_battery;
      potentialAnalysis.value.cluster_only_unconnected_projects =
        defaultValues.cluster_only_unconnected_projects;
      potentialAnalysis.value.cluster_max_number_projects =
        defaultValues.cluster_max_number_projects;
      potentialAnalysis.value.cluster_max_distance_grid_m =
        defaultValues.cluster_max_distance_grid_m;
      potentialAnalysis.value.cluster_max_distance_projects_m =
        defaultValues.cluster_max_distance_projects_m;
      potentialAnalysis.value.cluster_consider_external_projects =
        defaultValues.cluster_consider_external_projects;
    }
    geoTabIsLoading.value = false;
    return;
  }

  // Load existing analysis
  try {
    geoTabIsLoading.value = true;
    readOnly.value = true;

    formTabs.value?.setActiveTab();

    const fetchedAnalysis = await restFetch(PotentialAnalysisDto, id);

    potentialAnalysis.value = {
      ...fetchedAnalysis,
      municipalities: fetchedAnalysis.municipalities.map(String),
    };
  } catch (error) {
    showToast?.({
      color: 'error',
      message: `Potenzialanalyse mit ID ${id} konnte nicht gefunden werden.`,
    });
    console.error('Failed to fetch potential analysis:', error);
  } finally {
    geoTabIsLoading.value = false;
  }
}

function validatePotentialAnalysis() {
  const fields = {};

  // Prepare fields for validation
  for (const [key, meta] of Object.entries(props.metaData)) {
    // Check if cluster_apply_analysis is false and exclude specific fields
    if (
      !potentialAnalysis.value.cluster_apply_analysis &&
      [
        'cluster_max_transformer_load_kw',
        'cluster_min_connected_load_kw',
        'cluster_max_connected_load_kw',
        'cluster_consider_battery',
        'cluster_only_unconnected_projects',
        'cluster_max_number_projects',
        'cluster_max_distance_grid_m',
        'cluster_max_distance_projects_m',
        'cluster_consider_external_projects',
      ].includes(key)
    ) {
      continue; // Skip these fields
    }

    fields[key] = {
      ...meta,
      value: potentialAnalysis.value[key] ?? null,
    };
  }

  fieldValidator.value = new RESTRequestValidator(fields);
  emit('update:isValid', fieldValidator.value.isValid);
}

watchEffect(() => {
  emit('update:readOnly', readOnly.value);
});

watch(
  () => [props.formData?.id, props.formData?.title],
  ([newId, newTitle], [oldId, oldTitle] = []) => {
    if (newId !== oldId || newTitle !== oldTitle) {
      fillForm(newId, newTitle);
    }
  },
  { immediate: true },
);

watch(
  potentialAnalysis,
  async (val) => {
    if (val) {
      validatePotentialAnalysis();
      emit('update:potentialAnalysis', val);
    }
  },
  { deep: true },
);
</script>
