<template>
  <div ref="profileOptionsContainer" class="relative flex w-fit gap-5">
    <PersonalAvatar data-test="profile-options" @click="toggleProfileOptions" />
    <transition name="fade" mode="out-in">
      <div
        v-if="isOpen"
        class="standard-elevation-2 absolute top-0 right-0 z-1000 overflow-hidden rounded-md bg-white"
      >
        <div class="cursor-pointer p-2" @click="toggleProfileOptions">
          <div
            class="text-neutral bg-subtle body-3 flex flex-col items-center rounded-sm p-2.5"
          >
            <span>Angemeldet als:</span>
            <span>{{ profile?.username }}</span>
          </div>
        </div>
        <RouterLink to="/profile" class="more-options-classes">
          <div class="body-2 text-neutral mt-0.5">Profil</div>
          <IconWrapper
            icon="account_box"
            fill="text-neutral"
            type="filled"
            :size="20"
          />
        </RouterLink>
        <RouterLink to="/" class="more-options-classes">
          <div class="body-2 text-neutral mt-0.5">Einstellungen</div>
          <IconWrapper
            :size="20"
            icon="settings"
            fill="text-neutral"
            type="filled"
          />
        </RouterLink>
        <div class="more-options-classes" data-test="logout" @click="logout">
          <div class="body-2 text-neutral mt-[1px]">Logout</div>
          <IconWrapper :size="20" icon="logout" fill="text-neutral" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import PersonalAvatar from '@/components/global/PersonalAvatar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed, onMounted, ref } from 'vue';
import { useAuth } from '@/apps/auth/auth-store';
import { useUsers } from '@/apps/auth/users-store';
import { useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';

const authStore = useAuth();
const usersStore = useUsers();
const router = useRouter();

const profile = computed(() => usersStore.profile);
const isOpen = ref(false);
const profileOptionsContainer = ref(null);

onClickOutside(profileOptionsContainer, () => {
  isOpen.value = false;
});

onMounted(() => {
  router.afterEach(() => {
    isOpen.value = false;
  });
});

function toggleProfileOptions() {
  isOpen.value = !isOpen.value;
}

function logout() {
  authStore.logoutUser().then(() => {
    router.push('/login');
  });
}
</script>

<style scoped>
.more-options-classes {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  background-color: white;

  &:hover {
    background-color: var(--color-active-area);
  }
}
</style>
