<template>
  <div
    class="breadcrumb-nav relative flex w-fit items-center justify-start gap-4"
  >
    <ToHome v-if="!selectorExpanded" class="standard-elevation-1" />
    <div class="body-3 text-neutral flex">
      Sie sind hier:
      <RouterLink
        v-for="(item, index) in breadcrumb_items"
        :key="item.path"
        :to="item.path"
        class="flex gap-1 pl-1"
      >
        <div
          :class="{
            'border-neutral border-b text-black':
              item.path === router.currentRoute.value.path,
          }"
        >
          {{ item.title }}
        </div>
        <span v-if="index < breadcrumb_items.length - 1"> &rsaquo;</span>
      </RouterLink>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import ToHome from '@/apps/features/map/control-options/components/ToHome.vue';

const router = useRouter();
const route = router.currentRoute;

const selectorExpanded = ref(false);

const breadcrumb_items = computed(() => {
  const items = [];
  route.value.matched.forEach((e) => {
    if (e.meta.title) {
      // Replace dynamic segments with actual values
      let path = e.path;
      Object.keys(route.value.params).forEach((param) => {
        path = path.replace(`:${param}`, route.value.params[param]);
      });

      items.push({
        title: e.meta.title,
        path: path,
      });
    }
  });
  return items;
});
</script>
