import IconTaskProgressRound from '@/assets/icons/custom/misc/IconTaskProgressRound.vue';

export const colorProjectStatus = (reviewState, text) => {
  const styleConfig = {
    color: '',
    bgColor: '',
    icon: '',
    text,
  };
  switch (reviewState) {
    case 'created':
      styleConfig.color = 'text-spot-warning';
      styleConfig.bgColor = 'color-fill-warning';
      styleConfig.icon = 'pending_actions';
      break;
    case 'in_progress':
      styleConfig.color = 'text-spot-success';
      styleConfig.bgColor = 'color-fill-success';
      styleConfig.icon = IconTaskProgressRound;
      break;
    case 'complete':
      styleConfig.color = 'text-title-neutral';
      styleConfig.bgColor = 'color-neutral-grau-00';
      styleConfig.icon = 'check';
      break;
    case 'termination':
      styleConfig.color = 'text-spot-error';
      styleConfig.bgColor = 'color-fill-error';
      styleConfig.icon = 'cancel';
      break;
  }
  return styleConfig;
};
export function getListProgressIcon(progress) {
  const progressMap = {
    finished: {
      icon: 'mark_email_read',
      fill: 'text-spot-success bg-white',
      label: 'Abgeschlossen',
    },
    sent: {
      icon: 'forward_to_inbox',
      fill: 'text-spot-warning bg-white',
      label: 'Versandt an Partner',
    },
    open: {
      icon: 'pending_actions',
      fill: 'text-spot-warning bg-white',
      label: 'Offen',
    },
  };

  return progressMap[progress];
}

export function navigateToMap(router, salesRegionId, leadListId = null) {
  router.push({
    name: 'mapView4',
    params: { salesRegionId, leadListId },
  });
}
