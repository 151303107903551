<template>
  <AuthFormWrapper
    :title="title"
    :error="loginError"
    error-message="Das Passwort oder die E-Mail-Adresse ist nicht korrekt. Bitte geben Sie
            Ihre Zugangsdaten erneut ein. Falls Sie Ihr Passwort vergessen haben,
            können Sie es zurücksetzen."
    :animate="startAnimation"
  >
    <FormValidator v-model="isFormValid" class="login-container">
      <div class="align-start flex flex-col">
        <InputEl
          v-model="username"
          :rules="{ required: true, email: true }"
          label="E-Mail-Adresse"
          placeholder="Bitte E-Mail-Adresse eingeben"
          class="w-full"
          @update:model-value="loginError = false"
        />
      </div>

      <div class="align-start flex flex-col">
        <InputEl
          v-model="password"
          :rules="{ required: true }"
          label="Passwort"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Bitte Passwort eingeben"
          :suffix="showPassword ? EyeCrossedIcon : EyeIcon"
          :suffix-icon="true"
          :suffix-clickable="true"
          class="w-full"
          @icon-click="showPassword = !showPassword"
          @keyup.enter="confirmSignIn"
          @update:model-value="loginError = false"
        />
      </div>
      <ButtonEl
        v-if="!startAnimation"
        :text="t('login.button')"
        :disabled="!isFormValid || isSubmitting"
        :full-width="true"
        @click="confirmSignIn"
      />
    </FormValidator>
  </AuthFormWrapper>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import AuthFormWrapper from '@/apps/auth/AuthFormWrapper.vue';
import FormValidator from '@/components/FormValidator.vue';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import EyeCrossedIcon from '@/assets/icons/EyeCrossedIcon.vue';
import EyeIcon from '@/assets/icons/EyeIcon.vue';
import { useAuth } from './auth-store';
import { useUsers } from '@/apps/auth/users-store';

const authStore = useAuth();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const isFormValid = ref(false);
const isSubmitting = ref(false);

const username = ref();
const password = ref();
const loginError = ref(false);
const showPassword = ref(false);
const startAnimation = ref(false);

const title = computed(() => t('login.title'));

function confirmSignIn() {
  if (isFormValid.value) {
    isSubmitting.value = true;
    signIn();
  }
}

function signIn() {
  authStore
    .loginUser(username.value, password.value)
    .then(() => {
      const redirectUrl = route.query.from || '/';
      loginError.value = false;
      animateLogin();
      setTimeout(() => {
        router.push(redirectUrl);
      }, 300);
    })
    .catch(() => {
      isSubmitting.value = false;
      loginError.value = true;
    });
}

function animateLogin() {
  startAnimation.value = true;
  const container = document.querySelector('.login-container');
  container.classList.add('container-transition');
}

const userStore = useUsers();

onMounted(() => {
  userStore.$reset();
});
</script>

<style lang="scss" scoped>
.container-transition {
  margin-bottom: 30px;
  margin-top: 30px;
  transition:
    margin-top 0.4s ease,
    margin-bottom 0.4s ease;
}
</style>
