<template>
  <div
    class="flex w-full items-center gap-2.5 rounded-md p-3"
    :class="[color ? color : 'bg-white', 'standard-elevation-0-dark']"
    :data-test="dataTestId"
  >
    <IconWrapper
      :icon
      :size="32"
      :fill="
        disabled
          ? 'text-disabled-neutral'
          : color
            ? color
            : 'text-title-neutral'
      "
    />
    <div
      class="body-1 pt-[3px] whitespace-nowrap"
      :class="
        disabled ? 'text-disabled-neutral' : color ? color : 'text-core-dark'
      "
    >
      <div v-if="Array.isArray(label)">
        <p v-for="subText in label" :key="subText">{{ subText }}</p>
      </div>
      <p v-else>{{ label }}</p>
    </div>
    <ToolTip v-if="toolTipForLabel" :tooltip-text="toolTipForLabel" />
    <div
      class="flex grow items-center gap-2.5"
      :class="{ 'flex-row-reverse': toolTipAtEnd }"
    >
      <ToolTip v-if="toolTip && !$slots.tooltip" :tooltip-text="toolTip" />
      <slot v-else name="tooltip" />
      <h4
        v-if="!$slots.content"
        class="ml-auto"
        :class="
          disabled
            ? 'text-disabled-neutral'
            : color
              ? color
              : 'text-title-neutral'
        "
      >
        {{ formattedValue }} {{ unit }}
      </h4>
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { computed, useSlots } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { toLocaleStr } from '@/utils/formatUtils';

const props = defineProps({
  icon: [String, Object],
  label: [String, Array],
  value: [Number, Boolean, String],
  toolTipAtEnd: Boolean,
  toolTipForLabel: {
    type: String,
    default: '',
  },
  unit: String,
  toolTip: String,
  color: String,
  shadow: {
    type: String,
    default: 'standard-elevation-0-dark',
  },
  disabled: Boolean,
  minimumDigits: {
    type: Number,
    default: 1,
  },
  maximumDigits: {
    type: Number,
    default: 1,
  },
});

const slots = useSlots();

const formattedValue = computed(() => {
  if (typeof props.value === 'boolean') {
    return props.value ? 'ja' : 'nein';
  }
  if (typeof props.value === 'string') {
    return props.value;
  }
  return toLocaleStr(props.value, props.minimumDigits, props.maximumDigits);
});

const dataTestId = computed(() => {
  if (slots.content) {
    return `detail-box-${props.label}`.replace(' ', '');
  }
  return `detail-box-${props.label}-${formattedValue.value}`.replace(/\s/g, '');
});
</script>
