<template>
  <div class="flex flex-col gap-5">
    <TopHeaderBar go-back-to-custom="UseCase1ProjectList" />
    <div
      class="standard-elevation-2 project-result-detail flex h-full w-full flex-col gap-7 rounded-xl bg-white p-5"
    >
      <div class="text-title-neutral flex items-center justify-between gap-5">
        <div class="flex gap-1">
          <EditTitle
            :disabled="!userStore.uc1CanChangeProject"
            :dto="ProjectDto"
            :instance-id="projectId"
            :title="project.title"
            @title-changed="project.title = $event"
          />
          <ToMapButton
            v-if="activeTab.mapLayerConfig"
            :project-id
            :scenario-id="scenarioSelected"
            :map-store
            :map-layer-config="activeTab.mapLayerConfig"
            :aggregation-level="activeTab.aggregationLevel"
          />
        </div>
        <EstimationPoliticalAmbitionModal
          v-if="userStore.uc1CanChangeProject"
          :project="project"
          @patch-project="patchProject($event)"
        />

        <DropDown
          v-model="scenarioSelected"
          :items-data="scenarioSelectOptions"
          items-data-key="title"
          value="id"
          label="Szenario"
          class="z-20 min-w-[25%]"
          @update:model-value="onScenarioChange($event)"
        />
      </div>
      <CardNavigation
        :tabs="navigationCards"
        :route-parameter="scenarioSelected"
        :url-depth="4"
        :active-card-text-center="false"
        @update-active-tab="activeTab = $event"
      />

      <RouterView v-slot="{ Component }" :key="route.fullPath">
        <Suspense>
          <component :is="Component" v-if="Component" />
          <template #fallback>
            <div class="my-auto flex w-full items-center justify-center">
              <LoadingSpinner />
            </div>
          </template>
        </Suspense>
      </RouterView>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed, onUnmounted, ref, watch } from 'vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import { ProjectDetailTabs } from './selectable-categories';
import { useProjectResult } from './project-result-store';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import EstimationPoliticalAmbitionModal from '@/apps/usecase-1/projects/project-result-detail/components/EstimationPoliticalAmbitionModal.vue';
import CardNavigation from '@/apps/navigation/CardNavigation.vue';
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue';
import { ProjectDto } from '../project-dto';
import { restPatch } from '@/utils/rest-utils';
import EditTitle from '@/components/EditTitle.vue';
import ToMapButton from '../../../features/to-map-button/ToMapButton.vue';
import { useMapStore } from '@/apps/usecase-1/map/map-store';
import { useUsers } from '@/apps/auth/users-store';

const userStore = useUsers();
const mapStore = useMapStore();
const resultStore = useProjectResult();

const router = useRouter();
const route = useRoute();
const emit = defineEmits(['edit-instance', 'update-instances', 'abort']);
const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
});

resetGenerationScaling();

await resultStore.fetchAssumptionsGeneral();

const scenarioSelectOptions = ref([]);
const activeTab = ref({});
const scenarioSelected = ref(null);
const scenarioDataSelected = computed(() => {
  return scenarioSelectOptions.value.find(
    (e) => e.id === scenarioSelected.value,
  );
});

await resultStore.getScenariosByProject(props.projectId);
const project = ref({});
project.value = await resultStore.getProjectDetail(props.projectId);

setScenario();

await resultStore.fetchAssumptionsScenario(
  scenarioDataSelected.value?.assumptions_scenario,
);

function patchProject(payload) {
  restPatch(ProjectDto, project.value.id, payload).then((resp) => {
    project.value = resp.data;
  });
}

function setScenario() {
  // initially set scenario (in store and dropdown) to default or route param,
  // if already set in url
  scenarioSelectOptions.value = resultStore.scenarios.filter(
    (e) => e.progress === 0,
  );

  scenarioSelected.value = parseInt(route.params.scenarioId);
  resultStore.setActiveScenarioById(scenarioSelected.value);
}

function onScenarioChange(scenarioId) {
  // scenario id is initially set in route params and passed to dashboard views
  // via props. Active scenario in store is set in children after data is fetched
  resetGenerationScaling();
  if (Object.keys(route.params).includes('scenarioId')) {
    router.push({ params: { ...route.params, scenarioId } });
  }
}

function resetGenerationScaling() {
  resultStore.setAppliedDemandScalingId(null);
  resultStore.setAppliedGenerationScalingId(null);
  resultStore.setTotalIncreasePvRoofMw(null, true);
  resultStore.setTotalIncreasePvFreeMw(null, true);
  resultStore.setTotalIncreaseWindMw(null, true);
  resultStore.setScalingElectrificationMobility(null, true);
  resultStore.setScalingElectrificationHeatSingle(null, true);
  resultStore.setScalingElectrificationHeatNetwork(null, true);
}

const scenarioAssumptionId = computed(
  () => scenarioDataSelected.value.assumptions_scenario,
);

const navigationCards = computed(() => {
  return ProjectDetailTabs.filter((e) => {
    const perm = e.requiresPerm;
    return !perm || userStore.hasPermission(perm.app, perm.permission);
  });
});

watch(scenarioAssumptionId, (newValue) => {
  resultStore.fetchAssumptionsScenario(newValue);
});

onUnmounted(() => {
  resultStore.$reset();
});
</script>

<style scoped>
.project-result-detail {
  min-height: calc(100vh - 290px);
}
</style>
