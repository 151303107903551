<template>
  <div
    class="panelItem border-line-disabled-neutral singleItemcustom flex w-full justify-between border-b py-1 last:border-b-0"
  >
    <div class="body-3 relative flex items-center" :style="{ width: '60%' }">
      {{ item.label }}
    </div>

    <div
      class="flex items-center"
      :style="{ width: '38%' }"
      :class="item.type !== 'boolean' ? 'justify-start' : 'justify-end'"
    >
      <div class="caption-1 flex w-full">
        <span class="whitespace-nowrap">{{ item.unit }}</span>
        <component
          :is="visualization"
          v-if="visualization"
          :value="item.value"
        />
        <ToolTip
          v-else
          class="white-space-normal ml-auto flex w-fit justify-end"
          :class="[
            item.color &&
              `${item.color(props.item.value)} standard-elevation-0-dark rounded-md p-2.5`,
          ]"
          :tooltip-text="
            formattedValue.length > 15 &&
            (typeof item.value === 'number'
              ? toLocaleStr(item.value)
              : item.value)
          "
        >
          {{ formattedValue.length <= 15 ? formattedValue : truncatedValue }}
        </ToolTip>
      </div>
    </div>
  </div>
</template>

<script setup>
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { toLocaleStr } from '@/utils/formatUtils';
import { computed } from 'vue';
import HeatPumpPotentialIndicatior from '@/apps/features/map/info-panel/HeatPumpPotentialIndicatior.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const visualization = computed(() => {
  if (
    'panelItemType' in props.item &&
    props.item.panelItemType === 'heatPumpPotentialIndicator'
  ) {
    return HeatPumpPotentialIndicatior;
  }
  return undefined;
});

// Computed property for formatted value
const formattedValue = computed(() => {
  return props.item.value ? props.item.value : '';
});

// Computed property to return truncated string if too long
const truncatedValue = computed(() => {
  if (!props.item.value) return '';
  const formatted = formattedValue.value;
  return formatted.length > 15 ? `${formatted.slice(0, 14)}...` : formatted;
});
</script>
