<template>
  <ButtonEl
    icon="map"
    :icon-right="true"
    color="color2"
    class="h-10 w-10 rounded-xs"
    @click="navigateToMap()"
  />
</template>
<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: [String, Number],
    required: true,
  },
  mapStore: {
    type: Object,
    required: true,
  },
  mapLayerConfig: {
    type: Object,
    required: true,
  },
  aggregationLevel: {
    type: String || null,
    default: null,
  },
  text: {
    type: String,
    default: '',
  },
});

function navigateToMap() {
  props.mapStore.configureInitialMapState(props.mapLayerConfig);
  if (props.aggregationLevel !== null) {
    props.mapStore.setAggregationLevel(props.aggregationLevel);
  }
  router.push({
    name: 'mapView1',
    params: { projectId: props.projectId, scenarioId: props.scenarioId },
  });
}
</script>
