<template>
  <ButtonEl
    class="rounded-xxs p-0.5"
    size="m"
    :icon="props.icon"
    :color="!toggleIsActive ? 'color2' : 'color1'"
    :disabled="!layerExists"
    :class="{ 'text-button-primary-disabled-color1 bg-white': !layerExists }"
    @click="toggle"
  />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';

const emit = defineEmits(['update-style']);

const props = defineProps({
  layerState: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: 'layers',
  },
  onLayer: {
    type: [String, null],
    required: true,
  },
  unselect: {
    type: Array,
    default: () => [],
  },
  parentId: {
    type: String || null,
    default: null,
  },
  grandParentId: {
    type: String || null,
    default: null,
  },
  mapStore: {
    type: Object,
    required: true,
  },
  dataFromStoreAction: {
    type: String,
    default: null,
  },
});

const toggleIsActive = ref(false);
const layerExists = computed(() =>
  props.mapStore.getLayers.includes(props.onLayer),
);
onMounted(() => {
  if (layerStateIsApplied(props.mapStore.getLayerStates))
    toggleIsActive.value = true;
});

// emit Style when button is activated and set Layer visibility accordingly
function toggle() {
  toggleIsActive.value = !toggleIsActive.value;
  if (!props.onLayer) return;
  if (toggleIsActive.value) {
    emit('update-style', props.layerState);
    props.mapStore.changeLayerState({
      layerKeys: [props.onLayer],
      layerState: props.layerState,
    });
  }
  props.mapStore.changeLayerVisibility({
    onLayers: [props.onLayer],
    unselect: props.unselect,
    active: toggleIsActive.value,
  });
  if (props.dataFromStoreAction) {
    if (typeof props.mapStore[props.dataFromStoreAction] !== 'function') {
      throw new Error(
        'please define action in map store with name: ' +
          props.dataFromStoreAction,
      );
    }
    props.mapStore[props.dataFromStoreAction]();
  }
}

function layerStateIsApplied(layerStates) {
  const styleSelected = layerStates.find((e) => e.layer === props.onLayer);
  const isVisible = props.mapStore.getLayersVisible.includes(props.onLayer);
  if (typeof styleSelected === 'undefined') return false;
  return props.layerState === styleSelected.state && isVisible;
}

// if layer style changes and is not in this radio group, deactivate toggle
watch(
  () => props.mapStore.getLayerStates,
  (val) => {
    toggleIsActive.value = layerStateIsApplied(val);
  },
);

watch(toggleIsActive, (val) => {
  if (props.parentId)
    props.mapStore.setActiveToggle(props.layerState, props.parentId, val);
  if (props.grandParentId)
    props.mapStore.setActiveToggle(props.layerState, props.grandParentId, val);
});
</script>

<style lang="scss" scoped></style>
