<template>
  <div v-if="isLoading" class="flex items-center justify-center py-24">
    <LoadingSpinner />
  </div>
  <template v-else>
    <div class="space-y-2.5 py-5">
      <DetailBox
        icon="heat_pump"
        label="Anzahl Gebäude/potenzielle Wärmepumpen"
        :value="exportData.count_buildings"
        :maximum-digits="0"
        :minimum-digits="0"
      />
      <DetailBox icon="traffic" label="Wärmepumpen-Ampel">
        <template #content>
          <div class="align-center flex w-full justify-end">
            <div
              v-for="item in heatTrafficLights"
              :key="item"
              class="text-fill-warning ml-[5px] h-[30px] min-w-[50px] p-[5px]"
              :class="item.background"
            >
              <h4>{{ item.value }} %</h4>
            </div>
          </div>
        </template>
      </DetailBox>
      <div
        v-for="column in cardsColumn3"
        :key="column"
        class="grid auto-cols-fr grid-flow-col gap-2.5"
      >
        <DetailBox
          v-for="item in column"
          :key="item.label"
          :maximum-digits="0"
          :minimum-digits="0"
          v-bind="item"
          class="w-full"
        />
      </div>
      <DetailBox
        icon="inventory_2"
        label="Commoditiy-Potenzial"
        :value="exportData.commodity_potential_eur_a"
        :maximum-digits="0"
        :minimum-digits="0"
        unit="Mio. €"
      />
    </div>
    <div class="grid h-[420px] grid-cols-4 gap-2.5 px-5">
      <BasicPieChart
        class="h-full"
        :chart-data="pieTypeData"
        :title="'Gebäude nach\nGebäudetyp'"
        :custom-radius="['35%', '55%']"
        :series-position="['50%', '36%']"
        legend-orient="horizontal"
        chart-unit="Gebäude"
        break-unit
        legend-top="245px"
        :legend-right="null"
        :legend-line-break="false"
        :show-label="false"
        custom-title-tag="h5"
        custom-title-classes="min-h-9"
        legend-bg-color="#F7F7F7"
        :legend-border-radius="4"
        :legend-padding="10"
      />
      <BasicPieChart
        class="h-full"
        :chart-data="pieYearData"
        :title="'Gebäude nach\nBaualtersklassen'"
        :custom-radius="['35%', '55%']"
        :series-position="['50%', '36%']"
        legend-orient="horizontal"
        chart-unit="Gebäude"
        break-unit
        legend-top="245px"
        :legend-right="null"
        :legend-line-break="false"
        :show-label="false"
        custom-title-tag="h5"
        custom-title-classes="min-h-9"
        legend-bg-color="#F7F7F7"
        :legend-border-radius="4"
        :legend-padding="10"
      />
      <BasicPieChart
        class="h-full"
        :chart-data="pieSectorData"
        title="Gebäude nach Sektor"
        :custom-radius="['35%', '55%']"
        :series-position="['50%', '36%']"
        legend-orient="horizontal"
        chart-unit="Gebäude"
        break-unit
        legend-top="245px"
        :legend-right="null"
        :legend-line-break="false"
        :show-label="false"
        custom-title-tag="h5"
        custom-title-classes="min-h-9"
        legend-bg-color="#F7F7F7"
        :legend-border-radius="4"
        :legend-padding="10"
      />
      <BasicPieChart
        class="h-full w-[300px]"
        :chart-data="pieSourceData"
        :title="'Gebäude nach\n Energieträger'"
        :custom-radius="['35%', '55%']"
        :series-position="['50%', '36%']"
        legend-orient="horizontal"
        chart-unit="Gebäude"
        break-unit
        legend-top="245px"
        :legend-right="null"
        :legend-line-break="false"
        :show-label="false"
        custom-title-tag="h5"
        custom-title-classes="min-h-9"
        legend-bg-color="#F7F7F7"
        :legend-border-radius="4"
        :legend-padding="10"
      />
    </div>
    <div
      class="standard-elevation-0-dark text-title-neutral mb-5 rounded-md bg-white px-[14px] pt-2.5 pb-5"
    >
      <div class="flex justify-between">
        <div class="align-center flex">
          <IconWrapper
            :icon="getListProgressIcon(leadList.state).icon"
            :size="32"
          />
          <h4 class="ml-2.5">
            {{ getListProgressIcon(leadList.state).label }}
          </h4>
        </div>
        <IconWrapper
          v-if="userStore.uc4CanChangeLeadList"
          icon="edit_note"
          :size="40"
          class="text-color1 hover:text-active-color1 hover:cursor-pointer"
          @click="showStatusDialog = true"
        />
        <VuetifyDialog
          v-model="showStatusDialog"
          title="Status setzen"
          width="628px"
          persistent
        >
          <template #content>
            <VRadioGroup v-model="selectedStatus">
              <div class="mb-4 space-y-2">
                <VRadio
                  v-for="option in statusOptions"
                  :key="option.value"
                  :value="option.value"
                  @click="selectedStatus = option.value"
                >
                  <template #label>
                    <h5 class="text-neutral mr-2 ml-2">{{ option.label }}</h5>
                  </template>
                </VRadio>
              </div>
            </VRadioGroup>
            <TextAreaEl
              v-model="feedback"
              label="Notiz"
              custom-classes="h-52"
            />
          </template>
          <template #actions>
            <div class="flex w-full justify-between">
              <ButtonEl
                text="Abbrechen"
                color="color2"
                @click="showStatusDialog = false"
              />
              <ButtonEl text="Speichern" @click="editStatus" />
            </div>
          </template>
        </VuetifyDialog>
      </div>
      <p class="body-1 mt-2.5">
        {{ leadList.description || 'Kein Feedback vorhanden' }}
      </p>
    </div>
  </template>
</template>

<script setup>
import DetailBox from '@/apps/features/boxes/DetailBox.vue';
import IconHeatWavesCircleOutlined from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleOutlined.vue';
import IconHeatWavesCircleFilled from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleFilled.vue';
import IconHousePointFilled from '@/assets/icons/custom/energy-grid/IconHousePointFilled.vue';
import IconHousePointOutlined from '@/assets/icons/custom/energy-grid/IconHousePointOutlined.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import { computed, ref, onMounted, watch } from 'vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import TextAreaEl from '@/components/textAreaEl/TextAreaEl.vue';
import { getListProgressIcon } from '@/apps/usecase-4/utils';
import axios from '@/utils/axiosHelper';
import { toLocaleStr } from '@/utils/formatUtils';
import {
  BUILDINGTYPE_BY_ENUM,
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
  HEATING_TYPE_BY_ENUM,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue';
import { restFetch, restPatch } from '@/utils/rest-utils';
import { LeadListDto } from './components/lead-list-dto';
import { useToastStore } from '@/stores/toast-store';
import { useUsers } from '@/apps/auth/users-store';
const userStore = useUsers();
const { showToast } = useToastStore();

const props = defineProps({
  leadList: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:leadList']);

const isLoading = ref(true);
const exportData = ref({});

onMounted(async () => {
  await fetchLeadListAggregationData();
});

async function fetchLeadListAggregationData() {
  try {
    const { data } = await axios({
      url: `/api/usecase-4/export/lead-list-aggregation/${props.leadList.id}`,
      method: 'GET',
    });
    exportData.value = data;
  } finally {
    isLoading.value = false;
  }
}

const selectedStatus = ref(props.leadList.state);
const initialState = ref(props.leadList.state);
const feedback = ref(props.leadList.description);
const initialDescription = ref(props.leadList.description);
const showStatusDialog = ref(false);

const statusOptions = [
  {
    label: 'Offen',
    value: 'open',
  },
  {
    label: 'Versandt an Partner',
    value: 'sent',
  },
  {
    label: 'Abgeschlossen',
    value: 'finished',
  },
];

const downloadFormats = ref([
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'Als GeoJSON (Projektion EPSG:4326)',
    value: 'geojson',
  },
  {
    label: 'Als ESRI Shapefile (Projektion EPSG:4326)',
    value: 'shapefile',
  },
  {
    label: 'Report als PPTX',
    value: 'pptx',
  },
]);

const cardsColumn3 = computed(() => [
  [
    {
      icon: IconHeatWavesCircleOutlined,
      label: 'Wärmebedarf',
      value: exportData.value.demand_kwh,
      unit: 'MWh(th)/a',
    },
    {
      icon: IconHousePointOutlined,
      label: 'Ø Wärmebedarf je Gebäude',
      value: exportData.value.average_demand_kwh,
      unit: 'kWh(th)/a',
    },
  ],
  [
    {
      icon: IconHeatWavesCircleFilled,
      label: 'Wärmelast',
      value: exportData.value.demand_kw,
      unit: 'MW(th)',
    },
    {
      icon: IconHousePointFilled,
      label: 'ø Wärmelast je Gebäude',
      value: exportData.value.average_demand_kw,
      unit: 'kW(th)',
    },
  ],
]);

const heatTrafficLights = computed(() => [
  {
    value: toLocaleStr(
      exportData.value.percentage_air_heat_pump_potential_green * 100,
      1,
      1,
    ),
    background: 'bg-spot-success',
  },
  {
    value: toLocaleStr(
      exportData.value.percentage_air_heat_pump_potential_yellow * 100,
      1,
      1,
    ),
    background: 'bg-spot-warning',
  },
  {
    value: toLocaleStr(
      exportData.value.percentage_air_heat_pump_potential_red * 100,
      1,
      1,
    ),
    background: 'bg-spot-error',
  },
]);
const getPieData = (group, dataKey, style) => {
  return exportData.value[group].map((e) => {
    const choice = style[e[dataKey]];
    return {
      value: e.count_buildings,
      color: choice.color,
      name: choice.label,
    };
  });
};
const pieYearData = computed(() => {
  return getPieData(
    'by_construction_year_class',
    'construction_year_class_uc4',
    CONSTRUCTION_YEAR_UC4_BY_ENUM,
  );
});
const pieTypeData = computed(() => {
  return getPieData('by_building_type', 'building_type', BUILDINGTYPE_BY_ENUM);
});
const pieSectorData = computed(() => {
  return getPieData('by_sector', 'sector', SECTOR_BY_ENUM);
});
const pieSourceData = computed(() => {
  return getPieData('by_heating_type', 'heating_type', HEATING_TYPE_BY_ENUM);
});

async function editStatus() {
  await restPatch(LeadListDto, props.leadList.id, {
    state: selectedStatus.value,
    description: feedback.value,
  }).then(() => {
    showStatusDialog.value = false;
    restFetch(LeadListDto, props.leadList.id)
      .then((resp) => {
        emit('update:leadList', resp);
      })
      .finally(() => {
        const isStatusChanged = selectedStatus.value !== initialState.value;
        const isFeedbackChanged = feedback.value !== initialDescription.value;

        let message;
        if (isStatusChanged && isFeedbackChanged) {
          message = 'Status und Feedback erfolgreich gespeichert';
        } else if (isFeedbackChanged) {
          message = 'Feedback erfolgreich gespeichert';
        } else {
          message = 'Status erfolgreich gespeichert';
        }

        showToast({
          color: 'success',
          message,
        });
        initialDescription.value = feedback.value;
        initialState.value = selectedStatus.value;
      });
  });
}

watch(showStatusDialog, (newVal) => {
  if (!newVal) {
    setTimeout(() => {
      feedback.value = props.leadList.description;
      selectedStatus.value = props.leadList.state;
    }, 300);
  }
});
</script>

<style scoped lang="scss">
:deep(.v-selection-control__wrapper) {
  width: 16px;
}
</style>
