<template>
  <div class="relative flex h-screen w-full">
    <LayerPanel
      v-if="loaded && !mapStore.searchResults"
      v-show="mapStore.showMainMenu"
      :map-store
    >
      <template #header>
        <PostalCodeMenu />
      </template>
      <template #layer-toggles="slotProps">
        <AggregationToggle v-bind="slotProps" />
      </template>
    </LayerPanel>
    <div class="mapView relative h-full w-full">
      <template v-if="loaded">
        <BaseMap
          :map-store
          :selected-analysis-id="selectedSalesRegionId"
          :layer-config="LayerConfig"
          :panel-objects="panelObjects"
          :fly-to-function="flyToBBox"
          @map-style-loaded-signal="onMapStyleLoad()"
        >
          <template #after-title>
            <AggregationTooltip />
          </template>
        </BaseMap>
        <div class="top-controls absolute w-full">
          <ControlBar :map-store />
          <AnalysisSelector
            v-model="selectedLeadListId"
            :items="leadLists"
            :map-store
            :item-height-virtual-list="54"
          >
            <template #title-selected>
              <div class="flex items-center gap-4">
                <div>
                  <div class="caption-1 flex whitespace-nowrap">
                    Lead-Listen
                  </div>
                  <h5 class="max-w-60 truncate whitespace-nowrap">
                    {{ selectedLeadListName }}
                  </h5>
                </div>
                <AggregationTooltip />
              </div>
            </template>
            <template #after-title>
              <Transition name="fade">
                <IconWrapper
                  :icon="IconGotoChart"
                  fill="text-color1"
                  hover="hover:text-active-color1"
                  class="cursor-pointer"
                  @click="navigateToDetail()"
                />
              </Transition>
            </template>
          </AnalysisSelector>
          <LegendControls
            :map-store
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <MapControls :map-store v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import {
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  computed,
  defineComponent,
} from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-4/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import { useMapStore } from './map-store';
import AnalysisSelector from '@/apps/features/map/control-options/components/AnalysisSelector.vue';
import { panelObjects } from '@/apps/usecase-4/map/info-panel/Info-panel-objects';
import axios from '@/utils/axiosHelper';
import { fitBBox } from '@/mapbox/main';
import AggregationToggle from '@/apps/usecase-4/map/layer-panel/AggregationToggle.vue';
import { LAYER_KEY__BUILDINGS } from '@/apps/usecase-4/map/layer-config/buildings';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import IconGotoChart from '@/assets/icons/custom/misc/IconGotoChart.vue';
import { restFetch } from '@/utils/rest-utils';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';
import PostalCodeMenu from '@/apps/usecase-4/map/layer-panel/PostalCodeMenu.vue';
import Tooltip from '@/components/toolTip/ToolTip.vue';

const AggregationTooltip = defineComponent({
  name: 'AggregationTooltip',
  /* eslint-disable vue/no-unused-components */
  components: { Tooltip },
  template: `
    <Tooltip
      :tooltip-text="'Die dargestellten Werte in der Aggregationsebene der Postleitzahl stellen immer die Gesamtheit alle Gebäude dar. Die Auswahl der Lead-Liste auf der Karte filtert ausschließlich die relevanten Einzelgebäude.'"
    />
  `,
});

const mapStore = useMapStore();

const route = useRoute();
const router = useRouter();

const showLegend = ref(false);
const loaded = ref(false);

onMounted(async () => {
  initializeMap();
});

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
}

function onMapStyleLoad() {
  fetchAndFilterLeadList();
}

onBeforeUnmount(() => {
  mapStore.showMainMenu = false;
});

function flyToBBox() {
  return axios({
    method: 'GET',
    url: `/api/usecase-4/initial-map-bbox/${selectedSalesRegionId.value}/`,
  }).then((resp) => {
    const bbox = resp.data.bbox;
    fitBBox(bbox);
  });
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

const selectedSalesRegionId = ref(parseInt(route.params.salesRegionId));
const salesRegion = await restFetch(
  SalesRegionDto,
  selectedSalesRegionId.value,
);

const leadLists = computed(() => [
  { id: null, title: 'Keine' },
  ...salesRegion.lead_lists,
]);
const leadListBuildings = ref();
const selectedLeadListId = ref(parseInt(route.params.leadListId) || null);
const selectedLeadListName = computed(() => {
  if (selectedLeadListId.value === null) return 'Keine';
  return leadLists.value.find((e) => e.id === selectedLeadListId.value).title;
});

watch(selectedLeadListId, async (newLeadListId) => {
  await router.push({
    name: 'mapView4',
    params: { leadListId: newLeadListId },
  });
  await fetchAndFilterLeadList();
});

async function fetchAndFilterLeadList() {
  if (selectedLeadListId.value) {
    const leadListData = await axios({
      method: 'GET',
      url: `/api/usecase-4/leadlist/${selectedLeadListId.value}/buildings/`,
    });
    leadListBuildings.value = leadListData.data;
  } else {
    leadListBuildings.value = null;
  }
  filterLeadList();
}

function filterLeadList() {
  let filter = null;
  if (leadListBuildings.value !== null) {
    filter = ['in', ['get', 'id'], ['literal', leadListBuildings.value]];
  }
  mapStore.addFilter([LAYER_KEY__BUILDINGS], filter, 'leadListFilter');
}

function navigateToDetail() {
  router.push({
    name: 'Usecase4SalesRegionEvaluationFilter',
    params: { salesRegionId: selectedSalesRegionId.value },
  });
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
