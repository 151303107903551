<template>
  <div
    v-if="mapActionActive && !mapStore.showMeasureToolForm"
    class="standard-elevation-2 hide-scrollbar absolute top-[80px] z-10 flex h-fit w-[360px] flex-col gap-2.5 overflow-y-auto rounded-md bg-white p-2.5"
    :style="panelPositions['mapActionActive']"
  >
    <PvProjectForm v-if="mapStore.showCustomPvProjectForm" :map-store />
    <ClusterForm v-else-if="mapStore.showClusterForm" :map-store />
    <EstateSearchForm v-else-if="mapStore.showEstateSearchForm" :map-store />
    <ExternalProjectForm
      v-else-if="mapStore.showExternalProjectForm"
      :map-store
    />
  </div>
</template>

<script setup>
import { usePanel } from '@/composables/usePanel';
import ClusterForm from '../ClusterForm.vue';
import EstateSearchForm from '../EstateSearchForm.vue';
import ExternalProjectForm from '../ExternalProjectForm.vue';
import PvProjectForm from '../PvProjectForm.vue';
import { toRefs, watch } from 'vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { mapActionActive } = toRefs(props.mapStore);

const { panelPositions, addPanel, removePanel } = usePanel(
  'mapActionActive',
  true,
);

watch(mapActionActive, (newValue) => {
  if (newValue) {
    addPanel();
  } else {
    removePanel();
  }
});
</script>
