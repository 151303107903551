<template>
  <FormValidator v-model="isFormValid">
    <FormTabs :tabs="tabs">
      <template #slot0>
        <div class="min-h-[72px]">
          <InputEl
            v-model="scenario.title"
            :label="metaData.title.label"
            :rules="{ ...formRules.title }"
            :unique-check-values="filteredScenarioNamesByProject"
            class="mb-4 w-[565px]"
          />
        </div>
      </template>
      <template #slot1>
        <VuetifyDataTable
          :item-data="assumptionsScenarioOptions"
          :headers
          :show-radio="true"
          :show-expand="false"
          :header-small="true"
          @selected-radio="scenario.assumptions_scenario = $event"
        >
          <template #title="{ item }">
            <div class="flex flex-col gap-3 pr-5 pl-0">
              <h5>{{ item.title }}</h5>
            </div>
          </template>
          <template #created_at="{ item }">
            <div>
              {{ new Date(item.created_at).toLocaleDateString('de-DE') }}
            </div>
          </template>
          <template #functions="{ item }">
            <div class="mr-4 flex items-center justify-end">
              <a
                :href="router.resolve({ name: 'AssumptionsScenario' }).href"
                target="_blank"
              >
                <IconWrapper
                  :id="item.id"
                  :size="32"
                  fill="text-color1"
                  hover="hover:text-active-color1"
                  icon="article"
                  class="cursor-pointer"
                  type="round"
                />
              </a>
            </div>
          </template>
        </VuetifyDataTable>
      </template>
      <template #slot2>
        <InputEl
          v-model="scenario.demographic_development_yearly_validated"
          :label="metaData.demographic_development_yearly.label"
          :input-type="getFieldType(metaData.demographic_development_yearly)"
          suffix="%"
          class="mb-3 w-[565px]"
        />
        <InputEl
          v-model="scenario.economic_development_yearly_validated"
          :label="metaData.economic_development_yearly.label"
          :input-type="getFieldType(metaData.economic_development_yearly)"
          suffix="%"
          class="mb-3 w-[565px]"
        />
      </template>
    </FormTabs>
  </FormValidator>
  <div class="mt-2 flex items-center justify-between gap-5">
    <div class="ml-auto flex gap-5">
      <ButtonEl text="Abbrechen" color="color2" @click="emit('abort')" />
      <ButtonEl
        text="Speichern"
        :disabled="!isFormValid || !scenario.assumptions_scenario"
        @click="createDialogOpen = true"
      />
    </div>
  </div>

  <VuetifyDialog
    v-if="createDialogOpen"
    v-model="createDialogOpen"
    title="Szenario erstellen"
    :persistent="true"
    width="620px"
  >
    <template #content>
      Mit der Bestätigung durch „Anlegen“ wird das Szenario angelegt und die
      Szenarienberechnung gestartet. Dieser Vorgang kann nicht abgebrochen
      werden. Je nach Größe des Projektgebiets kann die Berechnung einige Zeit
      in Anspruch nehmen.
    </template>
    <template #actions>
      <ButtonEl
        text="zurück"
        color="color2"
        @click="createDialogOpen = false"
      />
      <ButtonEl text="Anlegen" @click="save" />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { ScenarioDto } from '@/apps/usecase-1/scenario/scenario-dto';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import {
  convertMetaDataToFormRules,
  getFieldType,
  restGetOrFetchMetaData,
  restPost,
} from '@/utils/rest-utils';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';

const router = useRouter();

const metaData = await restGetOrFetchMetaData(ScenarioDto);
const formRules = convertMetaDataToFormRules(metaData);

const createDialogOpen = ref(false);

const scenario = ref(new ScenarioDto());
const assumptionsScenarioOptions = ref([]);

const tabs = [
  {
    title: 'Basisparameter',
    icon: 'text_snippet',
  },

  {
    title: 'Szenariorahmen',
    icon: 'crop_free',
  },

  {
    title: 'Demografie & Industrieentwicklung',
    icon: 'insert_chart_outlined',
  },
];

const headers = [
  {
    title: 'Szenariorahmen',
    key: 'title',
    align: 'left',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '150px',
    align: 'end',
  },
  {
    title: '',
    key: 'functions',
    width: '300px',
    align: 'end',
    sortable: false,
  },
];

const isFormValid = ref(false);

const emit = defineEmits(['abort', 'update-instances', 'instance-created']);
const props = defineProps({
  projectId: {
    type: Number,
    required: true,
  },
  scenarios: {
    type: Array,
  },
});

const filteredScenarioNamesByProject = computed(() =>
  props.scenarios.filter(({ project }) => project === props.projectId),
);

onMounted(() => {
  axios({
    method: 'GET',
    url: '/api/usecase-1/select-options-assumptions-scenario/',
  }).then((resp) => {
    assumptionsScenarioOptions.value = resp.data.result
      .filter((item) => item.is_released)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  });
});

function save() {
  scenario.value.project = props.projectId;
  restPost(scenario.value, [
    'title',
    'assumptions_scenario',
    'demographic_development_yearly',
    'economic_development_yearly',
    'project',
  ])
    .then((resp) => {
      emit('abort');
      emit('instance-created', resp.data.id);
    })
    .catch((e) => {
      console.log(e);
    });
}
</script>
