<template>
  <RouterLink class="home-icon" :to="getUseCaseRoute">
    <img src="@/assets/svg/enbw-logo-small.svg" alt="logo" />
  </RouterLink>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { rawCardData } from '@/apps/landing-page/raw-card-data';

const cardData = rawCardData;
const router = useRouter();

const getUseCaseRoute = computed(() => {
  const currentRoute = '/' + window.location.pathname.split('/')[1];

  return (
    router
      .getRoutes()
      .find(
        (route) =>
          route.path === currentRoute &&
          cardData.some((card) => card.routeName === route.name),
      ) || '/'
  );
});
</script>

<style scoped>
.home-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 8px;
  background-color: var(--color-color1);
}
</style>
