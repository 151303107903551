<template>
  <div class="mt-auto ml-auto">
    <ButtonEl
      text="Einschätzung Ambition"
      icon="insert_drive_file"
      color="color2"
      icon-type="round"
      class="whitespace-nowrap"
      @click="setModalActive"
    />
    <VuetifyDialog
      v-model="active"
      :title="'Einschätzung politische Ambition\nzum EE-Ausbau'"
      activator-class="absolute"
      :persistent="true"
      :hide-close-button="true"
    >
      <template #content>
        <div class="flex flex-col gap-5 py-5">
          <div
            v-for="item in fieldConfig"
            :key="item"
            class="standard-elevation-0-dark flex flex-col gap-2.5 rounded-xs p-5"
          >
            <h4 class="text-title-neutral">{{ item.title }}</h4>

            <VRadioGroup v-model="item.ambitionValue" inline>
              <VRadio class="mt-3" :value="null">
                <template #label>
                  <div class="body-2 mt-0.5">Unbekannt</div>
                </template>
              </VRadio>
              <VRadio :value="0" class="mt-3">
                <template #label>
                  <div class="body-2 mt-0.5 whitespace-nowrap">
                    Keine Ambition
                  </div>
                </template>
              </VRadio>
              <div class="flex">
                <VRadio
                  class="mt-3"
                  :value="item.ambitionValue || 'fieldIsEmpty'"
                />
                <InputEl
                  v-model="item.ambitionTempField"
                  label="Ambition Zubau"
                  input-type="float"
                  placeholder="Bitte eingeben"
                  class="min-w-[210px]"
                  :suffix="item.fieldSuffix"
                  @update:model-value="item.ambitionValue = $event"
                />
              </div>
            </VRadioGroup>
          </div>
          <div class="h-full">
            <TextAreaEl
              v-model="description"
              label="Zusätzliche Anmerkungen"
              class="max-h-[100px]"
            />
          </div>
        </div>
      </template>
      <template #actions>
        <div class="flex w-full justify-between gap-5">
          <ButtonEl text="Abbrechen" color="color2" @click="active = false" />
          <div class="flex gap-5">
            <ButtonEl
              text="Werte löschen"
              color="color2"
              variant="secondary"
              :icon-right="true"
              icon="restart_alt"
              @click="deleteValues"
            />
            <ButtonEl
              text="Speichern"
              :icon-right="true"
              icon="save"
              :disabled="fieldConfigIsValid"
              @click="save"
            />
          </div>
        </div>
      </template>
    </VuetifyDialog>
  </div>
</template>
<script setup>
import InputEl from '@/components/input/InputEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { computed, ref, watch } from 'vue';
import TextAreaEl from '@/components/textAreaEl/TextAreaEl.vue';

const props = defineProps({
  project: { required: true, type: Object },
});
const emit = defineEmits(['patch-project']);
const active = ref(false);
const description = ref('');
const fieldConfig = ref([
  {
    title: 'PV-Dach',
    ambitionValue: null,
    fieldSuffix: 'MW(el)/a',
    fieldName: 'ambition_pv_roof_mw_a',
    ambitionTempField: null,
  },
  {
    title: 'PV-Freifläche',
    ambitionValue: null,
    fieldSuffix: 'MW(el)/a',
    fieldName: 'ambition_pv_free_mw_a',
    ambitionTempField: null,
  },
  {
    title: 'Wind',
    ambitionValue: null,
    fieldSuffix: 'MW(el)/a',
    fieldName: 'ambition_wind_mw_a',
    ambitionTempField: null,
  },
]);

function setModalActive() {
  active.value = true;
  initValues();
}

function initValues() {
  fieldConfig.value.forEach((e) => {
    e.ambitionValue = props.project[e.fieldName];
    e.ambitionTempField = e.ambitionValue || null;
  });

  description.value = props.project.description_ambitions;
}

function deleteValues() {
  fieldConfig.value.forEach((e) => {
    e.ambitionValue = null;
  });
  description.value = null;
  save();
}

function save() {
  const payload = { description_ambitions: description.value };
  fieldConfig.value.forEach((e) => (payload[e.fieldName] = e.ambitionValue));
  emit('patch-project', payload);
  active.value = false;
}

const fieldConfigIsValid = computed(() => {
  return fieldConfig.value.some(
    (e) =>
      e.ambitionValue === 'fieldIsEmpty' ||
      e.ambitionValue < 0 ||
      e.ambitionValue === '-',
  );
});

watch(
  fieldConfig,
  (fields) => {
    fields.forEach((field) => {
      field.ambitionTempField = field.ambitionValue || null;
    });
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
:deep(.v-selection-control-group) {
  gap: 12px;

  .v-selection-control__wrapper {
    width: 24px !important;
  }
}
</style>
