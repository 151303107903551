<template>
  <InfoPanelItem
    v-for="item in simpleItems"
    :key="item.key"
    :item="item"
    class="text-neutral relative flex items-center px-2.5 py-3"
  />

  <VCarousel
    v-if="chartItems.length > 0"
    class="carousel-custom"
    height="350px"
  >
    <template #prev="{ props: prevProps }">
      <IconWrapper
        v-if="chartItems.length > 1"
        icon="arrow_back"
        class="cursor-pointer"
        fill="text-color1"
        type="sharp"
        @click="prevProps.onClick"
      />
    </template>
    <template #next="{ props: nextProps }">
      <IconWrapper
        v-if="chartItems.length > 1"
        icon="arrow_forward"
        class="cursor-pointer"
        fill="text-color1"
        type="sharp"
        @click="nextProps.onClick"
      />
    </template>
    <template #item="{ props: dotProps }">
      <div
        v-if="chartItems.length > 1"
        :class="
          dotProps.class.includes('v-btn--active')
            ? 'bg-color1 pointer-events-none'
            : 'border-color1 hover:bg-active-color1 border bg-white hover:border-none'
        "
        class="mb-[14px] h-4 w-4 cursor-pointer rounded-full"
        @click="dotProps.onClick"
      ></div>
    </template>
    <VCarouselItem v-for="item in chartItems" :key="item.title">
      <BasicPieChart
        class="h-full w-full px-1.5"
        :title="item.title"
        :chart-data="chartData(item)"
        :custom-radius="['45%', '59%']"
        :series-position="['50%', '45%']"
        :title-inside="item.titleInside"
        custom-title-classes="text-color1 -mb-5"
        :custom-wrapper-padding="chartItems.length > 1 ? 'py-5' : 'pt-5 pb-0'"
        legend-orient="horizontal"
        :show-label="false"
        legend-type="scroll"
        :legend-border-radius="4"
        legend-bg-color="white"
        :legend-top="null"
        :legend-right="null"
        legend-bottom="24px"
        :chart-unit="item.unit"
        :title-custom-size="13"
        :legend-data="item.legend || []"
        :hide-sum="item.hideSum"
      />
    </VCarouselItem>
  </VCarousel>
</template>

<script setup>
import InfoPanelItem from '@/apps/features/map/info-panel/InfoPanelItem.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import { computed } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const chartItems = computed(() =>
  props.items.filter(
    (e) => 'panelItemType' in e && e.panelItemType === 'pieChart',
  ),
);
const simpleItems = computed(() =>
  props.items.filter(
    (e) => !('panelItemType' in e) || e.panelItemType !== 'pieChart',
  ),
);

function chartData(item) {
  const data = [];
  if (!item.value) return data;
  // if custom transfor function is defined, it is called in
  // panel-data-calculator and a value prop is set accordingly
  if (Object.hasOwn(item, 'customDataTransformFunction')) {
    return item.value;
  }
  item.value.forEach((e) => {
    const series = e[item.seriesFieldName];
    const value = e[item.valueFieldName];
    data.push({
      color: item.style[series].color,
      name: item.style[series].label,
      value: item.multiply ? value * item.multiply : value,
    });
  });
  return data;
}
</script>

<style lang="scss">
.carousel-custom {
  .v-carousel__controls {
    background: transparent;
    max-height: 40px;
    height: fit-content;
    gap: 8px;
  }
}
</style>
