<template>
  <ToolTip open-on-click clean-content prevent-close-on-hover>
    <IconWrapper
      :size="32"
      type="round"
      fill="text-color1"
      hover="hover:text-active-color1"
      icon="file_download"
      class="cursor-pointer"
    />
    <template #text>
      <div class="standard-elevation-0-dark rounded-xs bg-white">
        <div
          v-for="option in Object.values(downloadOptions)"
          :key="option.label"
          class="body-2 text-core-dark hover:bg-active-area flex cursor-pointer items-center justify-between gap-2.5 p-2.5 whitespace-nowrap"
          :class="{
            'pointer-events-none opacity-50':
              option.isLoading || option.isDownloadReady,
          }"
          @click="option.onClick()"
        >
          <div class="mt-0.5">
            {{ option.label }}
          </div>
          <div class="flex items-center">
            <div
              v-if="option.isLoading"
              class="flex h-6 w-6 items-center justify-center"
            >
              <VProgressCircular
                indeterminate
                color="color1"
                size="20"
                :width="3"
              />
            </div>
            <IconWrapper
              v-else-if="option.isDownloadReady"
              icon="download_done"
              class="cursor-pointer"
            />
            <IconWrapper v-else icon="download" />
          </div>
        </div>
      </div>
    </template>
  </ToolTip>
</template>

<script setup lang="ts">
import { ref, onUnmounted, onMounted, computed, watch } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import axios from '@/utils/axiosHelper';
import { useToastStore } from '@/stores/toast-store';

interface DownloadableFile {
  state: string;
  file: string;
  export_option: string;
}

interface LeadList {
  id: number;
  title: string;
  sales_region: number;
  downloadable_file?: DownloadableFile | null;
}

interface ExportFileStates {
  downloadable_file?: DownloadableFile | null;
}

interface DownloadFormat {
  label: string;
  fileExtension: string;
  isLoading: boolean;
  isDownloadReady: boolean;
  downloadUrl: string | null;
  onClick: () => void;
}

const props = defineProps<{
  leadList: LeadList;
  exportFileStates: ExportFileStates;
}>();

const { showToast } = useToastStore();

const downloadOptions = ref<Record<string, DownloadFormat>>({
  BUILDING_CSV: {
    label: 'CSV',
    fileExtension: 'csv',
    isLoading: false,
    isDownloadReady: false,
    downloadUrl: null,
    onClick: () => createBuildingExport('BUILDING_CSV'),
  },
  BUILDING_GEOJSON: {
    label: 'GeoJSON (Projektion EPSG:4326)',
    fileExtension: 'geojson',
    isLoading: false,
    isDownloadReady: false,
    downloadUrl: null,
    onClick: () => createBuildingExport('BUILDING_GEOJSON'),
  },
  BUILDING_SHAPE: {
    label: 'ESRI Shapefile (Projektion EPSG:4326)',
    fileExtension: 'shp',
    isLoading: false,
    isDownloadReady: false,
    downloadUrl: null,
    onClick: () => createBuildingExport('BUILDING_SHAPE'),
  },
  POSTALCODE_CSV: {
    label: 'Postleitzahlen CSV',
    fileExtension: 'csv',
    isLoading: false,
    isDownloadReady: false,
    downloadUrl: null,
    onClick: () => createPOSTALCODE_CSVExport(),
  },
  report: {
    label: 'Powerpoint',
    fileExtension: 'pptx',
    isLoading: false,
    isDownloadReady: false,
    downloadUrl: null,
    onClick: () => downloadReport(),
  },
});
const buildingExports = ['BUILDING_CSV', 'BUILDING_GEOJSON', 'BUILDING_SHAPE'];

function resetBuildingExport() {
  buildingExports.forEach((e) => {
    downloadOptions.value[e].isDownloadReady = false;
    downloadOptions.value[e].isLoading = false;
  });
}
// /**
//  * Downloads a file that is already prepared (state: READY)
//  * @param url - The URL of the file to download
//  * @param option - The download format option being processed
//  */
// function downloadReadyFile(url: string, option: DownloadFormat): void {
//   option.isLoading = true;
//
//   axios({
//     method: 'GET',
//     url,
//     responseType: 'blob',
//   })
//     .then((res) => {
//       const urlFilename = url.split('/').pop();
//       const regExpFilename = /filename\*=utf-8''(?<filename>.*)/;
//       const filename = decodeURIComponent(
//         regExpFilename.exec(res.headers['content-disposition'])?.groups
//           ?.filename ??
//           urlFilename ??
//           '',
//       );
//
//       const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
//       const link = document.createElement('a');
//       link.href = blobUrl;
//       link.setAttribute('download', filename);
//       document.body.appendChild(link);
//       link.click();
//       link.remove();
//     })
//     .catch((e) => {
//       console.error('Download failed:', e);
//       showToast({
//         color: 'error',
//         message: 'Download fehlgeschlagen',
//       });
//     })
//     .finally(() => {
//       option.isLoading = false;
//     });
// }

async function downloadReport() {
  showToast({
    color: 'info',
    message: 'PowerPoint wird generiert...',
  });

  try {
    downloadOptions.value.report.isLoading = true;
    const res = await axios({
      method: 'GET',
      url: `/api/ppt-gen/heat_pump_sales/${props.leadList.id}/`,
      responseType: 'blob',
    });

    const regExpFilename = /filename\*=utf-8''(?<filename>.*)/;
    const originalFilename = decodeURIComponent(
      regExpFilename.exec(res.headers['content-disposition'])?.groups
        ?.filename ?? 'Report.pptx',
    );
    const filename = `${props.leadList.title}_${originalFilename}`;
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    showToast({
      color: 'success',
      message: 'PowerPoint wurde erfolgreich generiert',
    });
  } catch (e) {
    console.error('Download failed:', e);
    showToast({
      color: 'error',
      message: 'PowerPoint konnte nicht generiert werden',
    });
  } finally {
    downloadOptions.value.report.isLoading = false;
  }
}

async function createBuildingExport(downloadOption: string) {
  try {
    const option = downloadOptions.value[downloadOption];
    option.isLoading = true;
    await axios({
      method: 'GET',
      url: `/api/usecase-4/export/create-download-file/${props.leadList.id}/${option.fileExtension}/`,
    });
  } catch (error) {
    console.error('Error initiating download:', error);
  }
}

async function createPOSTALCODE_CSVExport() {
  try {
    downloadOptions.value.POSTALCODE_CSV.isLoading = true;
    await axios({
      method: 'GET',
      url: `/api/usecase-4/export/create-postal-code-download/${props.leadList.id}/`,
    });
  } catch (error) {
    console.error('Error initiating download:', error);
  }
}

watch(
  () => props.exportFileStates,
  (newVal) => {
    let option;
    const fileState = newVal.downloadable_file;
    if (!fileState) return;
    resetBuildingExport();
    option = downloadOptions.value[fileState.export_option];
    option.isDownloadReady = fileState.state === 'READY';
    option.isLoading = fileState.state === 'PENDING';
    option.downloadUrl = fileState.file;
  },
  { immediate: true },
);
</script>
