<template>
  <div
    style="width: 340px"
    class="dataPanelExpandablesWrapper border-line-disabled-neutral border-t"
  >
    <VExpansionPanels v-model="activeExpandableIndex" variant="accordion">
      <VExpansionPanel
        v-for="(expandable, index) in panelData.chips[activeChipIndex].items"
        :key="index"
        data-test="info-panel-expansion-panel"
        class="rounded-b-[8px]"
        elevation="0"
      >
        <VExpansionPanelTitle
          class="text-title-neutral rounded-none px-2.5"
          :data-test="expandable.title"
          ><h6>
            {{ expandable.title }}
          </h6>
          <template #actions>
            <IconWrapper
              :size="16"
              :icon="activeExpandableIndex === index ? 'remove' : 'add'"
            />
          </template>
        </VExpansionPanelTitle>
        <VExpansionPanelText class="bg-active-area">
          <InfoPanelDataSubPanel :items="expandable.items" />
        </VExpansionPanelText>
      </VExpansionPanel>
    </VExpansionPanels>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InfoPanelDataSubPanel from '@/apps/features/map/info-panel/InfoPanelDataSubPanel.vue';

const activeExpandableIndex = ref(0);
const emit = defineEmits(['close-info-panel']);
const props = defineProps({
  panelData: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  activeChipIndex: {
    type: Number,
  },
  feature: {
    type: Object,
  },
});

watch(
  () => props.activeChipIndex,
  () => {
    activeExpandableIndex.value = 0;
  },
);

watch(
  () => props.panelData,
  (newPanelData, oldPanelData) => {
    if (newPanelData.name !== oldPanelData.name) {
      activeExpandableIndex.value = 0;
    }
  },
);
</script>

<style lang="scss">
.dataPanelExpandablesWrapper {
  border-radius: 0 0 8px 8px;

  .v-input__details {
    display: none;
  }

  .box-shadow-custom {
    box-shadow: 0 1px 6px #00000029;
    padding-bottom: 1px;

    &:last-child {
      margin-bottom: -1px;
    }
  }

  .v-field__append-inner {
    padding-top: 0;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
  }

  .v-expansion-panel-title {
    padding: 16px 8px;
    min-height: 44px !important;
    background-color: white;
  }

  .v-expansion-panel-title__overlay {
    opacity: 0;
  }
}
</style>
