<template>
  <div
    class="standard-elevation-0-dark grid w-full grid-cols-3 gap-5 rounded-lg p-5"
  >
    <InputEl
      v-model="analysis.title"
      label="Name"
      placeholder="Bitte eingeben"
      class="w-full"
      :disabled="readOnly"
      :rules="
        getValidationRules(formRules.title, readOnly ? null : potentialsNames)
      "
      @update:model-value="checkUniqueTitle"
    />
    <InputEl
      v-model="analysis.description"
      label="Beschreibung"
      :placeholder="readOnly ? null : 'Bitte eingeben'"
      class="w-full"
      :disabled="readOnly"
      :rules="getValidationRules(formRules.description)"
    />
    <DropDown
      v-model="analysis.data_basis"
      :items-data="[
        {
          label: 'Gebietsauswahl über administrative Grenzen',
          value: 'administrative',
        },
        {
          label: 'EnBW-Projektpipeline PV-Freifläche',
          value: 'projectPipeline',
        },
      ]"
      items-data-key="label"
      :initial-selection="true"
      label="Datengrundlage"
      class="w-full"
      :disabled="readOnly"
    />
  </div>
  <div
    class="standard-elevation-0-dark mt-5 flex h-full w-full gap-5 rounded-lg bg-white p-5"
  >
    <div class="mt-40 ml-20 flex w-[425px] flex-col gap-5">
      <h4>Gebietsauswahl</h4>
      <DropDownMultipleGrouped
        v-model="municipalitySelectItems"
        class="w-full"
        :items="municipalitySelectItems"
        placeholder="Gemeinden auswählen"
        :max-preview-items="1"
        label="Gebiet"
        item-type-all="Gemeinden"
        :disabled="readOnly"
      />
    </div>
    <div class="h-[550px] w-[460px]">
      <GeoMunicipalityChart
        v-if="municipalitySelectItems.length > 0"
        :selected="selected"
        :geo-items="municipalities"
        :read-only
        @geo-unselected="setUnSelected"
        @geo-selected="setSelected"
      />
    </div>
  </div>
</template>
<script setup>
import GeoMunicipalityChart from '@/components/charts/GeoChart.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import DropDownMultipleGrouped from '@/components/dropDownMultipleGrouped/DropDownMultipleGrouped.vue';
import InputEl from '@/components/input/InputEl.vue';
import { useSyncGeoMapMunicipalitySelection } from '@/composables/syncGeoMapMunicipalitySelection';
import { getValidationRules } from '@/utils/rest-utils';
import { useVModel } from '@vueuse/core';
import { watchEffect } from 'vue';

const props = defineProps({
  formRules: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  potentialsNames: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'title-unique']);
const analysis = useVModel(props, 'modelValue', emit);

function checkUniqueTitle(newTitle) {
  const isUnique = !props.potentialsNames
    .map((name) => name.trim().toLowerCase())
    .includes(newTitle?.trim().toLowerCase());
  emit('title-unique', isUnique);
}

const {
  setSelected,
  setUnSelected,
  selected,
  municipalitySelectItems,
  municipalities,
} = useSyncGeoMapMunicipalitySelection(new Set(analysis.value.municipalities));

watchEffect(() => {
  analysis.value.municipalities = selected.value;
});
</script>
