<template>
  <div class="flex items-start justify-start gap-1">
    <div v-if="item.type !== 'gradient'" class="mt-[4.5px] flex h-fit">
      <v-avatar v-if="item.image" size="14">
        <img :src="item.image" alt="avatar" />
      </v-avatar>
      <div
        v-else
        :class="iconStyles(item.icon, item.name === 'Potenzialfläche')"
        class="standard-elevation-0-dark bg-white p-[2px]"
      >
        <div
          :class="iconStylesChild(item.icon, item.name === 'Potenzialfläche')"
          :style="{
            backgroundColor: item.color,
            // If borderColor is provided, apply styles
            borderColor: item.borderColor ? item.borderColor : 'transparent',
            borderWidth: item.borderColor ? '2px' : '0',
            borderStyle: item.borderColor ? 'solid' : 'none',
          }"
        ></div>
      </div>
    </div>
    <div v-if="item.type !== 'gradient'" class="legend-entries">
      <span class="body-3 text-blue-grey-900">
        {{ item.name }}
      </span>
    </div>
    <div v-else>
      <div class="body-3 text-blue-grey-900">
        {{ item.name }}
      </div>
      <div class="body-3 flex items-center">
        <div class="w-fit">
          {{ item.items.min.value.toLocaleString('de-DE') }}
        </div>
        <div
          class="mx-1 w-full text-center text-xs"
          :style="`background: linear-gradient(to right, ${item.items.min.color}, ${item.items.max.color})`"
        >
          {{ item.unit }}
        </div>
        <div>
          {{ item.items.max.value.toLocaleString('de-DE') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    iconStyles: {
      type: Function,
      required: true,
    },
    iconStylesChild: {
      type: Function,
      required: true,
    },
  },
};
</script>
