<template>
  <VExpansionPanels variant="accordion" eager>
    <div class="w-full space-y-2.5 rounded-md">
      <VExpansionPanel v-for="panel in panels" :key="panel.title">
        <VExpansionPanelTitle>
          <div class="flex items-center gap-2">
            <h5 class="text-title-neutral">{{ panel.title }}</h5>
            <ToolTip
              v-if="panel.tooltip"
              :tooltip-text="panel.tooltip"
              class="z-10 max-h-[18px]"
            />
          </div>
          <template #actions="{ expanded }">
            <IconWrapper
              :icon="expanded ? 'remove' : 'add'"
              fill="text-color1"
            />
          </template>
        </VExpansionPanelTitle>
        <VExpansionPanelText class="panel-content">
          <!-- Simple checkbox list panels -->
          <div v-if="isCheckboxPanel(panel)" class="-mt-2 space-y-2">
            <CheckboxEl
              v-for="item in panel.content"
              :key="item.value"
              v-model="model[panel.key]"
              :value="item.value"
              :label="item.label"
              :disabled="shouldDisableCheckbox(panel.key, item.value)"
            />
          </div>

          <!-- Nested panels with sub-options -->
          <div v-if="isPanelWithSubPanels(panel)" class="-m-5 space-y-4">
            <VExpansionPanels variant="accordion" eager>
              <VExpansionPanel
                v-for="subPanel in panel.subPanels"
                :key="subPanel.title"
                elevation="0"
              >
                <VExpansionPanelTitle>
                  <h5 class="text-color1">{{ subPanel.title }}</h5>
                  <template #actions="{ expanded }">
                    <IconWrapper
                      :icon="
                        expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                      "
                      fill="text-color1"
                    />
                  </template>
                </VExpansionPanelTitle>
                <VExpansionPanelText class="sub-panel-content">
                  <!-- Checkbox list -->
                  <div v-if="isCheckboxSubPanel(subPanel)" class="space-y-2">
                    <CheckboxEl
                      v-for="item in subPanel.content"
                      :key="item.value"
                      v-model="model[subPanel.key]"
                      :value="item.value"
                      :label="item.label"
                      :disabled="
                        shouldDisableCheckbox(subPanel.key, item.value)
                      "
                      hide-details
                      density="compact"
                    />
                  </div>

                  <!-- Range panel -->
                  <div v-if="isRangePanel(subPanel)" class="space-y-4">
                    <div class="flex flex-col gap-2.5">
                      <template v-if="subPanel.isPercentage">
                        <div class="space-y-1">
                          <VRangeSlider
                            :model-value="getRangeSliderValue(subPanel)"
                            :min="0"
                            :max="100"
                            :step="1"
                            color="#000099"
                            track-fill-color="#0059B9"
                            hide-details
                            strict
                            :ripple="false"
                            class="m-0"
                            @update:model-value="
                              (val: [number, number]) =>
                                updateRangeSliderValue(subPanel, val)
                            "
                          />

                          <!-- Dots -->
                          <div class="relative flex justify-between">
                            <div
                              v-for="i in 11"
                              :key="i"
                              class="h-1.5 w-1.5 rounded-full"
                              :class="[
                                (i - 1) * 10 >=
                                  Number(model[subPanel.minKey] ?? 0) * 100 &&
                                (i - 1) * 10 <=
                                  Number(model[subPanel.maxKey] ?? 100) * 100
                                  ? 'bg-[#0059B9]'
                                  : 'bg-[#9E9EDA]',
                              ]"
                            />
                          </div>
                          <!-- Values -->
                          <div
                            class="caption-3 text-core-dark flex justify-between pt-0.5"
                          >
                            <div>0</div>
                            <div class="pl-3">50</div>
                            <div>100</div>
                          </div>
                        </div>
                      </template>
                      <div class="grid grid-cols-2 gap-5">
                        <InputEl
                          v-model="model[subPanel.minKey]"
                          :input-type="
                            subPanel.isPercentage ? 'percent' : 'float'
                          "
                          :suffix="subPanel.unit"
                        />
                        <InputEl
                          v-model="model[subPanel.maxKey]"
                          :input-type="
                            subPanel.isPercentage ? 'percent' : 'float'
                          "
                          :suffix="subPanel.unit"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Boolean toggle -->
                  <div v-if="isBooleanPanel(subPanel)">
                    <VRadioGroup v-model="model[subPanel.key]" hide-details>
                      <VRadio :value="true" label="ja" />
                      <VRadio :value="false" label="nein" />
                    </VRadioGroup>
                  </div>

                  <!-- Input min share panel -->
                  <template v-if="isInputMinSharePanel(subPanel)">
                    <div class="space-y-4">
                      <VRadioGroup
                        v-model="model[subPanel.inputKey]"
                        hide-details
                      >
                        <VRadio
                          v-for="choice in filtersetOptions[subPanel.inputKey]
                            .choices"
                          :key="choice.value"
                          :value="choice.value"
                          :label="choice.display_name"
                        />
                      </VRadioGroup>

                      <div class="space-y-2">
                        <div class="mt-4 grid grid-cols-2 items-center gap-2">
                          <span class="body-2 text-core-dark"
                            >Mindestanteil</span
                          >
                          <InputEl
                            v-model="model[subPanel.minShareKey]"
                            input-type="percent"
                            :min="0"
                            :max="1"
                            :disabled="!model[subPanel.inputKey]"
                            suffix="%"
                          />
                        </div>
                        <VSlider
                          v-if="model[subPanel.minShareKey] !== undefined"
                          v-model="model[subPanel.minShareKey]"
                          :min="0"
                          :max="1"
                          :step="0.01"
                          color="#000099"
                          track-fill-color="#0059B9"
                          hide-details
                          :disabled="!model[subPanel.inputKey]"
                          :ripple="false"
                          class="m-0"
                        />
                        <!-- Dots for VSlider -->
                        <div class="relative flex justify-between">
                          <div
                            v-for="i in 11"
                            :key="i"
                            class="h-1.5 w-1.5 rounded-full"
                            :class="[
                              (i - 1) * 0.1 <= model[subPanel.minShareKey]
                                ? 'bg-[#0059B9]'
                                : 'bg-[#9E9EDA]',
                            ]"
                          />
                        </div>
                        <!-- Values -->
                        <div
                          class="caption-3 text-core-dark flex justify-between pt-0.5"
                        >
                          <div>0</div>
                          <div class="pl-3">50</div>
                          <div>100</div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- Edge case panel -->
                  <div v-if="isEdgeCasePanel(subPanel)" class="space-y-4">
                    <div v-if="subPanel.key === 'age-slider-custom'">
                      <VRangeSlider
                        :model-value="[model.min_age ?? 0, model.max_age ?? 90]"
                        :min="0"
                        :max="90"
                        :step="10"
                        color="#000099"
                        track-fill-color="#0059B9"
                        hide-details
                        strict
                        :ripple="false"
                        class="m-0"
                        @update:model-value="
                          (val: [number, number]) => {
                            const [newMin, newMax] = val;
                            if (newMax - newMin >= 10) {
                              model.min_age = newMin;
                              model.max_age = newMax === 90 ? null : newMax;
                            } else if (model.min_age === newMin) {
                              model.max_age = newMin + 10;
                            } else {
                              model.min_age = newMax - 10;
                            }
                          }
                        "
                      />
                      <!-- Dots for VSlider -->
                      <div class="relative flex justify-between">
                        <div
                          v-for="i in 10"
                          :key="i"
                          class="h-1.5 w-1.5 rounded-full"
                          :class="[
                            (i - 1) * 10 >= model.min_age &&
                            (i - 1) * 10 <= (model.max_age ?? 90)
                              ? 'bg-[#0059B9]'
                              : 'bg-[#9E9EDA]',
                          ]"
                        />
                      </div>
                      <!-- Added values under each dot -->
                      <div
                        class="caption-3 text-core-dark relative mt-2 flex justify-between pt-0.5"
                      >
                        <div v-for="i in 9" :key="i" class="text-center">
                          {{ (i - 1) * 10 }}
                        </div>
                        <!-- infinity symbol -->
                        <div class="text-center">&#8734;</div>
                      </div>

                      <div class="space-y-2">
                        <div class="mt-4 grid grid-cols-2 items-center gap-2">
                          <span class="body-2 text-core-dark"
                            >Mindestanteil</span
                          >
                          <InputEl
                            v-model="model.min_age_share"
                            input-type="percent"
                            :min="0"
                            :max="1"
                            suffix="%"
                          />
                        </div>
                        <VSlider
                          v-if="model.min_age_share !== undefined"
                          v-model="model.min_age_share"
                          :min="0"
                          :max="1"
                          :step="0.01"
                          color="#000099"
                          track-fill-color="#0059B9"
                          hide-details
                          :ripple="false"
                          class="m-0"
                        />
                        <!-- Dots for VSlider -->
                        <div class="relative flex justify-between">
                          <div
                            v-for="i in 11"
                            :key="i"
                            class="h-1.5 w-1.5 rounded-full"
                            :class="[
                              (i - 1) * 0.1 <= model.min_age_share
                                ? 'bg-[#0059B9]'
                                : 'bg-[#9E9EDA]',
                            ]"
                          />
                        </div>
                        <!-- Values -->
                        <div
                          class="caption-3 text-core-dark flex justify-between pt-0.5"
                        >
                          <div>0</div>
                          <div class="pl-3">50</div>
                          <div>100</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </VExpansionPanelText>
              </VExpansionPanel>
            </VExpansionPanels>
          </div>
        </VExpansionPanelText>
      </VExpansionPanel>
    </div>
  </VExpansionPanels>
</template>

<script setup lang="ts">
import InputEl from '@/components/input/InputEl.vue';
import { ref, computed, type Ref } from 'vue';
import { restGetOrFetchMetaData } from '@/utils/rest-utils';
import { EvaluationFiltersetDto } from './evaluation-filterset-dto';
import type {
  FilterSet,
  Panel,
  SubPanel,
  CheckboxPanel,
  RangePanel,
  BooleanPanel,
  PanelWithSubPanels,
  InputMinSharePanel,
  EdgeCasePanel,
} from './types';
import { useVModel } from '@vueuse/core';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';

const props = defineProps<{
  modelValue: FilterSet | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FilterSet): void;
}>();

const model = useVModel(props, 'modelValue', emit) as Ref<FilterSet>;

const filtersetOptions = ref(
  await restGetOrFetchMetaData(EvaluationFiltersetDto),
);

// If this is a new filter (because there is no other filter available and it got initialized just as a EvaluationFiltersetDto in the parent component) populate all choice fields with all available values
if (model.value.id === null) {
  Object.entries(filtersetOptions.value).forEach(
    ([key, option]: [string, any]) => {
      if (option.child?.choices) {
        // For checkbox/choice fields, set all choices as selected
        model.value[key as keyof FilterSet] = option.child.choices.map(
          (choice: { value: any }) => choice.value,
        );
      }
    },
  );
}

// Type guard functions
function isCheckboxPanel(panel: Panel): panel is CheckboxPanel {
  return 'content' in panel;
}

function isPanelWithSubPanels(panel: Panel): panel is PanelWithSubPanels {
  return 'subPanels' in panel;
}

function isRangePanel(subPanel: SubPanel): subPanel is RangePanel {
  return 'type' in subPanel && subPanel.type === 'range';
}

function isBooleanPanel(subPanel: SubPanel): subPanel is BooleanPanel {
  return 'type' in subPanel && subPanel.type === 'boolean';
}

function isCheckboxSubPanel(subPanel: SubPanel): subPanel is CheckboxPanel {
  return 'content' in subPanel;
}

function isInputMinSharePanel(
  subPanel: SubPanel,
): subPanel is InputMinSharePanel {
  return 'type' in subPanel && subPanel.type === 'input-min-share';
}

function isEdgeCasePanel(subPanel: SubPanel): subPanel is EdgeCasePanel {
  return 'type' in subPanel && subPanel.type === 'edge-case';
}

// Create panel functions
function createChoicePanel(key: keyof FilterSet) {
  const option = filtersetOptions.value[key];
  return {
    title: option.child.label,
    key,
    content: option.child.choices.map((choice: any) => ({
      value: choice.value,
      label: choice.display_name,
    })),
  } satisfies CheckboxPanel;
}

function createRangePanel(
  minKey: keyof FilterSet,
  maxKey: keyof FilterSet,
): RangePanel {
  const minOption = filtersetOptions.value[minKey];
  const maxOption = filtersetOptions.value[maxKey];
  const unit = minOption.unit || maxOption.unit;
  const isPercentage = unit === '%';

  return {
    title: minOption.label.replace(' Minimum', ''),
    type: 'range',
    minKey,
    maxKey,
    unit,
    isPercentage,
  } satisfies RangePanel;
}

function createBooleanPanel(key: keyof FilterSet) {
  const option = filtersetOptions.value[key];
  return {
    title: option.label,
    type: 'boolean' as const,
    key,
  } satisfies BooleanPanel;
}

function createInputMinSharePanel(
  inputKey: keyof FilterSet,
  minShareKey: keyof FilterSet,
) {
  const option = filtersetOptions.value[inputKey];
  return {
    title: option.label,
    type: 'input-min-share' as const,
    inputKey,
    minShareKey,
  } satisfies InputMinSharePanel;
}

function createEdgeCasePanel(key: string, title: string) {
  return {
    title,
    type: 'edge-case' as const,
    key,
  } satisfies EdgeCasePanel;
}

const panels = computed((): Panel[] => [
  createChoicePanel('existing_customers'),
  // createChoicePanel('feedback'),
  {
    title: 'Gebäudeattribute',
    subPanels: [
      createChoicePanel('allowed_sectors'),
      createChoicePanel('allowed_building_types'),
      createChoicePanel('construction_year'),
      createChoicePanel('allowed_heating_types'),
      createChoicePanel('allowed_roof_types'),
      createRangePanel('min_usable_area_m2', 'max_usable_area_m2'),
      createRangePanel('min_floor_count', 'max_floor_count'),
      createRangePanel('min_ownership_rate', 'max_ownership_rate'),
    ],
  },
  {
    title: 'Wärmekennzahlen',
    subPanels: [
      createChoicePanel('allowed_heat_pump_potentials'),
      createRangePanel('min_heat_demand_kwh_a', 'max_heat_demand_kwh_a'),
      createRangePanel('min_heat_demand_kw', 'max_heat_demand_kw'),
      createRangePanel(
        'min_linear_heat_density_kwh_m',
        'max_linear_heat_density_kwh_m',
      ),
    ],
  },
  {
    title: 'Elektrifizierung',
    subPanels: [
      // createBooleanPanel('pv_plant_is_available'),
      createRangePanel('min_pv_roof_potential_kw', 'max_pv_roof_potential_kw'),
      // createBooleanPanel('battery_is_available'),
      // createBooleanPanel('electric_mobility_is_available'),
    ],
  },
  {
    title: 'Demografie',
    tooltip:
      'Die Altersanteile an einem Gebäude beziehen sich immer auf eine Gitterzelle von 100 x 100 m, in der sich das Gebäude befindet. Die Anteile geben die Altersverteilung aller Gebäude wider, so wie sie vom Zensus 2011 erfasst wurde.',
    subPanels: [
      createEdgeCasePanel('age-slider-custom', 'Alter'),
      createInputMinSharePanel('gender', 'min_gender_share'),
    ],
  },
  // createChoicePanel('allowed_sinus_milieus'),
  // {
  //   title: 'Grundversorgungsstatus',
  //   subPanels: [
  //     createChoicePanel('allowed_default_suppliers'),
  //     createRangePanel('min_market_share_enbw', 'max_market_share_enbw'),
  //   ],
  // },
]);

// Helper functions for range value handling
function getRangeSliderValue(subPanel: RangePanel) {
  return [
    Number(model.value[subPanel.minKey] ?? 0) * 100,
    Number(model.value[subPanel.maxKey] ?? 100) * 100,
  ];
}

function updateRangeSliderValue(
  subPanel: RangePanel,
  [min, max]: [number, number],
) {
  model.value[subPanel.minKey] = min / 100;
  model.value[subPanel.maxKey] = max / 100;
}

function shouldDisableCheckbox(key: keyof FilterSet, value: any): boolean {
  // If the model value for this key doesn't exist or isn't an array, return false
  if (!model.value[key] || !Array.isArray(model.value[key])) {
    return false;
  }

  const currentValues = model.value[key] as any[];

  // If this is the only checked item, disable it to prevent unchecking
  return currentValues.length === 1 && currentValues.includes(value);
}
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  &::after {
    display: none;
  }
}

.bg-primary {
  background-color: #000099;
}

.bg-subtle {
  background-color: #f5f8fa;
}
</style>
