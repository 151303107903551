<template>
  <aside
    class="standard-elevation-2 hide-scrollbar container-positioning flex max-w-sm flex-col gap-4 overflow-visible rounded-md bg-white p-0.5"
  >
    <header
      class="text-color1 standard-elevation-0-dark flex h-10 items-center justify-between gap-1 rounded-sm"
    >
      <button
        ref="measurementDropdown"
        class="standard-elevation-0-dark relative h-full min-w-[150px] grow rounded-sm px-2 py-0.5"
        :class="
          measureToolValue
            ? 'hover:bg-active-area cursor-pointer'
            : 'bg-brown-grey hover:bg-brown-grey'
        "
        @click="openOptions"
      >
        <div class="flex items-center justify-between">
          <div class="flex flex-col items-start justify-between gap-0.5">
            <h6 class="text-neutral">
              {{ lastActiveMode === 'draw_measure_line' ? 'Länge' : 'Fläche' }}
            </h6>
            <h6 class="text-neutral">{{ calculatedMeasureToolValue }}</h6>
          </div>
          <IconWrapper icon="keyboard_arrow_down" />
        </div>
        <template v-for="(option, i) in options" :key="i">
          <nav
            v-if="optionsOpen && option.units.includes(unit)"
            class="body-2 text-neutral absolute top-0 left-0 z-200 w-full overflow-hidden rounded-sm bg-white shadow-md"
            @click.stop="optionsOpen = false"
          >
            <button
              v-for="item in option.items"
              :key="item.unit"
              class="hover:bg-active-area h-10 w-full p-2.5 text-left"
              @click="unit = item.unit"
            >
              {{ item.title }}
            </button>
          </nav>
        </template>
      </button>
      <div class="flex">
        <ToolTip tooltip-text="Wert kopieren" :offset="2" :close-delay="0">
          <ButtonEl
            icon="copy_all"
            variant="primary"
            color="color2"
            class="h-10 w-10 rounded-none"
            @click="copyToClipboard(calculatedMeasureToolValue)"
          />
        </ToolTip>

        <ButtonEl
          icon="close"
          variant="primary"
          color="color2"
          class="h-10 w-10 rounded-md rounded-l-none"
          @click="abort"
        />
      </div>
    </header>
  </aside>
</template>

<script setup>
import { draw } from '@/apps/features/map/draw/draw';
import { computed, ref, toRefs, watch } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { useToastStore } from '@/stores/toast-store';
import { onClickOutside } from '@vueuse/core';
import ButtonEl from '@/components/button/ButtonEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { toLocaleStr } from '@/utils/formatUtils';

const props = defineProps({
  mapStore: {
    type: Object,
  },
});

const { showToast, hideToast } = useToastStore();
const { measureToolValue, drawModeActive } = toRefs(props.mapStore);

const optionsOpen = ref(false);
const unit = ref('m');
const lastActiveMode = ref('draw_measure_line');
const measurementDropdown = ref(null);

const options = [
  {
    units: ['m', 'km'],
    items: [
      { title: 'Meter', unit: 'm' },
      { title: 'Kilometer', unit: 'km' },
    ],
  },
  {
    units: ['m²', 'ha', 'km²'],
    items: [
      { title: 'Quadratmeter', unit: 'm²' },
      { title: 'Hektar', unit: 'ha' },
      { title: 'Quadratkilometer', unit: 'km²' },
    ],
  },
];

const relevantDrawingModes = {
  draw_measure_line: 'm',
  draw_measure_polygon: 'm²',
};

onClickOutside(measurementDropdown, () => {
  optionsOpen.value = false;
});

const calculatedMeasureToolValue = computed(() => {
  const conversionFactors = {
    km: 1000,
    ha: 10000,
    'km²': 1000000,
  };

  if (!measureToolValue.value) {
    return `0 ${unit.value}`;
  }

  let result = measureToolValue.value;
  const factor = conversionFactors[unit.value] ?? 1;
  result = Number(result) / factor;

  return `${toLocaleStr(result, 0, 2)} ${unit.value}`;
});

function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
  showToast({
    color: 'color1',
    message: 'Wert wurde in die Zwischenablage kopiert.',
    timeout: 2000,
  });
}

function abort() {
  props.mapStore.resetMapAction(draw);
}

function openOptions() {
  if (measureToolValue.value) {
    optionsOpen.value = true;
  }
}

watch(drawModeActive, (newVal) => {
  if (Object.keys(relevantDrawingModes).includes(newVal)) {
    lastActiveMode.value = newVal;
    unit.value = relevantDrawingModes[newVal];
  }
});

watch(
  () => measureToolValue.value,
  (val) => {
    if (val) {
      hideToast();
    } else {
      showToast({
        color: 'color1',
        message:
          'Entfernungen und Flächen können gemessen werden, indem eine Strecke oder Fläche auf der Karte durch Klicken erzeugt wird.',
        timeout: -1,
        clearable: false,
        maxWidth: '85%',
      });
    }
  },
  { immediate: true },
);
</script>
