<template>
  <div
    class="standard-elevation-0-dark flex w-full flex-col items-start justify-center gap-2.5 rounded-sm p-2.5"
    data-test="info-panel-subtitles"
  >
    <div class="flex w-full flex-col gap-2.5">
      <InfoEntries
        v-for="subtitle in filteredSubtitles"
        :key="subtitle.index"
        :subtitle="subtitle"
      />

      <!-- Render filtered subtitles -->
      <div
        v-if="filteredSpecialSubtitles.length > 0"
        class="grid auto-cols-fr grid-flow-col gap-2.5 whitespace-nowrap"
      >
        <div
          v-if="
            filteredSpecialSubtitles.some(
              (subtitle) =>
                subtitle.subtitle === 'is_favorite' && subtitle.value,
            )
          "
          class="bg-active-area text-color1 flex items-center justify-center gap-1 rounded-xs p-1"
        >
          <IconWrapper icon="stars" :size="16" />
          <span class="caption-1 pt-0.5 text-blue-800">Prioritätsfläche</span>
        </div>
        <div
          v-if="
            filteredSpecialSubtitles.some(
              (subtitle) => subtitle.subtitle === 'review_state',
            )
          "
          :class="[
            'flex items-center justify-center gap-1 rounded-xs p-1',
            reviewStateStyle.color,
          ]"
        >
          <IconWrapper :icon="reviewStateStyle.icon" :size="16" />
          <span class="caption-1 pt-0.5">{{ reviewStateStyle.label }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="selectedAnalysis && mapStore.$id === 'MapUsecase1'"
      class="caption-3 text-title-neutral bg-subtle rounded-xs px-3 py-2"
    >
      <div class="mt-[1px]">Szenario: {{ selectedAnalysis.title }}</div>
    </div>
  </div>
</template>

<script setup>
import InfoEntries from '@/apps/features/map/info-panel/InfoEntries.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import MoreOptions from '@/components/moreOptions/MoreOptions.vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps({
  subtitles: Array,
  feature: Object,
  mapStore: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['open-dialog']);

const { selectedAnalysis } = storeToRefs(props.mapStore);

// Filter subtitles
const filteredSubtitles = computed(() =>
  props.subtitles.filter(
    (subtitle) =>
      subtitle.subtitle !== 'is_favorite' &&
      subtitle.subtitle !== 'review_state',
  ),
);

const filteredSpecialSubtitles = computed(() =>
  props.subtitles.filter(
    (subtitle) =>
      subtitle.subtitle === 'is_favorite' ||
      subtitle.subtitle === 'review_state',
  ),
);

function getReviewStateStyle(reviewState) {
  const reviewStateMap = {
    TODO: {
      icon: 'pending_actions',
      color: 'text-spot-warning bg-fill-warning',
      label: 'Prüfung ausstehend',
    },
    YES: {
      icon: 'check',
      color: 'text-spot-success bg-fill-success',
      label: 'Fortführung',
    },
    NO: {
      icon: 'cancel',
      color: 'text-spot-error bg-fill-error',
      label: 'Abbruch/nicht relevant',
    },
  };

  return reviewStateMap[reviewState];
}

const reviewStateStyle = computed(() => {
  const reviewState = filteredSpecialSubtitles.value.find(
    (subtitle) => subtitle.subtitle === 'review_state',
  )?.value;
  return getReviewStateStyle(reviewState);
});
</script>
