<template>
  <div class="standard-elevation-2 rounded-2xl p-5">
    <div class="flex h-full gap-10">
      <div class="flex w-[50%] flex-col">
        <h4 class="text-color1 mb-1">Gebietsauswahl</h4>
        <InputSearch
          v-model="selected"
          :search-keys="['title']"
          :items="
            searchFieldMunis.sort((a, b) => a.title.localeCompare(b.title))
          "
          item-value="id"
        />

        <div class="chart-item mt-2 p-4">
          <h5 class="text-title-neutral mb-2.5">
            Zieljahr {{ resultStore.activeProject.target_year }} - Einfärbung
            nach
          </h5>
          <VRadioGroup v-model="highlightCategory">
            <div class="flex justify-between">
              <div v-for="(choice, index) in choices" :key="index">
                <VRadio
                  v-for="radio in choice"
                  :key="radio.value"
                  :value="radio.value"
                >
                  <template #label>
                    <p class="body-3 ml-1.5">
                      {{ `${radio.label} in ${radio.unit}` }}
                    </p>
                  </template>
                </VRadio>
              </div>
            </div>
          </VRadioGroup>
        </div>
        <div class="flex w-full justify-center">
          <div class="flex flex-col justify-between">
            <div>
              <div class="h-[600px] w-[530px]">
                <GeoMunicipalityChart
                  v-if="municipalities.length > 0"
                  ref="geoChartRef"
                  :selected
                  :geo-items="municipalities"
                  :single-select="true"
                  :style-border-on-select="true"
                  :style-area-on-select="false"
                  @geo-selected="setSelected"
                />
                <div v-else class="flex h-[500px] items-center justify-center">
                  <VProgressCircular
                    class="text-color1"
                    size="30"
                    width="3"
                    indeterminate
                  />
                </div>
              </div>
              <div class="mt-2">
                <div class="gradient-box"></div>
                <div class="body-2 mt-5 flex justify-between px-2.5">
                  <p>
                    &#60;
                    {{ toLocaleStr(ranges.min) }}
                    {{ geoChartUnit }}
                  </p>
                  <p>
                    &#62;
                    {{ toLocaleStr(ranges.max) }}
                    {{ geoChartUnit }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToolTip class="my-auto w-full" :slot-max-width="380">
          <div
            class="bg-color1 flex h-fit w-full items-center justify-start gap-2.5 rounded-xs py-4 pr-5 pl-[54px] text-white"
          >
            <div class="flex items-center gap-3">
              <VProgressCircular
                v-model="municipalityData.autarkie"
                size="42"
                width="6"
                bg-color="#1867C0"
                color="white"
              />
              <h2>
                {{ toLocaleStr(municipalityData.autarkie) + '%' }}
              </h2>
            </div>
            <span class="body-2 mt-[5px]">
              Bilanzieller Autarkiegrad (Status Quo)
            </span>
          </div>
          <template #text>
            Bilanzieller Autarkiegrad berechnet aus MW-Bestand multipliziert mit
            Volllaststunden aus Szenariorahmen
          </template>
        </ToolTip>
      </div>
      <div class="h-full w-[50%]">
        <div
          v-if="selected"
          class="standard-elevation-0-dark flex flex-col gap-5 rounded-xs p-5"
        >
          <h4 class="text-title-neutral">Nachfrage</h4>
          <div class="chart-item pt-3">
            <div class="flex w-full items-center justify-between px-4">
              <h5 class="text-color1">Strom</h5>
              <VRadioGroup v-model="chartRadioValue">
                <div class="flex justify-end">
                  <VRadio value="mwh" @click="chartRadioValue = 'mwh'">
                    <template #label>
                      <h5 class="text-title-neutral mr-2 ml-1">Bedarf</h5>
                    </template>
                  </VRadio>
                  <VRadio value="mw" @click="chartRadioValue = 'mw'">
                    <template #label>
                      <h5 class="text-title-neutral ml-1">Last</h5>
                    </template>
                  </VRadio>
                </div>
              </VRadioGroup>
            </div>
            <BarChartBasic
              :stacks="electricityBarChartData"
              :show-legend="false"
              :x-axis-data="[
                'Status Quo',
                resultStore.activeProject.target_year,
              ]"
              class="min-h-[200px] shadow-none"
              bar-width="42px"
              grid-top="18%"
              :chart-unit="getChartUnit['electricity']"
              :y-axis-label-custom-fixed-width="60"
              :y-axis-name="getChartUnit['electricity']"
            />

            <BasicPieChart
              :chart-data="electricityPieChartData"
              :title="`Strombedarf ${resultStore.activeProject.target_year} nach Bedarfsbetreibern`"
              :custom-radius="['65%', '95%']"
              :series-position="['33%', '50%']"
              custom-title-classes="mb-3 text-sm"
              legend-right="10%"
              chart-unit="MWh(el)/a"
              class="h-[200px]"
              title-inside="MWh(el)/a"
              :show-label="false"
              :show-chart-unit-inside-title="false"
            />
          </div>

          <div class="chart-item pt-3">
            <div class="flex w-full items-center justify-between px-4">
              <h5 class="text-color1">Wärme</h5>
              <VRadioGroup v-model="chartRadioValue">
                <div class="flex justify-end">
                  <VRadio value="mwh" @click="chartRadioValue = 'mwh'">
                    <template #label>
                      <h5 class="text-title-neutral mr-2 ml-1">Bedarf</h5>
                    </template>
                  </VRadio>
                  <VRadio value="mw" @click="chartRadioValue = 'mw'">
                    <template #label>
                      <h5 class="text-title-neutral ml-1">Last</h5>
                    </template>
                  </VRadio>
                </div>
              </VRadioGroup>
            </div>
            <BarChartBasic
              :stacks="heatBarChartData"
              :show-legend="false"
              :x-axis-data="[
                'Status Quo',
                resultStore.activeProject.target_year,
              ]"
              class="min-h-[200px] shadow-none"
              bar-width="42px"
              grid-top="18%"
              :chart-unit="getChartUnit['heat']"
              :y-axis-name="getChartUnit['heat']"
              :y-axis-label-custom-fixed-width="60"
            />
          </div>
          <h4 class="text-title-neutral">EE-Erzeugung</h4>
          <div class="chart-item flex h-[250px]">
            <BasicPieChart
              :chart-data="demandData"
              title="Bestand"
              chart-unit="MW(el)"
              title-inside="MW(el)"
              :custom-radius="['45%', '65%']"
              custom-title-classes="text-color1 text-sm"
              :series-position="['40%', '50%']"
              :show-label="false"
              :show-chart-unit-inside-title="false"
            />
            <BasicPieChart
              :chart-data="potentialData"
              title="Potenzial"
              title-inside="MW(el)"
              :custom-radius="['45%', '65%']"
              custom-title-classes="text-color1 text-sm"
              :series-position="['40%', '50%']"
              chart-unit="MW(el)"
              :show-label="false"
              :show-chart-unit-inside-title="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ml-auto flex w-fit gap-5">
    <SourcesItem item="area-evaluation" />
  </div>
</template>
<script setup>
import GeoMunicipalityChart from '@/components/charts/GeoChart.vue';
import { computed, nextTick, ref, watch } from 'vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import { AREA_EVAL } from '@/configs/color-constants';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import { useProjectResult } from '@/apps/usecase-1/projects/project-result-detail/project-result-store';
import { useBaseStore } from '@/apps/basemodule/base-store';
import BarChartBasic from '@/components/charts/BarChartBasic.vue';
import InputSearch from '../../../../components/inputSearch/InputSearch.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { toLocaleStr } from '@/utils/formatUtils';

const baseStore = useBaseStore();
const resultStore = useProjectResult();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});
// ----------- Geochart start --------------

await resultStore.fetchMunicipalityComparison(
  props.projectId,
  props.scenarioId,
);

const municipalities = ref([]);
const municipalityGeoData = await baseStore.fetchSimplifiedMuniData();
const searchFieldMunis = ref([]);
const municipalitiesProject = ref(resultStore.activeProject.municipalities);
const selected = ref(resultStore.activeProject.municipalities[0]);
const geoChartRef = ref(null);
const highlightCategory = ref('electricityDemandTargetYearMwh');

// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

const municipalityData = computed(() =>
  resultStore.comparisonDataMunicipality(selected.value),
);
const ranges = ref({
  min: 0,
  max: 0,
});

populateMunicipalities();

function populateMunicipalities() {
  const municipalitiesProjectSet = new Set(municipalitiesProject.value);
  for (const municipality of municipalityGeoData) {
    const isInProject = municipalitiesProjectSet.has(
      municipality.id.toString(),
    );
    if (isInProject) {
      municipalities.value.push({
        isSelectable: isInProject,
        key: municipality.key,
        title: municipality.name,
        id: municipality.id.toString(),
        name: municipality.id.toString(),
        geometry_json: municipality.geometry_json,
        properties: municipality.properties,
        areaColor: getAreaColor(municipality.id.toString(), isInProject),
      });
      searchFieldMunis.value.push({
        title: municipality.name,
        id: municipality.id.toString(),
      });
    }
  }
}

function getAreaColor(municipalityId, isInProject) {
  if (!isInProject) return 'rgb(243,243,243)';
  const value =
    resultStore.comparisonDataMunicipality(municipalityId)[
      highlightCategory.value
    ];
  ranges.value =
    resultStore.getComparisonMunicipalityRanges[highlightCategory.value];
  const clampedValue = Math.max(
    ranges.value.min,
    Math.min(ranges.value.max, value),
  );
  const factorNormalized =
    (clampedValue - ranges.value.min) / (ranges.value.max - ranges.value.min);
  const startColor = hexToRgb('#f5f0ed'); // light color
  const endColor = hexToRgb('#676665'); // dark color
  const interpolatedColor = interpolateColor(
    startColor,
    endColor,
    factorNormalized,
  );

  return rgbToHex(interpolatedColor);
}

function interpolateColor(color1, color2, factor) {
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
  }
  return result;
}

function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

function rgbToHex(rgb) {
  return (
    '#' +
    rgb
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
}

// ----------- Geochart end --------------
// ----------- Pie chart start --------------
const electricityPieChartData = computed(() => [
  {
    name: 'Konventionell',
    color: AREA_EVAL.CONVENTIONAL,
    value: municipalityData.value.demandDriversTargetMwh?.base,
  },
  {
    name: 'E-Mobilität',
    color: AREA_EVAL.E_MOBILITY,
    value: municipalityData.value.demandDriversTargetMwh?.mobility,
  },
  {
    name: 'Elektrifizierung Wärme Einzelversorgung',
    color: AREA_EVAL.ELECTRIFICATION_HEAT_DEMAND,
    value: municipalityData.value.demandDriversTargetMwh?.heatPump,
  },
  {
    name: 'Elektrifizierung Wärmenetz',
    color: AREA_EVAL.ELECTRIFICATION_HEAT_NETWORK,
    value: municipalityData.value.demandDriversTargetMwh?.heatNetwork,
  },
]);

const demandData = computed(() => [
  {
    name: 'Wind',
    color: AREA_EVAL.WIND,
    value: municipalityData.value.windGenerationMw,
  },
  {
    name: 'PV-Freifläche',
    color: AREA_EVAL.PV_FREE,
    value: municipalityData.value.pvFreeGenerationMw,
  },
  {
    name: 'PV-Aufdach',
    color: AREA_EVAL.PV_ROOF,
    value: municipalityData.value.pvRoofGenerationMw,
  },
  {
    name: 'Wasserkraft',
    color: AREA_EVAL.WATER,
    value: municipalityData.value.hydropowerGenerationMw,
  },
  {
    name: 'Biomasse',
    color: AREA_EVAL.BIOMASS,
    value: municipalityData.value.biomassGenerationMw,
  },
]);

const potentialData = computed(() => [
  {
    name: 'Wind',
    color: AREA_EVAL.WIND,
    value: municipalityData.value.windPotentialMw,
  },
  {
    name: 'PV-Freifläche',
    color: AREA_EVAL.PV_FREE,
    value: municipalityData.value.pvFreePotentialMw,
  },
  {
    name: 'PV-Aufdach',
    color: AREA_EVAL.PV_ROOF,
    value: municipalityData.value.pvRoofPotentialMw,
  },
]);

// ----------- Pie chart end --------------
// ----------- Bar charts start --------------

const heatBarChartData = computed(() => [
  {
    name: 'Status Quo',
    itemStyle: {
      color: AREA_EVAL.STATUS_QUO,
    },
    data: [
      chartRadioValue.value === 'mw'
        ? municipalityData.value.heatDemandBaseYearMw
        : municipalityData.value.heatDemandBaseYearMwh,
      0,
    ],
  },
  {
    name: resultStore.activeProject.target_year,
    itemStyle: {
      color: AREA_EVAL.TARGET_YEAR,
    },
    data: [
      0,
      chartRadioValue.value === 'mw'
        ? municipalityData.value.heatDemandTargetYearMw
        : municipalityData.value.heatDemandTargetYearMwh,
    ],
  },
]);

const electricityBarChartData = computed(() => [
  {
    name: 'Status Quo',
    itemStyle: {
      color: AREA_EVAL.STATUS_QUO,
    },
    data: [
      chartRadioValue.value === 'mw'
        ? municipalityData.value.electricityDemandBaseYearMw
        : municipalityData.value.electricityDemandBaseYearMwh,
      0,
    ],
  },
  {
    name: resultStore.activeProject.target_year,
    itemStyle: {
      color: AREA_EVAL.TARGET_YEAR,
    },
    data: [
      0,
      chartRadioValue.value === 'mw'
        ? municipalityData.value.electricityDemandTargetYearMw
        : municipalityData.value.electricityDemandTargetYearMwh,
    ],
  },
]);

// ----------- Vertical Bar chart end --------------
// ----------- Radio Buttons start --------------
const chartRadioValue = ref('mwh');
const choices = {
  demand: [
    {
      label: 'Strombedarf',
      unit: 'MWh(el)/a',
      value: 'electricityDemandTargetYearMwh',
    },
    {
      label: 'Wärmebedarf',
      unit: 'MWh(th)/a',
      value: 'heatDemandTargetYearMwh',
    },
  ],
  renewable: [
    {
      label: 'EE-Bestand',
      unit: 'MW(el)',
      value: 'generationTotalMw',
    },

    {
      label: 'EE-Potenzial',
      unit: 'MW(el)',
      value: 'potentialTotalMw',
    },
  ],
  autarkie: [
    {
      label: 'Autarkie Status Quo',
      unit: '%',
      value: 'autarkie',
    },
  ],
};

const radioUnits = {
  electricity: {
    demand: 'MWh(el)/a',
    load: 'MW(el)',
  },
  heat: {
    demand: 'MWh(th)/a',
    load: 'MW(th)',
  },
};

const getChartUnit = computed(() => {
  const result = {};

  Object.entries(radioUnits).forEach(([type, units]) => {
    // Find the matching unit based on chartRadioValue
    result[type] =
      chartRadioValue.value === 'mwh'
        ? units.demand.toLowerCase().includes('mwh') && units.demand
        : units.load.toLowerCase().includes('mw') && units.load;
  });

  return result;
});

const geoChartUnit = computed(
  () =>
    Object.values(choices)
      .flat()
      .find((e) => e.value === highlightCategory.value).unit,
);

// watch highlightCategory for changes to adjust the area color
watch(highlightCategory, async () => {
  for (const municipality of municipalities.value) {
    municipality.areaColor = getAreaColor(
      municipality.id,
      municipality.isSelectable,
    );
  }
  await nextTick();
  geoChartRef.value.syncGeoSelect();
});

function setSelected(item) {
  selected.value = item;
}

// ----------- Radio Buttons end --------------
</script>

<style lang="scss" scoped>
@use 'partials/design-tokens';
.gradient-box {
  border-radius: 8px;
  height: 33px;
  background: linear-gradient(to right, #f5f0ed, #676665);
}

.chart-item {
  @extend .standard-elevation-0-dark;
  border-radius: var(--radius-md);
  overflow: hidden;
}
</style>
