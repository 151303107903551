<template>
  <VSlider
    v-model="year"
    :ticks="Object.assign({}, years)"
    :max="years.length - 1"
    step="1"
    show-ticks="always"
    tick-size="4"
    :ripple="false"
    :hide-details="true"
    class="year-slider rounded-md bg-white"
    data-test="year-slider"
  />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
  years: {
    type: Array,
    default: () => [],
  },
  mapStore: {
    type: Object,
    required: true,
  },
});

const year = ref(0);

watch(year, (val) => {
  props.mapStore.setYear(props.years[val]);
});
const scenarioYear = computed(() => props.mapStore.scenarioYear);
watch(scenarioYear, (val) => {
  year.value = props.years.findIndex((e) => e === val);
});
</script>
