<template>
  <VuetifyDialog
    v-model="showModal"
    :hide-close-button="true"
    :persistent="true"
    :overflow-visible="true"
    custom-classes="rounded-md"
    title="Projekt auswählen"
  >
    <template #content>
      <div class="flex flex-col gap-5">
        <FormValidator v-model="isFormValid">
          <DropDown
            v-model="selectedProjectName"
            :initial-selection="true"
            :items-data="projectNames"
            :rules="{ required: true }"
            label="Projekt"
          />
        </FormValidator>
        <ButtonEl
          :disabled="!isFormValid"
          text="Auswählen"
          class="w-fit"
          @click="submit()"
        />
      </div>
    </template>
  </VuetifyDialog>
</template>

<script setup>
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { ref } from 'vue';
import { Project } from '@/apps/usecase-1/map/project';
import DropDown from '@/components/DropDown/DropDown.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const showModal = ref(true);

const projects = (await Project.fetchAllDeep()).filter(
  (p) => p.scenarioDtos.filter((e) => e.progress === 0).length > 0,
);
const projectNames = projects.map((p) => p.projectDto.title);

const selectedProjectName = ref('');
const isFormValid = ref(false);

function submit() {
  const selectedProject = projects.find(
    (p) => p.projectDto.title === selectedProjectName.value,
  );
  const selectedProjectId = selectedProject.projectDto.id;

  let scenarioId = selectedProject.projectDto.default_scenario;
  if (scenarioId == null) {
    scenarioId = selectedProject.scenarioDtos[0].id;
  }

  router.push({
    name: 'mapView1',
    params: { projectId: selectedProjectId, scenarioId: scenarioId },
  });
}
</script>

<style scoped lang="scss"></style>
