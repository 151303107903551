<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="mapStore.showAnalysisSelector"
      :class="{ 'rounded-b-none': isExpanded }"
      class="standard-elevation-2 outer-container z-20 rounded-md bg-white"
    >
      <div class="relative flex w-full gap-2 p-1.5">
        <div class="flex w-full rounded-sm">
          <div
            ref="analysisWrapper"
            class="analysis-wrapper w-full"
            :class="{
              extended: isExpanded,
            }"
          >
            <div class="analysis-box flex gap-2">
              <slot name="before-title" />
              <div
                class="standard-elevation-0-dark text-color1 flex w-full cursor-pointer items-center justify-between gap-1.5 rounded-sm px-1.5 py-1"
                @click="isExpanded = !isExpanded"
              >
                <div class="flex flex-col">
                  <slot name="title-selected" />
                </div>
                <IconWrapper
                  icon="keyboard_arrow_down"
                  :class="[
                    {
                      'rotate-180': isExpanded,
                    },
                    'transform duration-300',
                  ]"
                />
              </div>
              <slot name="after-title" />
              <ButtonEl
                icon="close"
                color="color2"
                :edges="true"
                class="h-10 w-10 rounded-sm"
                @click="mapStore.toggleAnalysisSelector"
              />
            </div>
            <div v-if="isExpanded" class="analysis-list-wrapper" @click.stop>
              <InputSearch
                class="mx-1.5 overflow-hidden rounded"
                placeholder="Suche"
                :search-keys="['title']"
                :items
                :prevent-close-on-click-outside="true"
                @update:search="searchResults = $event"
                @update:search-text="searchText = $event"
              />
              <h6
                v-if="searchText && !searchResults?.length"
                class="p-2.5 text-center"
              >
                Keine Ergebnisse
              </h6>
              <div
                v-bind="containerProps"
                :style="{ maxHeight: maxHeight }"
                class="hide-scrollbar"
              >
                <VRadioGroup v-model="selected" v-bind="wrapperProps">
                  <div
                    v-for="(item, index) in analysesVirtualList"
                    :key="item.data.id"
                    class="rounded-sm px-1.5 py-[5px]"
                    @mouseenter="hoverIndex = index"
                    @mouseleave="hoverIndex = null"
                  >
                    <div
                      class="standard-elevation-0-dark hover:bg-active-area flex flex-col items-start gap-1.5 rounded-sm p-2.5 hover:border hover:border-white hover:p-[9px]"
                      :class="{
                        'bg-active-area border border-white p-[9px]':
                          item.data.id === selected,
                        'border border-white p-[9px]': hoverIndex === index,
                      }"
                    >
                      <div class="flex w-full flex-col gap-1">
                        <div class="flex items-center justify-between gap-1.5">
                          <VRadio
                            :value="item.data.id"
                            :density="null"
                            :disabled="disabledCallback(item.data)"
                          >
                            <template #label>
                              <h6
                                class="text-color1 hover:text-active-color1 -mb-0.5 flex"
                              >
                                {{ item.data.title }}
                              </h6>
                            </template>
                          </VRadio>
                          <div class="-mr-1">
                            <slot
                              name="title-icon"
                              :item="item"
                              :index="index"
                              :hover-index="hoverIndex"
                            />
                          </div>
                        </div>
                      </div>
                      <slot
                        name="additional-info"
                        :item="item"
                        :index="index"
                        :hover-index
                      />
                    </div>
                  </div>
                </VRadioGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { ref, computed, watchEffect, watch } from 'vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import {
  onClickOutside,
  onKeyStroke,
  useVirtualList,
  useVModel,
  useWindowSize,
} from '@vueuse/core';

const { height: containerHeight } = useWindowSize();

const props = defineProps({
  items: Array,
  modelValue: Number,
  mapStore: {
    type: Object,
    required: true,
  },
  disabledCallback: {
    type: Function,
    default: (_) => false,
  },
  itemHeightVirtualList: {
    type: Number,
    default: 113,
  },
  maxItemsShow: {
    type: Number,
    default: 5,
  },
});

const emit = defineEmits(['update:expanded', 'update:modelValue']);
const selected = useVModel(props, 'modelValue', emit);

const isExpanded = ref(false);
const analysisWrapper = ref(null);
const hoverIndex = ref(null);
const searchResults = ref(null);
const searchText = ref(null);

function closeSelector() {
  isExpanded.value = false;
  searchResults.value = null;
  searchText.value = null;
}

watch(selected, () => {
  closeSelector();
});

const filteredItems = computed(() => {
  if (!searchText.value) return props.items;
  if (!searchResults.value?.length) return [];
  const searchResultIds = searchResults.value.map((item) => item.id);
  return props.items.filter((item) => searchResultIds.includes(item.id));
});

const {
  list: analysesVirtualList,
  containerProps,
  wrapperProps,
} = useVirtualList(filteredItems, {
  itemHeight: props.itemHeightVirtualList,
  overscan: 3,
});

onClickOutside(analysisWrapper, () => {
  closeSelector();
});

onKeyStroke('Escape', () => {
  closeSelector();
});

const maxHeight = computed(() => {
  const itemCount = props.items.length;
  const maxItems = Math.min(itemCount, props.maxItemsShow);
  const calculatedHeight = props.itemHeightVirtualList * maxItems;
  return containerHeight.value - 192 < calculatedHeight
    ? `${containerHeight.value - 192}px`
    : `${calculatedHeight}px`;
});

watchEffect(() => {
  if (isExpanded.value) {
    emit('update:expanded');
  }
});
</script>

<style scoped lang="scss">
@use 'partials/design-tokens';

.outer-container {
  position: absolute;
  left: 50%;
  top: -4px;
  transform: translateX(-50%);
  min-width: 250px;

  @media (max-width: 1020px) {
    top: 48px;
  }
}

.analysis-wrapper {
  display: flex;
  flex-direction: column;

  .analysis-list-wrapper {
    @extend .standard-elevation-1;
    position: absolute;
    margin-top: 8px;
    padding-top: 0;
    top: 46px;
    left: 0;
    z-index: 10;
    background-color: var(--color-white);
    border-bottom-right-radius: var(--radius-md);
    border-bottom-left-radius: var(--radius-md);
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;

    &::before {
      position: absolute;
      left: 0;
      top: -3px;
      width: 100%;
      height: 3px;
      background-color: var(--color-white);
      content: '';
    }

    .analysis-item {
      @extend .body-2;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 8px;
      min-width: max-content;
      height: 40px;
      cursor: pointer;
      color: var(--color-neutral);
      background-color: var(--color-subtle);
      border-radius: var(--radius-xss);
    }
  }
}
</style>
