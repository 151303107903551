<template>
  <div class="absolute left-5 z-100 flex h-10 gap-5">
    <div class="flex w-[360px] gap-[5px]">
      <div class="standard-elevation-2 flex gap-[5px] rounded-md">
        <ToHome
          class="z-10"
          :class="{ 'rounded-r-none': !showAnalysisSelector }"
        />
        <!-- minimized version of analysis selector -->
        <IconWrapper
          v-show="!showAnalysisSelector"
          icon="pin_drop"
          fill="text-color1"
          :class="
            showAnalysisSelector
              ? '-ml-[45px] rounded-md'
              : '-ml-[5px] rounded-r-md'
          "
          class="h-10 cursor-pointer bg-white px-2 transition-all"
          icon-type="round"
          @click="toggleAnalysisSelector"
        />
      </div>

      <ButtonEl
        data-test="main-menu-toggle"
        icon="segment"
        :variant="toggleVariant('mainMenu')"
        :color="toggleColor('mainMenu')"
        icon-type="round"
        class="standard-elevation-2 h-10 rounded-md"
        @click="toggleMainMenu"
      />

      <SearchBar :map-store />

      <MapActionsDialog :map-store />
    </div>
    <SearchResults :map-store />
    <MapActionsToolBar v-if="Object.keys(mapActions).length" :map-store>
      <MeasuringToolForm v-if="mapStore.showMeasureToolForm" :map-store />
    </MapActionsToolBar>
  </div>
</template>

<script setup>
import ToHome from '@/apps/features/map/control-options/components/ToHome.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import SearchBar from '@/apps/features/map/control-options/search/SearchBar.vue';
import MapActionsDialog from '@/apps/features/map/control-options/components/MapActionsDialog.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { toRefs } from 'vue';
import MapActionsToolBar from './MapActionsToolBar.vue';
import SearchResults from '../search/SearchResults.vue';
import MeasuringToolForm from '@/apps/usecase-2/map/MeasuringToolForm.vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { showMainMenu, showAnalysisSelector, mapActions } = toRefs(
  props.mapStore,
);
const { toggleMainMenu, toggleAnalysisSelector } = props.mapStore;

function toggleVariant(type) {
  if (type === 'mainMenu') {
    return showMainMenu.value ? 'primary' : 'primary';
  }
}

function toggleColor(type) {
  if (type === 'mainMenu') {
    return showMainMenu.value ? 'color1' : 'color2';
  }
}
</script>
