<template>
  <div class="body-3 flex items-center pt-2">
    <div class="w-fit">
      {{ minValue.toLocaleString('de-DE') }}
    </div>
    <div
      class="mx-1 w-full rounded-full text-center text-xs"
      :style="`background: linear-gradient(to right, ${minColor}, ${maxColor})`"
    >
      {{ unit }}
    </div>
    <div>
      {{ maxValue.toLocaleString('de-DE') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    unit: {
      type: String,
      required: true,
    },
    minValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    minColor: {
      type: String,
      required: true,
    },
    maxColor: {
      type: String,
      required: true,
    },
  },
};
</script>
