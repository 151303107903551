<template>
  <div class="relative flex h-screen w-full">
    <LayerPanel
      v-if="loaded && !mapStore.searchResults"
      v-show="mapStore.showMainMenu"
      :map-store
    >
      <template #header>
        <LayerPanelAggregationLevels
          :agg-levels="[
            { name: 'Keine', layer: null },
            { name: 'Block', layer: LAYER_KEY__BLOCK },
            { name: 'Stadtteil', layer: LAYER_KEY__DISTRICT },
            { name: 'Gemeinde', layer: LAYER_KEY__MUNICIPALITY },
            { name: 'Landkreis', layer: LAYER_KEY__COUNTY },
            { name: 'Regierungsbezirk', layer: LAYER_KEY__GOVERNMENT_DISTRICT },
            { name: 'Bundesland', layer: LAYER_KEY__FEDERAL_STATE },
          ]"
          :global="true"
        />
      </template>
      <template #layer-toggles="slotProps">
        <AggregationToggle v-bind="slotProps" />
      </template>
    </LayerPanel>
    <div class="mapView relative h-full w-full">
      <template v-if="loaded">
        <BaseMap
          :map-store
          :selected-analysis-id="selectedScenarioId"
          :base-year="projectBaseYear"
          :layer-config="LayerConfig"
          :panel-objects="panelObjects"
          :fly-to-function="flyToBBox"
          @map-style-loaded-signal="onMapStyleLoad()"
        />
        <div class="top-controls absolute w-full">
          <ControlBar :map-store />
          <AnalysisSelector
            v-model="selectedScenarioId"
            :items="project.scenarioDtos.filter((e) => e.progress === 0)"
            :map-store
            :item-height-virtual-list="99"
          >
            <template #title-selected>
              <div class="caption-1 flex whitespace-nowrap">Szenario</div>
              <h5 class="max-w-60 truncate whitespace-nowrap">
                {{
                  `${project.projectDto.title}: ` +
                  project.scenarioDtos.find(
                    (item) => item.id === selectedScenarioId,
                  )?.title
                }}
              </h5>
            </template>
            <template #title-icon="{ item, index, hoverIndex }">
              <Transition name="fade">
                <IconWrapper
                  v-if="
                    hoverIndex === index || selectedScenarioId === item.data.id
                  "
                  :icon="IconGotoChart"
                  fill="text-color1"
                  hover="hover:text-active-color1"
                  class="cursor-pointer"
                  @click="navigateToDetail(item.data.id)"
                />
              </Transition>
            </template>
            <template #additional-info="{ item }">
              <div class="border-disabled-neutral w-full border-b"></div>
              <div class="text-neutral flex w-full flex-col gap-1">
                <div class="subtitle-3 text-title-neutral flex flex-col">
                  <div>Szenariorahmen:</div>
                  <div>
                    {{ item.data.assumptions_scenario_title }}
                  </div>
                </div>
              </div>
            </template>
          </AnalysisSelector>
          <LegendControls
            :map-store
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <TimeMachine
            :map-store
            data-test="time-machine"
            :years="projectYears"
          />
          <MapControls :map-store v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-1/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import TimeMachine from '@/apps/features/map/control-options/components/TimeMachine.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import { Project } from '@/apps/usecase-1/map/project';
import AggregationToggle from '@/apps/usecase-1/map/layer-panel/AggregationToggle.vue';
import { useMapStore } from './map-store';
import LayerPanelAggregationLevels from '@/apps/features/map/layer-panel/input-forms/LayerPanelAggregationLevels.vue';
import AnalysisSelector from '@/apps/features/map/control-options/components/AnalysisSelector.vue';
import { panelObjects } from '@/apps/usecase-1/map/info-panel/Info-panel-objects';
import axios from '@/utils/axiosHelper';
import { fitBBox, setFilter } from '@/mapbox/main';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { LAYER_KEY__GRID_STATIONS_SUBSTATION } from '@/apps/features/map/layer-config/base-data';
import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '@/apps/usecase-1/map/layer-config/geographical-level';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import IconGotoChart from '@/assets/icons/custom/misc/IconGotoChart.vue';

const mapStore = useMapStore();

const route = useRoute();
const router = useRouter();

const showLegend = ref(false);
const loaded = ref(false);

onMounted(async () => {
  if (setupError) {
    await router.push({ name: 'mapProjectSelection', params: {} });
    return;
  }
  initializeMap();
});

onBeforeUnmount(() => {
  mapStore.showMainMenu = false;
});

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
}

function flyToBBox() {
  return axios({
    method: 'GET',
    url: `/api/usecase-1/initial-map-bbox/${project.projectDto.id}/`,
  }).then((resp) => {
    const bbox = resp.data.bbox;
    fitBBox(bbox);
  });
}

function onMapStyleLoad() {
  setFilter(LAYER_KEY__GRID_STATIONS_SUBSTATION, [
    '==',
    ['get', 'operator'],
    'Netze BW',
  ]);
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

let project;
const selectedScenarioId = ref();
const currentScenarioDto = ref();
const projectBaseYear = ref();
const projectYears = ref();

watch(selectedScenarioId, async (newScenarioId) => {
  await router.push({
    name: 'mapView1',
    params: { projectId: project.projectDto.id, scenarioId: newScenarioId },
  });
  currentScenarioDto.value = project.scenarioDtos.find(
    (s) => s.id === newScenarioId,
  );
  mapStore.selectedAnalysis = currentScenarioDto.value;
  await flyToBBox();
});

function navigateToDetail(scenarioId) {
  let name = 'UseCase1ProjectResultOverview';
  if (
    [
      'UseCase1ProjectResultOverview',
      'UseCase1ProjectScenarioDemand',
      'UseCase1ProjectProjectRenewableGeneration',
      'UseCase1ProjectScenarioPowerBalance',
      'UseCase1ProjectScenarioGridPlanning',
      'UseCase1AreaEvaluation',
      'UseCase1ScenarioComparison',
      'UseCase1ProjectReports',
    ].includes(route.meta.lastVisitedRouteName)
  ) {
    name = route.meta.lastVisitedRouteName;
  }
  router.push({
    name,
    params: { projectId: project.projectDto.id, scenarioId },
  });
}

async function getProjectAndScenarios() {
  project = await Project.fetchDeep(route.params.projectId);
  projectBaseYear.value = project.projectDto.base_year;
  projectYears.value = project.projectDto.years;
  if (project.scenarioDtos.length === 0) {
    throw Error('project contains no scenarios');
  }
  currentScenarioDto.value = project.scenarioDtos.find(
    (s) => s.id.toString() === route.params.scenarioId,
  );

  if (currentScenarioDto.value == null) {
    throw Error(
      'Scenario with ID "' + route.params.scenarioId + '" does not exist',
    );
  }
  selectedScenarioId.value = currentScenarioDto.value.id;
  mapStore.selectedAnalysis = currentScenarioDto.value;
  mapStore.setYear(projectBaseYear.value);
}

let setupError = false;
try {
  await getProjectAndScenarios();
} catch (err) {
  console.log('Error getting project and scenario: ', err.message);
  //router.push does not work correctly in <script setup> block,
  //therefore set setupError so that onMounted hook does it
  setupError = true;
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
