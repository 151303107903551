<template>
  <div
    class="pade_subnavi side-bar-custom nav-drawer standard-elevation-2 absolute top-[80px] left-5 z-10 h-fit w-[360px] overflow-hidden rounded-md bg-white outline-hidden"
  >
    <div class="relative" :style="{ maxHeight: containerHeight - 160 + 'px' }">
      <!-- Scrollable content -->
      <div
        ref="scrollContainer"
        class="hide-scrollbar relative overflow-y-auto pt-2.5"
        :style="{ maxHeight: containerHeight - 160 + 'px' }"
        @scroll="stickyFooter?.checkPotentialAnalysisSectionsVisibility()"
      >
        <div class="flex items-center justify-between gap-2 p-2.5 pt-0">
          <h5 class="text-title-neutral select-none">Kartenansicht</h5>
          <slot name="header" />
        </div>
        <div
          v-for="(section, index) in layerPanelConfig.sections"
          v-show="section.show"
          :key="index"
          class="layer-panel-section flex-col"
          :class="{ flex: section.show }"
        >
          <div
            class="justify-space-between border-line-disabled-neutral text-color1 flex h-[41px] items-center gap-1.5 border-b p-2.5 select-none"
          >
            <div class="flex items-center gap-1.5">
              <IconWrapper v-if="section.icon" :icon="section.icon" />
              <h5 v-if="section.title">
                {{ section.title }}
              </h5>
            </div>
            <ButtonEl
              v-if="section.LayerShortCut"
              class="rounded-xxs -mr-0.5 h-5 w-5"
              size="m"
              icon="checklist_rtl"
              :color="section.active ? 'color1' : 'color2'"
              @click="toggleLayerShortCut(section)"
            />
          </div>
          <LayerPanelItem
            v-for="(group, groupIndex) in section.groups"
            :key="groupIndex"
            :menu-item="group"
            class="nav-menu-item"
            :open="open[0]"
            :map-store
            @emit-activator="setOpenedActivator($event)"
          >
            <template v-if="group.items">
              <LayerPanelItem
                v-for="(item, itemIndex) in group.items"
                :key="itemIndex"
                :data-test="`item-group-${item.title}`"
                :menu-item="item"
                :parent-id="group.id"
                :open="open[1]"
                subgroup
                small
                :item-level="1"
                :map-store
                @emit-activator="setOpenedActivator($event)"
              >
                <template v-if="item.items">
                  <LayerPanelItem
                    v-for="(level3Item, level3Index) in item.items"
                    :key="level3Index"
                    :menu-item="level3Item"
                    :parent-id="item.id"
                    :grand-parent-id="group.id"
                    :open="open[2]"
                    small
                    :item-level="2"
                    :map-store
                    @emit-activator="setOpenedActivator($event)"
                  >
                    <template #layer-toggles="slotProps">
                      <slot name="layer-toggles" v-bind="slotProps" />
                    </template>
                  </LayerPanelItem>
                </template>
                <template #layer-toggles="slotProps">
                  <slot name="layer-toggles" v-bind="slotProps" />
                </template>
              </LayerPanelItem>
            </template>
            <template #layer-toggles="slotProps">
              <slot name="layer-toggles" v-bind="slotProps" />
            </template>
          </LayerPanelItem>
        </div>
      </div>

      <LayerPanelStickyFooter
        v-if="mapStore.$id === 'MapUsecase2'"
        ref="stickyFooter"
        :scroll-container
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, toRefs, watch } from 'vue';
import LayerPanelItem from '@/apps/features/map/layer-panel/LayerPanelItem.vue';
import { useWindowSize } from '@vueuse/core';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import LayerPanelStickyFooter from '@/apps/features/map/layer-panel/components/LayerPanelStickyFooter.vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { layerPanelConfig } = toRefs(props.mapStore);

const { height: containerHeight } = useWindowSize();

const open = ref([null, null, null]); // Array to store open state for levels 0, 1, and 2

function setOpenedActivator({ title, level }) {
  open.value = open.value.map((item, idx) =>
    idx === level ? (item === title ? null : title) : item,
  );
}

const scrollContainer = ref({});
const stickyFooter = ref(null);

// ---------- LAYER SHORTCUT
function toggleLayerShortCut(section) {
  const onLayers = section.LayerShortCut;
  section.active = !section.active;
  props.mapStore.changeLayerVisibility({ onLayers, active: section.active });
}

watch(
  () => props.mapStore.getLayersVisible,
  (newVal) => {
    for (const section of layerPanelConfig.value.sections) {
      if (section.LayerShortCut) {
        section.active = section.LayerShortCut.every((e) => newVal.includes(e));
      }
    }
  },
);
</script>

<style lang="scss" scoped>
.layer-panel-section {
  .filter_group.nav-menu-item {
    border-bottom: 1px solid var(--color-line-disabled-neutral);
    .filter_group {
      &:not(:last-child),
      &.not-1st-level {
        border-bottom: 1px solid var(--color-line-disabled-neutral);
      }

      &:first-child {
        border-top: 1px solid var(--color-line-disabled-neutral);
      }
    }

    .filter_group:last-child .switchery-layer-custom .switch-list-item {
      &.not-1st-level:last-child {
        border-bottom: none;
      }
    }

    .filter_group:not(:last-child) .switchery-layer-custom {
      &.apply-border {
        border-bottom: 1px solid var(--color-white);
      }
    }
  }

  &:last-child .filter_group.nav-menu-item:last-child {
    border-bottom: none;
  }
}
</style>
