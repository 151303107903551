<template>
  <div class="flex flex-col gap-5">
    <TopHeaderBar go-back-to-custom="SalesRegionsList" />
    <div
      class="standard-elevation-2 project-result-detail flex h-full w-full flex-col gap-7 rounded-xl bg-white p-5"
    >
      <div class="text-title-neutral flex items-center justify-between gap-5">
        <div class="flex gap-1">
          <EditTitle
            :dto="SalesRegionDto"
            :instance-id="salesRegion.id"
            :title="salesRegion.title"
            :unique-check-values="salesRegions"
            :disabled="!userStore.uc4CanChangeSalesRegion"
            @title-changed="salesRegion.title = $event"
          />
          <ButtonEl
            icon="map"
            :icon-right="true"
            color="color2"
            class="h-10 w-10 rounded-xs"
            @click="navigateToMap(router, salesRegionId)"
          />
        </div>
        <div class="flex gap-5">
          <ButtonEl
            :text="reviewStateStyle.text"
            :custom-text-color="reviewStateStyle.color"
            :custom-bg-hover-color="reviewStateStyle.bgColor"
            :icon="reviewStateStyle.icon"
            variant="primary"
            :disabled="!userStore.uc4CanChangeSalesRegion"
            @click="showReviewStateDialog = true"
          />
        </div>
      </div>
      <CardNavigation
        :tabs="navigationCards"
        :url-depth="3"
        active-card-tag="h3"
        in-active-card-tag="h4"
        card-classes="p-1"
        @update-active-tab="activeTab = $event"
      />
      <RouterView
        v-slot="{ Component }"
        :key="route.fullPath"
        :sales-region-id
        :sales-region="salesRegion"
        @created:lead-list="fetchSalesRegion"
      >
        <Suspense>
          <component :is="Component" v-if="Component" />
          <template #fallback>
            <div class="my-auto flex w-full items-center justify-center">
              <LoadingSpinner />
            </div>
          </template>
        </Suspense>
      </RouterView>
    </div>
    <VuetifyDialog
      v-if="showReviewStateDialog"
      v-model="showReviewStateDialog"
      title="Status setzen"
      :persistent="true"
    >
      <template #content>
        <div class="flex flex-col gap-5">
          <VRadioGroup v-model="reviewState" class="-ml-1">
            <div>
              <VRadio
                v-for="radio in salesRegionMetaData['status'].choices"
                :key="radio.value"
                class="mt-1"
                :value="radio.value"
              >
                <template #label>
                  <h5 class="text-core-dark ml-1 pb-[1px]">
                    {{ radio.display_name }}
                  </h5>
                </template>
              </VRadio>
            </div>
          </VRadioGroup>

          <TextAreaEl v-model="description" label="Notiz" />
        </div>
      </template>
      <template #actions>
        <div class="flex w-full justify-between">
          <ButtonEl
            text="Abbrechen"
            color="color2"
            @click="showReviewStateDialog = false"
          />
          <ButtonEl
            text="Speichern"
            @click="patchProject({ status: reviewState, description })"
          />
        </div>
      </template>
    </VuetifyDialog>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { EvaluationTabs } from './selectable-categories';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import CardNavigation from '@/apps/navigation/CardNavigation.vue';
import { useRoute, useRouter } from 'vue-router';
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue';
import EditTitle from '@/components/EditTitle.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import TextAreaEl from '@/components/textAreaEl/TextAreaEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';
import { useSalesRegionsStore } from '@/apps/usecase-4/sales-regions/sales-regions-store';
import { colorProjectStatus, navigateToMap } from '@/apps/usecase-4/utils';
import {
  restFetch,
  restGetOrFetchMetaData,
  restPatch,
} from '@/utils/rest-utils';
import { useUsers } from '@/apps/auth/users-store';

const userStore = useUsers();

const router = useRouter();
const route = useRoute();

const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
});
const navigationCards = computed(() => {
  return EvaluationTabs.filter((e) => {
    const perm = e.requiresPerm;
    return !perm || userStore.hasPermission(perm.app, perm.permission);
  });
});
const salesRegionsStore = useSalesRegionsStore();
await salesRegionsStore.fetchSalesRegions();
const salesRegions = computed(() => salesRegionsStore.salesRegions);

async function fetchSalesRegion() {
  salesRegion.value = await restFetch(SalesRegionDto, props.salesRegionId);
}

const salesRegion = ref();
const salesRegionMetaData = await restGetOrFetchMetaData(SalesRegionDto);
await fetchSalesRegion();

const showReviewStateDialog = ref(false);
const reviewState = ref(salesRegion.value.status);
const reviewStateStyle = computed(() => {
  const reviewState = salesRegion.value.status;
  const text = salesRegionMetaData['status'].choices.find(
    (e) => e.value === reviewState,
  ).display_name;
  return colorProjectStatus(reviewState, text);
});

const description = ref(salesRegion.value.description);

function patchProject(args) {
  restPatch(SalesRegionDto, salesRegion.value.id, args).then(async () => {
    salesRegion.value = await restFetch(SalesRegionDto, props.salesRegionId);
    showReviewStateDialog.value = false;
  });
}
</script>

<style scoped>
.project-result-detail {
  min-height: calc(100vh - 290px);
}
</style>
