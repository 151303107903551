<template>
  <VuetifyDialog
    :model-value="loadDialog"
    :title
    width="960px"
    @update:model-value="emit('update:loadDialog', $event)"
  >
    <template #content>
      <div v-if="!isLoading" class="flex gap-10">
        <div v-if="selectables.length > 0" class="flex w-[50%] flex-col gap-5">
          <DropDown
            :model-value="selected"
            :items-data="selectables"
            :rules="{ required: true }"
            items-data-key="title"
            value="id"
            label="Parametrierungsvariante"
            @update:model-value="emit('update:selected', $event)"
          />
          <TextDisplay
            :value="formatDate(date)"
            label="Datum Erstellung"
            icon="calendar_month"
          />
          <TextDisplay :value="description" class="h-32" label="Beschreibung" />
        </div>
        <div v-else class="h-96">
          Keine Parametrierungen verfügbar. Legen Sie eine neue Parametrierung
          über "Parametrierung speichern" an.
        </div>
        <div v-if="selectables.length > 0" class="w-[50%]">
          <slot />
        </div>
      </div>
      <div v-else class="flex h-96 items-center justify-center">
        <VProgressCircular
          class="text-color1"
          size="44"
          width="4"
          indeterminate
        />
      </div>
    </template>
    <template #actions>
      <div class="mt-10 flex w-full justify-between">
        <ButtonEl
          color="color2"
          text="Abbrechen"
          @click="emit('update:loadDialog', false)"
        />
        <DeleteDialog
          v-if="selected && selectables.length"
          :id="selected"
          :url="baseUrl"
          class="z-10000001"
          @instance-removed="emit('deleteValues')"
        >
          <ButtonEl
            v-if="canDelete"
            text="Variante löschen"
            variant="secondary"
            color="color2"
            icon="delete_forever"
            icon-right
        /></DeleteDialog>
        <ButtonEl
          text="Werte übernehmen"
          icon="open_in_browser"
          icon-right
          :disabled="!selected || !selectables.length"
          @click="emit('applyValues', selected)"
        />
      </div>
    </template>
  </VuetifyDialog>
</template>
<script setup>
import DropDown from '../../../../components/DropDown/DropDown.vue';
import TextDisplay from '../../../../components/textDisplay/TextDisplay.vue';
import { formatDate } from '@/utils/formatUtils';
import VuetifyDialog from '../../../../components/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '../../../../components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';

const props = defineProps({
  title: {
    type: String,
  },
  selected: {
    type: Number,
  },
  selectables: {
    type: Array,
  },
  date: {
    type: String,
  },
  description: {
    type: String,
  },
  loadDialog: {
    type: Boolean,
  },
  baseUrl: {
    type: String,
  },
  isLoading: {
    type: Boolean,
  },
  canDelete: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'update:selected',
  'update:loadDialog',
  'applyValues',
  'deleteValues',
]);
</script>
