<template>
  <div
    v-if="mapActionActive && mapActionActive.buttons"
    ref="mapActionsContainer"
    class="fixed flex gap-2.5"
    :style="style"
  >
    <div
      :class="[
        'flex rounded-md',
        isDragging ? 'standard-elevation-0-dark' : 'standard-elevation-2',
      ]"
    >
      <ButtonEl
        icon="drag_indicator"
        color="color2"
        class="border-line-disabled-neutral h-11! w-6! rounded-none! rounded-l-md border-r"
      />
      <MapActionSingle
        v-for="(button, index) in filteredButtons"
        :key="button.id"
        :icon="button.icon"
        :tool-tip="!isDragging && button.toolTip"
        :active="button.drawMode === drawModeActive"
        :content-classes="
          index === filteredButtons.length - 1
            ? 'rounded-r-md rounded-l-none'
            : 'rounded-none'
        "
        @click="button.click(draw, button)"
      />
    </div>
    <slot />
  </div>
</template>

<script setup>
import { ref, toRefs, watch, nextTick, computed } from 'vue';
import { useDraggable, useElementBounding, useWindowSize } from '@vueuse/core';
import MapActionSingle from './MapActionSingle.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { draw } from '../../draw/draw';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { mapActionActive, drawModeActive } = toRefs(props.mapStore);

const mapActionsContainer = ref(null);
const { x, y, style, isDragging } = useDraggable(mapActionsContainer);
const { width, height } = useWindowSize();

const filteredButtons = computed(() =>
  mapActionActive.value.buttons.filter((b) => b.show !== false),
);

function updatePosition() {
  const { width: containerWidth } = useElementBounding(mapActionsContainer);
  x.value = (width.value - containerWidth.value) / 2;
  y.value = height.value - 160;
}

watch(
  [width, height, mapActionActive],
  async () => {
    if (!mapActionActive.value) return;
    await nextTick();
    updatePosition();
  },
  { immediate: true },
);
</script>
