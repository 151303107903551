<template>
  <Transition name="fade">
    <div
      v-show="showFooter"
      class="standard-elevation-3 sticky bottom-0 left-0 z-11 w-full space-y-5 rounded-md bg-white p-2.5"
    >
      <div class="flex items-center gap-2">
        <IconWrapper
          :icon="IconSmartMobilityPortal"
          :size="32"
          fill="text-color2"
        />
        <h5 class="text-color1">Ergebnisse Potenzialanalyse</h5>
      </div>
      <div class="flex items-center justify-between gap-5">
        <ButtonEl
          text="Anzeigen"
          icon="arrow_downward"
          @click="scrollToPotentialAnalysis"
        />
        <ButtonEl
          text="Schließen"
          color="color2"
          variant="secondary"
          icon="cancel"
          :icon-right="true"
          @click="handleCloseFooter"
        />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconSmartMobilityPortal from '@/assets/icons/custom/misc/IconSmartMobilityPortal.vue';

const props = defineProps({
  scrollContainer: {
    type: Object,
    required: true,
    default: null,
  },
});

const showFooter = ref(true);
const manuallyClosedFooter = ref(false);

function isElementInViewport(element) {
  const rect = element.getBoundingClientRect();
  const containerRect = props.scrollContainer.getBoundingClientRect();
  return rect.top >= containerRect.top && rect.bottom <= containerRect.bottom;
}

function getPotentialAnalysisHeadings() {
  const headings = props.scrollContainer.querySelectorAll(
    '.layer-panel-section h5',
  );
  return Array.from(headings).filter((heading) =>
    heading.textContent.includes('Potenzialanalyse'),
  );
}

function checkPotentialAnalysisSectionsVisibility() {
  if (!props.scrollContainer || manuallyClosedFooter.value) return;

  const potentialHeadings = getPotentialAnalysisHeadings();
  const allHeadingsVisible = potentialHeadings.every((heading) =>
    isElementInViewport(heading),
  );

  showFooter.value = !allHeadingsVisible;
}

function scrollToPotentialAnalysis() {
  const potentialHeadings = getPotentialAnalysisHeadings();
  if (potentialHeadings.length > 0) {
    const firstHeading = potentialHeadings[0];
    props.scrollContainer.scrollTo({
      top: firstHeading.offsetTop - 20,
      behavior: 'smooth',
    });
  }
}

function handleCloseFooter() {
  showFooter.value = false;
  manuallyClosedFooter.value = true;
}

defineExpose({
  checkPotentialAnalysisSectionsVisibility,
});
</script>
