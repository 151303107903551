<template>
  <div
    :class="{
      'bg-active-area': !customBackground,
      'bg-subtle': dropdownOpen,
      [customBackground]: customBackground,
    }"
    class="flex cursor-pointer items-center gap-2 rounded-r-[6px] px-1.5"
  >
    <IconWrapper
      v-if="selectedItems.length > 0 && clearable"
      icon="highlight_off"
      :size="small ? 20 : 24"
      @click.stop="emit('removeClicked')"
    />
    <IconWrapper
      icon="keyboard_arrow_down"
      :size="24"
      :class="[{ 'rotate-180': dropdownOpen }, 'duration-300']"
      type="round"
      @click="emit('arrowClicked')"
    />
  </div>
</template>

<script setup>
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  customBackground: String,
  selectedItems: Array,
  small: Boolean,
  clearable: Boolean,
  dropdownOpen: { type: Boolean, default: false },
});

const emit = defineEmits(['removeClicked', 'arrowClicked']);
</script>
