<template>
  <div
    class="top-0 z-10 flex min-h-6 w-full flex-col justify-center gap-1 bg-white"
  >
    <div class="flex items-center justify-between gap-2">
      <h5 class="text-title-neutral flex items-center gap-1">
        {{ panelName }}
        <ToolTip
          v-if="
            updatedInfo &&
            updatedInfo.map((e) => e.value).some((v) => v === true)
          "
          tooltip-icon-fill="#00A1D1"
          tooltip-icon="warn-sign-filled"
        >
          <template #text>
            <ul class="list-inside list-disc">
              <li
                v-for="update in updatedInfo?.filter((e) => e.value)"
                :key="update.index"
              >
                <span>{{ update.text }}</span>
              </li>
            </ul>
          </template>
        </ToolTip>
        <slot name="after-title" />
      </h5>
      <IconWrapper
        class="cursor-pointer"
        icon="remove"
        :size="16"
        data-test="close-info-panel"
        @click="emit('closeClick')"
      />
    </div>
  </div>
</template>

<script setup>
import ToolTip from '@/components/toolTip/ToolTip.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { useRouter } from 'vue-router';

const emit = defineEmits(['closeClick']);
const props = defineProps({
  panelName: String,
  updatedInfo: Object,
  mapStore: Object,
  panelData: Object,
});

const router = useRouter();
</script>
