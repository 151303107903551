<template>
  <div
    :class="{
      'gap-4': small,
      'gap-5': !small,
    }"
    class="radio-button-group flex w-fit"
  >
    <label
      v-for="item in itemsData"
      :key="item"
      :class="[
        {
          'flex-row-reverse': reverse,
          'h-[32px] gap-1.5': small,
          'h-10 gap-2.5': !small,
        },
        disabled ? 'pointer-events-none' : 'cursor-pointer',
      ]"
      class="flex items-center"
    >
      <input
        type="radio"
        class="h-[16px] w-[16px] cursor-pointer"
        :value="item?.[value]"
        :checked="modelValue === item?.[value]"
        :disabled="disabled"
        @change="changeSelection(item?.[value])"
      />
      <span
        :class="{
          'body-3': small,
          'body-2': !small,
        }"
        class="leading-tight"
      >
        {{ item?.[itemsDataKey || value] }}
      </span>
    </label>
  </div>
</template>

<script setup>
const props = defineProps({
  itemsData: {
    type: Array,
    required: true,
  },
  itemsDataKey: {
    type: String,
    default: null,
  },
  value: {
    type: String,
    default: 'value',
  },
  modelValue: {
    type: [String, Number, Object, Array, Boolean],
    default: null,
  },
  small: {
    type: Boolean,
    default: false,
  },
  reverse: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

function changeSelection(value) {
  emit('update:modelValue', value);
}
</script>

<style lang="scss" scoped>
.radio-button-group {
  input[type='radio'] {
    appearance: none;
    position: relative;
    border: 1px solid var(--color-infra-project-bg-focus);
    border-radius: 50%;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:checked {
      &::before {
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: var(--color-infra-highlight-700);
      }

      border: 1px solid #79909d;
    }
  }
}
</style>
