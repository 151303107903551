<template>
  <div class="flex">
    <div
      class="standard-elevation-0-dark grid w-full grid-cols-2 items-center gap-x-5 rounded-lg px-5 py-2.5"
    >
      <InputEl
        v-if="inputKeys.length === 1"
        v-model="weightingDto[inputKeys[0]]"
        :label="weightingMetaData[inputKeys[0]].label"
        :input-type="getFieldType(weightingMetaData[inputKeys[0]])"
        placeholder="Bitte eingeben"
        :suffix="weightingMetaData[inputKeys[0]].unit"
        class="w-full"
        :rules="getValidationRules(weightingFormRules[inputKeys[0]])"
        :disabled="!userStore.uc2CanChangeWeightingValue"
      />
      <div v-else class="flex gap-2.5">
        <InputEl
          v-model="weightingDto[inputKeys[0]]"
          :label="weightingMetaData[inputKeys[0]].label"
          :input-type="getFieldType(weightingMetaData[inputKeys[0]])"
          placeholder="Bitte eingeben"
          :suffix="weightingMetaData[inputKeys[0]].unit"
          class="w-1/2"
          :rules="getValidationRules(weightingFormRules[inputKeys[0]])"
          :disabled="!userStore.uc2CanChangeWeightingValue"
        />
        <InputEl
          v-model="weightingDto[inputKeys[1]]"
          :label="weightingMetaData[inputKeys[1]].label"
          :input-type="getFieldType(weightingMetaData[inputKeys[1]])"
          placeholder="Bitte eingeben"
          :suffix="weightingMetaData[inputKeys[1]].unit"
          class="w-1/2"
          :rules="getValidationRules(weightingFormRules[inputKeys[1]])"
          :disabled="!userStore.uc2CanChangeWeightingValue"
        />
      </div>
      <div class="relative flex gap-5">
        <SliderParameterization
          v-model="weightingDto[sliderKey]"
          color="#0059B9"
          class="relative top-2"
          :step="1"
          :enabled-max="6"
          :enabled-min="0"
          :disabled="!userStore.uc2CanChangeWeightingValue"
        />
        <SquareMeasureWeighting v-model="weightingDto[sliderKey]" />
      </div>
    </div>
    <div class="standard-elevation-0-dark ml-5 rounded-lg p-5">
      <SquareMeasureWeighting
        :model-value="calculateWeightingShare(weightingDto[sliderKey])"
      />
    </div>
  </div>
</template>
<script setup>
import SliderParameterization from '@/apps/features/slider/SliderParameterization.vue';
import InputEl from '@/components/input/InputEl.vue';
import SquareMeasureWeighting from '@/apps/usecase-2/settings/weighting-values/components/SquareMeasureWeighting.vue';
import { getFieldType, getValidationRules } from '@/utils/rest-utils';
import { computed } from 'vue';
import { useUsers } from '@/apps/auth/users-store';

const userStore = useUsers();

const weightingMetaData = computed(() => props.weightingData.weightingMetaData);
const weightingFormRules = computed(
  () => props.weightingData.weightingFormRules,
);
const weightingDto = computed(() => props.weightingData.weightingDto);

const props = defineProps({
  inputKeys: {
    type: Array,
  },
  sliderKey: {
    type: String,
  },
  weightingData: {
    type: Object,
  },
  sumWeighting: {
    type: Number,
  },
});

function calculateWeightingShare(input) {
  return (input / props.sumWeighting) * 5;
}
</script>
