<template>
  <div class="flex w-fit items-center gap-1.5">
    <FormValidator v-if="isEditing" v-model="nameIsValid">
      <InputEl
        v-model="localTitle"
        :style="{ minWidth: `${storedTitleWidth}px` }"
        :disabled="disableEdit"
        :rules="{ required: true }"
        :unique-check-url="dto[checkUrlKey]"
        :unique-check-values
        :unique-map-key="propName"
        :exclude-from-unique-check="localTitle"
      />
    </FormValidator>
    <h2 v-else ref="titleElement">{{ localTitle }}</h2>

    <div v-if="isEditing" class="flex items-center gap-0.5">
      <ButtonEl
        icon="check"
        class="h-10 w-10 rounded-xs"
        :color="nameIsValid ? 'color2' : 'neutral'"
        :disabled="disableEdit || !nameIsValid"
        @click="saveName"
      />
      <ButtonEl
        icon="close"
        class="h-10 w-10 rounded-xs"
        color="color2"
        @click="abortEdit"
      />
    </div>
    <ButtonEl
      v-else-if="!disabled"
      icon="edit"
      class="h-10 w-10 rounded-xs"
      color="color2"
      @click="editTitle"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import InputEl from '@/components/input/InputEl.vue';
import { restPatch } from '@/utils/rest-utils';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useElementSize } from '@vueuse/core';
import FormValidator from '@/components/FormValidator.vue';
import { useToastStore } from '@/stores/toast-store';

const { showToast } = useToastStore();

const props = defineProps({
  dto: {
    type: [Function, Object],
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  instanceId: {
    type: [String, Number],
    required: true,
  },
  uniqueCheckValues: {
    type: Array,
  },
  propName: {
    type: String,
    default: 'title',
  },
  checkUrlKey: {
    type: String,
    default: 'NAMES_URL',
  },
});

const emit = defineEmits(['title-changed']);

const titleElement = ref(null);
const { width: titleElWidth } = useElementSize(titleElement);
const localTitle = ref(props.title);
const isEditing = ref(false);
const nameIsValid = ref(true);
const disableEdit = ref(false);
const storedTitleWidth = ref(180); // Store the h2 width before it gets hidden

// Save the h2 width when switching to edit mode
function editTitle() {
  if (titleElement.value && titleElWidth.value >= 180) {
    // Store the width before hiding the h2 element
    storedTitleWidth.value = titleElWidth.value;
  }
  isEditing.value = true;
}

// Save the title if it has changed
function saveName() {
  if (localTitle.value !== props.title) {
    disableEdit.value = true;
    restPatch(props.dto, props.instanceId, {
      [props.propName]: localTitle.value,
    }).then((resp) => {
      isEditing.value = false;
      disableEdit.value = false;
      emit('title-changed', resp.data[props.propName]);
      showToast({
        color: 'success',
        message: 'Projektbezeichnung erfolgreich geändert',
      });
    });
  } else {
    isEditing.value = false;
  }
}

// Revert the title and exit edit mode
function abortEdit() {
  isEditing.value = false;
  localTitle.value = props.title;
}
</script>
