<template>
  <div class="flex flex-col gap-2.5">
    <div class="grid auto-cols-fr grid-flow-col gap-2.5">
      <div
        v-for="i in headerKpis"
        :key="i"
        class="standard-elevation-0-dark flex flex-col items-center justify-center gap-[5px] px-1 py-2"
      >
        <h6 class="text-center text-[#322A26]">{{ i.label }}</h6>
        <h6 class="text-title-neutral text-center">
          {{
            i.value.toLocaleString('de-DE', {
              maximumFractionDigits: i.decimals,
            }) +
            ' ' +
            i.unit
          }}
        </h6>
      </div>
    </div>
    <div class="standard-elevation-0-dark relative rounded-md">
      <div class="h-[60px] w-full bg-white"></div>
      <DropDown
        v-model="waterfallChartUnit"
        size="m"
        class="absolute top-5 left-2.5 z-10 w-[160px]"
        :items-data="[
          {
            label: 'Leistung in MW(el)',
            value: 'MW',
          },
          {
            label: 'Arbeit in MWh(el)/a',
            value: 'MWh',
          },
        ]"
        items-data-key="label"
        label="Einheit"
      />
      <p class="caption-1 text-color1 absolute bottom-[76px] left-5 z-9 w-20">
        Nach Gleichzeitigkeit
      </p>
      <h4 class="chart-section-title left-[14%]">Nachfrage</h4>
      <div class="vertical-chart-separator left-[63.8%]"></div>
      <h4 class="chart-section-title left-[65.7%]">EE-Erzeugung</h4>
      <div class="vertical-chart-separator right-[10.7%]"></div>
      <h4 class="chart-section-title right-[9%]">Delta</h4>
      <div class="horizontal-chart-separator"></div>
      <WaterfallChart
        :data="dataOneChart"
        :labels="labelsOneChart"
        :legend="legend"
        grid-left="81px"
        :y-axis-label-custom-fixed-width="80"
        grid-bottom="9%"
        :unit="waterfallChartUnit"
        class="min-h-[540px] shadow-none"
        :show-y-axis-line="true"
        :show-tooltip="true"
      >
        <div class="absolute right-[46.5%] bottom-[8.7%] cursor-pointer">
          <ToolTip>
            <IconWrapper icon="info" :size="20" fill="text-color1" />
            <template #text>
              Der dargestellte Wert bezieht sich auf die Entwicklung des
              konventionellen Strombedarfs im Status Quo. Dieser kann zwei
              Treiber haben: Zum einen wirkt die Stromeffizienz bei elektrischen
              Geräten stromverbrauchsmindernd, zum anderen kann über das
              Projektgebiet hinweg die demografische Entwicklung oder das
              Wirtschaftswachstum zu einer Verbrauchssteigerung führen. Die
              Säule kann somit aus dem Wirkzusammenhang beider Treiber entweder
              negativ oder positiv sein.
            </template>
          </ToolTip>
        </div>
      </WaterfallChart>
    </div>
  </div>
  <ExpansionPanel
    :show-circle-number="false"
    :panels="['Parametrierung Nachfrage', 'Parametrierung EE-Erzeugung']"
    :multiple="true"
    :eager="true"
    :first-panel-open="false"
  >
    <template #panel1>
      <div class="flex flex-col gap-5">
        <div class="grid grid-cols-2 gap-5 pl-5">
          <span class="subtitle-2">Skalierungsfaktoren</span>
          <div class="flex justify-between">
            <span class="-ml-1">0</span>
            <span class="ml-0.5">3</span>
            <span class="mr-[25px]">6</span>
          </div>
        </div>
        <div
          v-for="(slider, index) in sliders"
          :key="index"
          class="standard-elevation-0-dark grid grid-cols-2 items-center justify-between gap-6 p-5"
        >
          <div class="flex items-center justify-between">
            <span class="subtitle-2"> {{ slider.label }}</span>
            <div
              :style="{ backgroundColor: slider.color }"
              class="subtitle-3 text-neutral standard-elevation-2 flex h-10 w-10 items-center justify-center rounded-sm p-1 pt-[7px]"
            >
              {{ slider.model.toLocaleString('de-DE') }}
            </div>
          </div>
          <SliderParameterization
            v-model="slider.model"
            :color="slider.color"
            :step="0.25"
            :enabled-max="6"
            :enabled-min="0"
          />
        </div>

        <div class="mt-10 mb-2 flex justify-between">
          <AssumptionsScalingFactorsDemandLoad
            :scenario-id="props.scenarioId"
            :can-delete="userStore.uc1CanDeleteAssumptionsScaling"
            @apply-values="applyScalingValues($event)"
          />
          <AssumptionsCreate
            v-if="userStore.uc1CanAddAssumptionsScaling"
            title-save-dialog="Parametrierung zur Nachfrage speichern"
            :data="{
              electrification_mobility:
                resultStore.scalingElectrificationMobility,
              electrification_heat_single:
                resultStore.scalingElectrificationHeatSingle,
              electrification_heat_network:
                resultStore.scalingElectrificationHeatNetwork,
            }"
            :url="'/api/usecase-1/assumptions-scaling-factors-demand/'"
            :scenario-id="props.scenarioId"
            :format-data-for-emit="formatDemand"
            @apply-values="applyScalingValues($event)"
          />
        </div>
      </div>
    </template>
    <template #panel2>
      <div class="flex gap-8">
        <div
          class="flex max-w-[175px] flex-col items-center justify-end px-2.5 py-5"
        >
          <div class="text-title-neutral flex flex-col gap-2.5">
            <SwitchItem
              v-model="toggleFieldsYearly"
              label="Jährlicher Zubau in MW(el)/a"
              @update:model-value="toggleFieldsAbsolute = !toggleFieldsYearly"
            />
            <SwitchItem
              v-model="toggleFieldsAbsolute"
              :label="`Absoluter Zubau bis ${resultStore.activeProject.target_year} in MW(el)`"
              @update:model-value="toggleFieldsYearly = !toggleFieldsAbsolute"
            />
          </div>
        </div>

        <div class="grid h-[500px] auto-cols-fr grid-flow-col gap-8">
          <div
            v-for="(sliderItem, index) in Object.values(verticalSliders)"
            :key="index"
            class="flex flex-col items-center justify-between gap-5 px-2.5 py-5"
          >
            <div class="flex h-full w-full justify-between gap-2.5">
              <SliderParameterization
                :model-value="sliderItem.potentialUtilization"
                :color="sliderItem.color"
                :vertical="true"
                :enabled-min="0"
                :enabled-max="1"
                :step="0.05"
                @update:model-value="
                  sliderItem.modelAbsolute = $event * sliderItem.potential
                "
              />
              <div class="flex w-fit flex-col items-start justify-between">
                <div class="flex flex-col">
                  <span class="subtitle-2 whitespace-break-spaces">{{
                    sliderItem.sliderTopLabel
                  }}</span>
                  <h4 class="text-title-neutral">
                    <span
                      :class="
                        sliderItem.potentialUtilization <= 1
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                      >{{
                        (
                          sliderItem.sliderTopValue *
                          sliderItem.potentialUtilization
                        ).toLocaleString('de-DE', {
                          maximumFractionDigits: 0,
                        })
                      }}
                    </span>
                    <br />
                    von
                    <br />
                    {{
                      sliderItem.sliderTopValue.toLocaleString('de-DE', {
                        maximumFractionDigits: 0,
                      })
                    }}
                  </h4>
                </div>
                <div class="flex flex-col">
                  <span
                    class="subtitle-3 inline-flex items-center whitespace-break-spaces"
                  >
                    {{ 'Potenzial-\nausschöpfung' }}
                    <ToolTip
                      v-if="sliderItem.tooltip"
                      class="ml-1 cursor-pointer"
                    >
                      <IconWrapper icon="info" :size="20" fill="text-color1" />
                      <template #text>
                        {{ sliderItem.tooltip }}
                      </template>
                    </ToolTip>
                  </span>
                  <h4 class="text-title-neutral">
                    <span
                      :class="
                        sliderItem.potentialUtilization <= 1
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                      >{{
                        (sliderItem.potentialUtilization * 100).toLocaleString(
                          'de-DE',
                          { maximumFractionDigits: 1 },
                        ) + '%'
                      }}</span
                    >
                  </h4>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-2.5">
              <h4 class="text-[#322A26]">{{ sliderItem.paramsLabel }}</h4>
              <InputEl
                :model-value="sliderItem.modelAbsolute / countYears"
                :disabled="!toggleFieldsYearly"
                input-type="float"
                suffix="MW(el)/a"
                @update:model-value="
                  sliderItem.modelAbsolute = $event * countYears
                "
              />
              <InputEl
                v-model="sliderItem.modelAbsolute"
                :disabled="!toggleFieldsAbsolute"
                input-type="float"
                suffix="MW(el)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 mb-2 flex justify-between">
        <AssumptionsTotalIncreaseGenerationLoad
          :scenario-id="props.scenarioId"
          :potentials="{
            potentialPvRoof: resultStore.potentialPvRoofMinusGenerationMw,
            potentialPvFree: resultStore.potentialPvFreeMinusGenerationMw,
            potentialWind: resultStore.potentialWindMinusGenerationMw,
          }"
          :scenario-count-years="countYears"
          :can-delete="userStore.uc1CanDeleteAssumptionsIncrease"
          @apply-values="applyTotalGenerationValues($event)"
        />
        <AssumptionsCreate
          v-if="userStore.uc1CanAddAssumptionsIncrease"
          title-save-dialog="Parametrierung zur Erzeugung speichern"
          :data="{
            pv_roof_mw: resultStore.totalIncreasePvRoofMw,
            pv_free_mw: resultStore.totalIncreasePvFreeMw,
            wind_mw: resultStore.totalIncreaseWindMw,
          }"
          :potentials="{
            potentialPvRoof: resultStore.potentialPvRoofMinusGenerationMw,
            potentialPvFree: resultStore.potentialPvFreeMinusGenerationMw,
            potentialWind: resultStore.potentialWindMinusGenerationMw,
          }"
          :scenario-count-years="countYears"
          :url="'/api/usecase-1/assumptions-total-increase-generation/'"
          :scenario-id="props.scenarioId"
          :format-data-for-emit="formatGeneration"
          @apply-values="applyTotalGenerationValues($event)"
        />
      </div>
    </template>
  </ExpansionPanel>
  <SourcesItem item="power-balance" />
</template>

<script setup>
import WaterfallChart from '@/components/charts/WaterfallChart/WaterfallChart.vue';
import { useSeriesChart } from './power-balance-waterfall-data';
import { computed, ref } from 'vue';
import InputEl from '@/components/input/InputEl.vue';
import AssumptionsScalingFactorsDemandLoad from '@/apps/usecase-1/assumptions-project-result/assumptions-scaling-factors-demand/AssumptionsScalingFactorsDemandLoad.vue';
import AssumptionsCreate from '@/apps/usecase-1/assumptions-project-result/AssumptionsCreate.vue';
import AssumptionsTotalIncreaseGenerationLoad from '@/apps/usecase-1/assumptions-project-result/assumptions-total-increase-generation/AssumptionsTotalIncreaseGenerationLoad.vue';
import SliderParameterization from '@/apps/features/slider/SliderParameterization.vue';
import SwitchItem from '@/components/SwitchItem/SwitchItem.vue';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';
import { useProjectResult } from './project-result-store';
import DropDown from '@/components/DropDown/DropDown.vue';
import { POWER_DEMAND, RENEWABLE_GENERATION } from '@/configs/color-constants';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { useUsers } from '@/apps/auth/users-store';

const { seriesChart } = useSeriesChart();

const userStore = useUsers();
const resultStore = useProjectResult();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const toggleFieldsYearly = ref(false);
const toggleFieldsAbsolute = ref(true);
const waterfallChartUnit = ref('MWh');

await resultStore.fetchDemandData(props.projectId, props.scenarioId);
await resultStore.fetchPotentialGenerationData(
  props.projectId,
  props.scenarioId,
);
await resultStore.applyDefaultDemandScaling(props.scenarioId);
await resultStore.applyDefaultGenerationScaling(props.scenarioId);

// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

// power generation
const baseYear = computed(() => resultStore.activeProject.base_year);
const targetYear = computed(() => resultStore.activeProject.target_year);
const countYears = computed(() => targetYear.value - baseYear.value);

function applyScalingValues(scalingValues) {
  resultStore.setAppliedDemandScalingId(scalingValues.id);
  resultStore.setScalingElectrificationMobility(
    scalingValues.scalingElectrificationMobility,
    true,
  );
  resultStore.setScalingElectrificationHeatSingle(
    scalingValues.scalingElectrificationHeatSingle,
    true,
  );
  resultStore.setScalingElectrificationHeatNetwork(
    scalingValues.scalingElectrificationHeatNetwork,
    true,
  );
}

function applyTotalGenerationValues(scalingValues) {
  resultStore.setAppliedGenerationScalingId(scalingValues.id);
  resultStore.setTotalIncreasePvRoofMw(scalingValues.totalIncreasePvRoof, true);
  resultStore.setTotalIncreasePvFreeMw(scalingValues.totalIncreasePvFree, true);
  resultStore.setTotalIncreaseWindMw(scalingValues.totalIncreaseWind, true);
}

// Chart
const labelsOneChart = computed(() => [
  'Status Quo',
  'Elektri-\nfizierung\nMobilität',
  'Elektri-\nfizierung\nWärme\nEinzeilvers.',
  'Elektri-\nfizierung\nWärmenetz',
  resultStore.baseDemandDeltaMwh > 0
    ? 'Effizienz-\nsteigerung\nStrom\nkonv.'
    : 'Zuwachs\nStrom konv.\nDemogr./\nWirtschaft',
  `Nachfrage ${resultStore.activeProject.target_year}`,
  'Bestand',
  'PV-Aufdach,\nPV-Freifläche,\nWind',
  `Gesamt-\nerzeugung\n${resultStore.activeProject.target_year}`,
  'Gesamt',
]);

const legend = {
  data: ['PV-Aufdach', 'PV-Freifläche', 'Wind', 'Wasser', 'Biomasse'],
  bottom: 2,
  right: '13.5%',
};

const dataOneChart = computed(() => {
  if (waterfallChartUnit.value === 'MWh') {
    return seriesChart(
      resultStore.generationTotalIncreaseMwh,
      resultStore.scaledDemandTotalMwh,
      resultStore.demandStatusQuoMwh,
      resultStore.scaledDemandMobilityMwh,
      resultStore.scaledDemandHeatSingleMwh,
      resultStore.scaledDemandHeatNetworkMwh,
      resultStore.baseDemandDeltaMwh,
      resultStore.generationPvRoofMwh,
      resultStore.generationPvFreeMwh,
      resultStore.generationWindMwh,
      resultStore.generationWaterMwh,
      resultStore.generationBiomassMwh,
      resultStore.generationTotalMwh,
      resultStore.totalIncreasePvRoofMwh,
      resultStore.totalIncreasePvFreeMwh,
      resultStore.totalIncreaseWindMwh,
    );
  }
  return seriesChart(
    resultStore.generationTotalIncreaseMw,
    resultStore.scaledDemandTotalMw,
    resultStore.demandStatusQuoMw,
    resultStore.scaledDemandMobilityMw,
    resultStore.scaledDemandHeatSingleMw,
    resultStore.scaledDemandHeatNetworkMw,
    resultStore.baseDemandDeltaMw,
    resultStore.generationPvRoofMw,
    resultStore.generationPvFreeMw,
    resultStore.generationWindMw,
    resultStore.generationWaterMw,
    resultStore.generationBiomassMw,
    resultStore.generationTotalMw,
    resultStore.totalIncreasePvRoofMw,
    resultStore.totalIncreasePvFreeMw,
    resultStore.totalIncreaseWindMw,
  );
});

// Tab 1: horizontal slider config
const sliders = ref([
  {
    label: 'Elektrifizierung Mobilität',
    model: computed({
      get() {
        return resultStore.scalingElectrificationMobility;
      },
      set(value) {
        resultStore.setScalingElectrificationMobility(value);
      },
    }),
    color: POWER_DEMAND.ELECTRIFICATION_MOBILITY,
  },
  {
    label: 'Elektrifizierung Wärme Einzelversorgung',
    model: computed({
      get() {
        return resultStore.scalingElectrificationHeatSingle;
      },
      set(value) {
        resultStore.setScalingElectrificationHeatSingle(value);
      },
    }),
    color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE,
  },
  {
    label: 'Elektrifizierung Wärmenetze',
    model: computed({
      get() {
        return resultStore.scalingElectrificationHeatNetwork;
      },
      set(value) {
        resultStore.setScalingElectrificationHeatNetwork(value);
      },
    }),
    color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK,
  },
]);

// Tab 2: vertical slider config
const verticalSliders = ref({
  pvRoof: {
    // 8 kw = EFH
    sliderTopLabel: `Anzahl\nEFH-Äquivalent`,
    sliderTopValue: Math.round(
      resultStore.potentialPvRoofMinusGenerationMw / 0.008,
    ),
    potentialUtilization: computed(() => {
      if (resultStore.potentialPvRoofMinusGenerationMw === 0) return 0;
      return (
        resultStore.totalIncreasePvRoofMw /
        resultStore.potentialPvRoofMinusGenerationMw
      );
    }),
    potential: resultStore.potentialPvRoofMinusGenerationMw,
    paramsLabel: 'PV-Aufdach',
    modelSlider: null,
    modelAbsolute: computed({
      get() {
        return resultStore.totalIncreasePvRoofMw;
      },
      set(value) {
        resultStore.setTotalIncreasePvRoofMw(value);
      },
    }),
    color: RENEWABLE_GENERATION.PV_ROOF,
  },

  pvFree: {
    // 1,2 ha sind 1 MW
    sliderTopLabel: 'Fläche\nin ha',
    sliderTopValue: resultStore.potentialPvFreeMinusGenerationMw * 1.2,
    potentialUtilization: computed(() => {
      if (resultStore.potentialPvFreeMinusGenerationMw === 0) return 0;
      return (
        resultStore.totalIncreasePvFreeMw /
        resultStore.potentialPvFreeMinusGenerationMw
      );
    }),
    potential: resultStore.potentialPvFreeMinusGenerationMw,
    paramsLabel: 'PV-Freifläche',
    tooltip: 'Potenziale nach Abzug des Bestands',
    modelSlider: null,
    modelAbsolute: computed({
      get() {
        return resultStore.totalIncreasePvFreeMw;
      },
      set(value) {
        resultStore.setTotalIncreasePvFreeMw(value);
      },
    }),
    color: RENEWABLE_GENERATION.PV_FREE,
  },
  wind: {
    sliderTopLabel: 'Anzahl\nAnlagen',
    // 6 MW eine Anlage
    sliderTopValue: Math.round(resultStore.potentialWindMinusGenerationMw / 6),
    potentialUtilization: computed(() => {
      if (resultStore.potentialWindMinusGenerationMw === 0) return 0;
      return (
        resultStore.totalIncreaseWindMw /
        resultStore.potentialWindMinusGenerationMw
      );
    }),
    potential: resultStore.potentialWindMinusGenerationMw,
    paramsLabel: 'Wind',
    modelSlider: null,
    modelAbsolute: computed({
      get() {
        return resultStore.totalIncreaseWindMw;
      },
      set(value) {
        resultStore.setTotalIncreaseWindMw(value);
      },
    }),
    color: RENEWABLE_GENERATION.WIND,
  },
});

const headerKpis = computed(() => {
  return [
    {
      label: 'Stromnachfrage',
      value: resultStore.scaledDemandTotalMwh,
      unit: 'MWh(el)/a',
      decimals: 0,
    },
    {
      label: 'EE-Erzeugung',
      value: resultStore.generationTotalIncreaseMwh,
      unit: 'MWh(el)/a',
      decimals: 0,
    },
    {
      label: 'Investitionsbedarf',
      value: resultStore.investRenewableMioEur,
      unit: 'Mio. €',
      decimals: 2,
    },
    {
      label: 'Bilanzieller Autarkiegrad',
      value:
        resultStore.generationTotalIncreaseMwh >
        resultStore.scaledDemandTotalMwh
          ? 100
          : (resultStore.generationTotalIncreaseMwh /
              resultStore.scaledDemandTotalMwh) *
            100,
      unit: '%',
      decimals: 0,
    },
    {
      label: 'EEG-Gemeindeabgabe',
      value: resultStore.municipalityLevyMioEur,
      unit: 'Mio. €/a',
      decimals: 2,
    },
    {
      label: 'Wasserstoffpotenzial',
      value: resultStore.potentialHydrogenMwh,
      unit: 'MWh(th)/a',
      decimals: 0,
    },
    {
      label: 'Notwendige Elektrolyseurkapazität',
      value: resultStore.potentialHydrogenMw,
      unit: 'MW(el)',
      decimals: 2,
    },
  ];
});

//------------ scaling helpers
function formatGeneration(values) {
  return {
    totalIncreasePvRoof: values.pv_roof_mw,
    totalIncreasePvFree: values.pv_free_mw,
    totalIncreaseWind: values.wind_mw,
  };
}

function formatDemand(values) {
  return {
    scalingElectrificationMobility: values.electrification_mobility,
    scalingElectrificationHeatSingle: values.electrification_heat_single,
    scalingElectrificationHeatNetwork: values.electrification_heat_network,
  };
}
</script>
<style scoped lang="scss">
.separator-default-styles {
  background-color: white;
  position: absolute;
  z-index: 2;
}

.vertical-chart-separator {
  width: 10px;
  height: 564px;
  top: 0;
  @extend .separator-default-styles;

  box-shadow:
    inset 8px 0 8px -8px rgba(126, 117, 112, 0.2),
    inset -8px 0 8px -8px rgba(126, 117, 112, 0.2);
}

.horizontal-chart-separator {
  height: 8px;
  right: 0;
  left: 0;
  bottom: 28px;
  @extend .separator-default-styles;

  box-shadow:
    inset 0 8px 8px -8px rgba(126, 117, 112, 0.2),
    inset 0 -8px 8px -8px rgba(126, 117, 112, 0.2);
}

.chart-section-title {
  color: var(--color-neutral);
  width: 8px;
  position: absolute;
  top: 20px;
  z-index: 10;
  white-space: nowrap;
}
</style>
