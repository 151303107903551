<template>
  <TopHeaderBar />
  <div class="flex flex-col gap-5">
    <div class="flex items-center gap-4">
      <IconWrapper
        v-if="IconAnalysis"
        :size="64"
        :icon="IconAnalysis"
        fill="text-color2"
      />
      <h1 class="text-title-neutral">Einstellungen</h1>
    </div>
    <div
      class="standard-elevation-0-dark flex flex-col gap-5 rounded-md bg-white p-5"
    >
      <FormTabs
        :tabs
        custom-window-classes="min-h-[500px]"
        :disable-transitions="true"
        :prevent-window-scroll="true"
      >
        <template #slot0>
          <InvestCosts />
        </template>
        <template #slot1>
          <WeightingValues />
        </template>
      </FormTabs>
    </div>
  </div>
</template>

<script setup>
import InvestCosts from '@/apps/usecase-2/settings/invest-costs/InvestCosts.vue';
import WeightingValues from '@/apps/usecase-2/settings/weighting-values/WeightingValues.vue';
import IconAnalysis from '@/assets/icons/custom/misc/IconAnalysis.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';

const tabs = [
  {
    title: 'Investitionskosten',
    icon: 'euro_symbol',
  },
  {
    title: 'Werthaltigkeitsindikator',
    icon: 'switch_access_shortcut',
  },
];
</script>
