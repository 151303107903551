<template>
  <div
    v-show="mapStore.showSearch && mapStore.searchResults?.length"
    class="standard-elevation-0-dark absolute top-[60px] z-30 box-border rounded-md bg-white px-2 py-2"
  >
    <div
      v-for="results in mapStore.searchResults"
      :key="results.id"
      class="cursor-pointer border-b py-2 transition-colors duration-200 ease-in-out last:border-0 hover:bg-gray-100"
      @click="
        flyToCenter(results.center[0], results.center[1]);
        mapStore.resetSearch();
      "
    >
      <div class="px-2">
        <h3 class="text-sm font-medium">{{ results.text }}</h3>
        <p class="text-xs" style="margin: 0">{{ results.place_name }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { flyTo, setMarker } from '@/mapbox/main';

const props = defineProps({ map: Object, mapStore: Object });
const emit = defineEmits(['coordinates']);

function flyToCenter(lng, lat) {
  const config = {
    zoom: 16.9,
    lng,
    lat,
    maxZoom: 16.9,
  };
  flyTo(config, props.map ? props.map : undefined);
  setMarker([lng, lat], props.map ? props.map : undefined);
}
</script>
