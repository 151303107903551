<template>
  <transition name="fade" mode="out-in">
    <div v-if="selectedUseCase" class="absolute left-1/2 z-10 -translate-x-1/2">
      <div ref="useCaseSelectorContainer" class="relative">
        <div
          class="flex items-center p-2"
          :class="{ 'cursor-pointer': !hasOnlyOneUseCase }"
          @click="openContainer"
        >
          <h6 class="text-neutral whitespace-nowrap">
            {{ selectedUseCase.title }}
          </h6>
          <IconWrapper
            v-if="!hasOnlyOneUseCase"
            icon="keyboard_arrow_down"
            class="mt-0.5"
          />
        </div>

        <transition name="fade-in" mode="out-in">
          <div
            v-if="isOpen"
            class="standard-elevation-2 absolute top-0 left-1/2 flex -translate-x-1/2 flex-col overflow-hidden rounded-md"
          >
            <RouterLink
              v-for="selector in filteredSelectorData"
              :key="selector.id"
              :to="{ name: selector.routeName }"
              :class="{
                'bg-disabled-color1 hover:bg-disabled-color1 pointer-events-none':
                  selector.routeName === $route.name,
              }"
              class="text-color1 bg-active-area flex cursor-pointer items-center justify-between gap-2 p-2 whitespace-nowrap hover:bg-white"
              @click="isOpen = false"
            >
              <h6>{{ selector.title }}</h6>
              <IconWrapper :icon="selector.icon" />
            </RouterLink>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed, ref, watch } from 'vue';
import { rawCardData } from '@/apps/landing-page/raw-card-data';
import { useUsers } from '@/apps/auth/users-store';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
  currentRouteName: {
    type: [String, Object],
    required: true,
  },
});

const userStore = useUsers();

const selectorData = rawCardData;
const selectedUseCase = ref(null);

const useCaseSelectorContainer = ref(null);
const isOpen = ref(false);

onClickOutside(useCaseSelectorContainer, () => {
  if (isOpen.value) {
    isOpen.value = false;
  }
});

const orderedSelectorData = computed(() => {
  if (!selectedUseCase.value) return selectorData;

  const selectedUseCaseIndex = selectorData.findIndex(
    (item) => item.routeName === selectedUseCase.value.routeName,
  );

  const orderedData = [...selectorData];
  orderedData.splice(selectedUseCaseIndex, 1);
  orderedData.unshift(selectedUseCase.value);

  return orderedData;
});

const filteredSelectorData = computed(() => {
  return orderedSelectorData.value.filter((selector) =>
    userStore.hasPermission(
      selector.permission.app,
      selector.permission.permission,
    ),
  );
});

const hasOnlyOneUseCase = computed(() => {
  return filteredSelectorData.value.length === 1;
});

function openContainer() {
  if (hasOnlyOneUseCase.value) return;
  isOpen.value = !isOpen.value;
}

watch(
  () => props.currentRouteName,
  () => {
    selectedUseCase.value =
      selectorData.find((item) => item.routeName === props.currentRouteName) ||
      null;
    isOpen.value = false;
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.fade-in-enter-active,
.fade-in-enter-from,
.fade-in-leave-to {
  transition: opacity 0.3s;
  opacity: 0;
}

.fade-in-enter-to {
  opacity: 1;
}

.fade-in-leave-active {
  opacity: 0;
}
</style>
