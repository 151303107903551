<template>
  <div ref="activator" class="relative">
    <div
      class="agg-levels-toggle"
      :class="{ open: open }"
      @click.stop="open = true"
    >
      <IconWrapper
        :size="16"
        :icon="'border_style'"
        fill="text-title-neutral"
      />
      <h6 class="text-title-neutral">
        {{ postalCodeIsVisible ? 'Postleitzahl' : 'Keine' }}
      </h6>
      <IconWrapper :size="16" icon="more_horiz" fill="text-neutral" />
    </div>

    <!--  agg levels-->
    <Teleport to="body">
      <transition name="fade">
        <div
          v-if="open"
          ref="aggContainer"
          :style="{ top: `${top}px`, left: `${calcXPos}px` }"
          class="standard-elevation-0-dark absolute z-100 rounded-sm bg-white"
        >
          <h6 class="text-title-neutral px-2.5 py-2 select-none">
            Aggregationsebenen
          </h6>

          <div
            class="caption-1 hover:bg-active-area text-neutral border-l-line-disabled-neutral flex w-full cursor-pointer items-center justify-between border-b py-1 pr-1.5 pl-2.5"
          >
            <VRadioGroup v-model="postalCodeIsVisible" class="max-w-fit">
              <VRadio :value="false" label="Keine" />
              <VRadio :value="true" label="Postleitzahl" />
            </VRadioGroup>
          </div>

          <div class="flex flex-col gap-2.5 p-2.5 select-none">
            <ToolTip
              class="w-full cursor-pointer justify-between"
              custom-content-class="p-1.5"
              @click="
                mapStore.changeLayerState({
                  layerKeys: [LAYER_KEY__POSTAL_CODE],
                  layerState: 'default',
                })
              "
            >
              <h6 class="text-title-neutral">Darstellung</h6>
              <IconWrapper :size="16" icon="border_style" fill="text-color1" />
              <template #text>
                <div class="subtitle-4 text-neutral">
                  {{
                    'Klicken, um Karte auf die\neinfache Anzeige der\n' +
                    'Gebietsgrenzen zu setzen.'
                  }}
                </div>
              </template>
            </ToolTip>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { onClickOutside, onKeyStroke, useElementBounding } from '@vueuse/core';
import { useMapStore } from '@/apps/usecase-4/map/map-store';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { LAYER_KEY__POSTAL_CODE } from '@/apps/usecase-4/map/layer-config/postal-code';

const mapStore = useMapStore();
const postalCodeIsVisible = computed({
  get() {
    const layersVisible = new Set(mapStore.getLayersVisible);
    return layersVisible.has(LAYER_KEY__POSTAL_CODE);
  },
  set(isVisible) {
    mapStore.changeLayerVisibility({
      onLayers: [LAYER_KEY__POSTAL_CODE],
      active: isVisible,
    });
  },
});

// layout logic
const open = ref(false);
const activator = ref(null);
const aggContainer = ref(null);

const { width: aggContainerWidth } = useElementBounding(aggContainer);
const { top, right } = useElementBounding(activator);
const calcXPos = computed(() => {
  return aggContainerWidth.value && right.value - aggContainerWidth.value;
});

// utils
onClickOutside(aggContainer, (e) => {
  e.stopPropagation();
  open.value = false;
});

onKeyStroke('Escape', () => {
  open.value = false;
});
</script>

<style lang="scss" scoped>
@use 'partials/design-tokens';

.agg-levels-toggle {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--color-line-disabled-neutral);
  border-radius: var(--radius-xs);
  padding: 6px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &.open {
    border-color: var(--color-white);
  }

  &:hover {
    background-color: var(--color-active-area);
    @extend .standard-elevation-0-dark;
  }
}

.selected-agg-level {
  display: flex;
  align-items: center;
  border-radius: var(--radius-xxs);
  padding: 4px 2px;
  gap: 2px;
  color: white;
  background-color: var(--color-color1);
  transition: all 0.15s ease-in-out;
  user-select: none;
  &.inactive {
    background-color: var(--color-button-primary-disabled-color1);
  }
}
</style>
