<template>
  <div>
    <ButtonEl
      variant="secondary"
      color="color2"
      :text="titleSaveButton"
      icon="save"
      icon-right
      icon-type="round"
      @click="saveDialog = true"
    />
    <VuetifyDialog v-model="saveDialog" :title="titleSaveDialog" width="960px">
      <template #content>
        <div class="flex gap-10">
          <FormValidator
            v-model="formIsValid"
            class="flex w-[50%] flex-col gap-5"
          >
            <InputEl
              id="parameter-variant"
              v-model="title"
              :rules="{ required: true }"
              label="Name Parametrierungsvariante"
              placeholder="Bitte eingeben"
            />
            <CheckboxEl v-model="isDefault">
              <span class="body-1 mt-1">Als Standardset übernehmen</span>
            </CheckboxEl>
            <TextAreaEl
              v-model="description"
              label="Beschreibung"
              placeholder="Bitte eingeben"
            />
          </FormValidator>
          <div class="flex w-[50%] flex-col gap-5">
            <template v-if="potentials">
              <AssumptionsTotalIncreaseRow
                v-for="item in dataForDisplay"
                :key="item.title"
                :items="item.items"
                :title="item.title"
              />
            </template>
            <template v-else>
              <AssumptionsScalingFactorsRow
                v-for="item in dataForDisplay"
                :key="item.title"
                :item="item.item"
                :title="item.title"
              />
            </template>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="mt-10 flex w-full justify-between">
          <ButtonEl
            color="color2"
            text="Abbrechen"
            @click="saveDialog = false"
          />
          <ButtonEl
            text="Speichern und schließen"
            icon="save"
            icon-right
            icon-type="round"
            :disabled="!formIsValid"
            @click="createInstance()"
          />
        </div>
      </template>
    </VuetifyDialog>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import InputEl from '@/components/input/InputEl.vue';
import axios from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import AssumptionsScalingFactorsRow from './assumptions-scaling-factors-demand/AssumptionsScalingFactorsRow.vue';
import AssumptionsTotalIncreaseRow from './assumptions-total-increase-generation/AssumptionsTotalIncreaseRow.vue';
import {
  getScalingFactorAssumptionsForDisplay,
  getTotalIncreaseAssumptionsForDisplay,
} from './helpers/modal';
import TextAreaEl from '../../../components/textAreaEl/TextAreaEl.vue';
import FormValidator from '../../../components/FormValidator.vue';

import { useToastStore } from '@/stores/toast-store';

const { showToast } = useToastStore();

const props = defineProps({
  titleSaveButton: {
    type: String,
    default: 'Parametrierung Speichern',
  },
  titleSaveDialog: {
    type: String,
  },
  data: {
    type: Object,
    required: true,
  },
  potentials: {
    type: Object,
  },
  scenarioId: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  scenarioCountYears: {
    type: Number,
  },
  formatDataForEmit: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['apply-values']);

const title = ref('');
const description = ref('');
const isDefault = ref(false);
const saveDialog = ref(false);
const formIsValid = ref(false);

const dataForDisplay = computed(() => {
  if (props.potentials) {
    return getTotalIncreaseAssumptionsForDisplay(
      props.data,
      props.potentials,
      props.scenarioCountYears,
    );
  }
  return getScalingFactorAssumptionsForDisplay(props.data);
});

function createInstance() {
  axios({
    method: 'POST',
    url: props.url,
    data: {
      title: title.value,
      scenario: props.scenarioId,
      ...props.data,
      description: description.value,
      is_default: isDefault.value,
    },
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then((r) => {
    showToast({
      color: 'success',
      message: 'Erfolgreich erstellt',
    });
    resetForm();
    emit('apply-values', {
      ...props.formatDataForEmit(props.data),
      id: r.data.id,
    });
  });
}

function resetForm() {
  saveDialog.value = false;
  // Implement better solution without timeout? Problem is short error display for form fields
  setTimeout(() => {
    title.value = '';
    description.value = '';
    isDefault.value = false;
  }, 500);
}
</script>
