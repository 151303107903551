<template>
  <VDialog
    v-model="mapActionsDialog"
    width="auto"
    :no-click-animation="true"
    scrim="#263238"
    scrim-opacity="0.2"
    transition="fade-transition"
  >
    <template #activator="{ props: launcherProps }">
      <div
        v-if="Object.keys(mapStore.mapActions).length"
        v-bind="launcherProps"
        class="standard-elevation-2 rounded-md"
      >
        <ButtonEl
          data-test="grid-icon"
          icon="apps"
          variant="primary"
          color="color2"
          class="h-10 w-10 rounded-md"
        />
      </div>
    </template>
    <div
      class="standard-elevation-2 grid auto-cols-fr grid-flow-col gap-0.5 overflow-hidden rounded-md"
    >
      <template
        v-for="[key, item] in Object.entries(mapStore.mapActions)"
        :key="item"
      >
        <div
          v-if="
            !item.hasPerm ||
            (item.hasPerm &&
              userStore.hasPermission(item.hasPerm.app, item.hasPerm.perm))
          "
          :class="getClass(item)"
          class="flex h-36 w-36 flex-col items-center justify-start gap-3 p-4 pt-7"
          @click="setAction(key, item)"
        >
          <IconWrapper
            :icon="item.icon"
            :size="44"
            :type="item.icon === 'account_tree' ? 'rounded' : 'round'"
            fill="text-color1"
            class="pointer-events-none"
          />
          <h6 class="text-neutral text-center whitespace-pre-wrap">
            {{ item.title }}
          </h6>
        </div>
      </template>
    </div>
  </VDialog>
</template>

<script setup>
import { ref } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { draw } from '@/apps/features/map/draw/draw';
import { useUsers } from '@/apps/auth/users-store';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const userStore = useUsers();

const mapActionsDialog = ref(false);

function setAction(id, actionObject) {
  props.mapStore.setMapAction(id, draw);
  mapActionsDialog.value = false;
  if (typeof actionObject.onActivate === 'function') {
    actionObject.onActivate();
  }
}

function getClass(item) {
  return item.disabled
    ? 'pointer-events-none bg-white'
    : (item.active ? 'bg-blue-grey-50' : 'bg-white hover:bg-blue-grey-50') +
        ' cursor-pointer';
}
</script>
