<template>
  <div
    :class="[
      'flex w-full flex-col items-center justify-center',
      customWrapperPadding,
    ]"
  >
    <Component
      :is="customTitleTag"
      v-if="title"
      class="text-center whitespace-pre-line text-[#7E7570]"
      :style="titleCustomSize ? { 'font-size': titleCustomSize + 'px' } : {}"
      :class="customTitleClasses"
    >
      {{ title }}
    </Component>
    <VChart
      ref="chartRef"
      class="h-full w-full"
      :class="customPadding"
      :option="option"
      autoresize
    />
  </div>
</template>

<script setup>
import { sum } from 'lodash';
import { computed, ref } from 'vue';
import { formatChartTooltip } from '@/utils/chart-utils';
import { toLocaleStr } from '@/utils/formatUtils';
import { useElementSize } from '@vueuse/core';

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
    deep: true,
  },
  titleInside: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  chartUnit: {
    type: String,
    default: '',
  },
  showChartUnitInsideTitle: {
    type: Boolean,
    default: true,
  },
  chartTop: {
    type: String,
    default: '',
  },
  titleInsideLH: {
    type: Number,
    default: 0,
  },
  titleCustomSize: {
    type: Number,
    default: null,
  },
  multiply: {
    type: Number,
    default: 1,
  },
  customTitleClasses: {
    type: String,
    default: '-mb-5',
  },
  customPadding: {
    type: String,
    default: '',
  },
  customMinHeight: {
    type: String,
    default: '250px',
  },
  customRadius: {
    type: Array,
    default: () => ['35%', '45%'],
  },
  showLegend: {
    type: Boolean,
    default: true,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  showInnerTitle: {
    type: Boolean,
    default: true,
  },
  showRawInputInnerTitle: {
    type: Boolean,
    default: false,
  },
  legendSelectedMode: {
    type: Boolean,
    default: false,
  },
  seriesPosition: {
    type: Array,
    default: () => ['50%', '50%'],
  },
  legendTop: {
    type: String,
    default: 'middle',
  },
  legendBottom: {
    type: String,
    default: null,
  },
  legendRight: {
    type: String,
    default: '10px',
  },
  legendOrient: {
    type: String,
    default: 'vertical',
  },
  gridBottom: {
    type: String,
    default: '5%',
  },
  gridTop: {
    type: String,
    default: '5%',
  },
  gridLeft: {
    type: String,
    default: '5%',
  },
  gridRight: {
    type: String,
    default: '5%',
  },
  customWrapperPadding: {
    type: String,
    default: 'py-5',
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  scaleEmphasis: {
    type: Boolean,
    default: true,
  },
  legendData: {
    type: Array,
    default: () => [],
  },
  legendType: {
    type: String,
    default: 'plain',
  },
  legendBorderRadius: {
    type: Number,
    default: null,
  },
  legendBgColor: {
    type: String,
    default: null,
  },
  legendPadding: {
    type: Number,
    default: 0,
  },
  customTitleTag: {
    type: String,
    default: 'h4',
  },
  innerTextMinDigits: {
    type: Number,
    default: 0,
  },
  innerTextMaxDigits: {
    type: Number,
    default: 0,
  },
  breakUnit: {
    type: Boolean,
    default: false,
  },
  legendLineBreak: {
    type: Boolean,
    default: true,
  },
  customChartTitleTop: {
    type: String,
    default: '',
  },
  hideSum: {
    type: Boolean,
    default: false,
  },
});

const chartRef = ref(null);
const { width: containerWidth, height: containerHeight } =
  useElementSize(chartRef);

const option = computed(() => {
  const filteredChartData = props.chartData.filter((item) => {
    return item.value !== 0 || item.value !== 0.0;
  });

  const sumValues = sum(
    filteredChartData.map((item) => props.multiply * item.value),
  );

  const innerTextUnit = props.showChartUnitInsideTitle ? props.chartUnit : '';

  const innerText =
    `{a|${
      props.hideSum
        ? ''
        : toLocaleStr(
            sumValues,
            props.innerTextMinDigits,
            props.innerTextMaxDigits,
          ) +
          (innerTextUnit
            ? `${props.breakUnit ? '\n' : ''}${innerTextUnit}`
            : '')
    }}` + (props.titleInside ? `\n{b|${props.titleInside}}` : '');

  // Integrate color codes from chartData
  const dataWithColors = filteredChartData.map((item) => ({
    ...item,
    itemStyle: {
      color: item.color,
      borderWidth: 2, // Set the border width to create a gap
      borderColor: '#FFF',
    },
  }));

  const titleText = props.showRawInputInnerTitle
    ? props.titleInside
    : innerText;
  const estimatedCharWidth = 0.5; // Approximate width value (not as pixels) per character

  const titleLeftPosition =
    props.seriesPosition[0] === '50%'
      ? 'center'
      : (containerWidth.value *
          parseFloat(props.seriesPosition[0].replace('%', ''))) /
          100 -
        (titleText.length * estimatedCharWidth) / 2;

  const titleTopPosition =
    props.seriesPosition[1] === '50%'
      ? 'middle'
      : (containerHeight.value *
          parseFloat(props.seriesPosition[1].replace('%', ''))) /
          100 -
        24;

  return {
    title: {
      show: props.showInnerTitle,
      text: props.showRawInputInnerTitle ? props.titleInside : innerText,
      left: titleLeftPosition,
      top: titleTopPosition,
      textAlign: props.seriesPosition[0] === '50%' ? null : 'center',
      textStyle: {
        fontSize: 16,
        color: '#7E7570',
        lineHeight: 20,
        fontFamily: 'mark',
        fontWeight: 500,
        rich: {
          a: {
            fontFamily: 'mark',
            color: 'black',
            fontSize: 13,
            lineHeight: 20,
            fontWeight: 500,
          },
          b: {
            fontSize: 12,
            color: 'black',
            lineHeight: 20,
            fontFamily: 'enbw',
            fontWeight: 500,
          },
        },
      },
    },
    tooltip: {
      trigger: 'item',
      show: props.showTooltip,
      formatter: (params) => {
        return `
              <div style='display: flex;align-items: center;'>
                <span style='display:inline-block; border-radius: 0; width:12px; height:12px; background-color:${params.color}; margin-right:5px;'></span>
                <span style='padding-bottom: 1px'>${params.name}: ${formatChartTooltip(
                  params.value,
                  props.chartUnit,
                )}</span>
              </div>`;
      },

      backgroundColor: 'rgba(255, 255, 255)',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
      padding: 8,
      appendToBody: true,
      textStyle: {
        fontSize: 12,
        color: 'black',
        fontWeight: 500,
        fontFamily: 'mark',
        align: 'center',
        style: 'normal',
      },
    },

    legend: {
      ...(props.legendData.length && { data: props.legendData }),
      show: props.showLegend,
      orient: props.legendOrient,
      type: props.legendType,
      itemWidth: 12,
      itemHeight: 12,
      right: props.legendRight,
      top: props.legendBottom ? null : props.legendTop,
      bottom: props.legendTop ? null : props.legendBottom,
      selectedMode: props.legendSelectedMode,
      textStyle: {
        fontFamily: 'enbw',
        fontWeight: 600,
        fontSize: 12,
        color: '#322A26',
      },
      backgroundColor: props.legendBgColor,
      padding: props.legendPadding,
      borderRadius: props.legendBorderRadius,
      icon: 'rect',
      itemStyle: {
        borderWidth: 0,
      },
      formatter: (value) => {
        return props.legendLineBreak ? value : value.split('\n').join(' ');
      },
    },
    grid: {
      top: props.gridTop,
      left: props.gridLeft,
      right: props.gridRight,
      bottom: props.gridBottom,
      containLabel: props.showLabel,
    },
    series: [
      {
        type: 'pie',
        radius: props.customRadius,
        center: props.seriesPosition,
        top: props.chartTop,
        data: dataWithColors,
        label: {
          show: props.showLabel,
          formatter: (value) => {
            const percentage = (value.value / sumValues) * 100;
            const decimalPlaces = percentage === 0 || percentage >= 10 ? 0 : 1;
            return `{a|${value.name}}\n{b|${percentage.toLocaleString('de-DE', {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            })}%}`;
          },
          rich: {
            a: {
              fontSize: 12,
              lineHeight: 20,
              color: 'black',
              fontWeight: 500,
              fontFamily: 'mark',
              align: 'center',
              style: 'normal',
            },
            b: {
              fontSize: 12,
              color: 'black',
              fontFamily: 'enbw',
              align: 'center',
              fontWeight: 500,
            },
          },
        },
        silent: !props.scaleEmphasis,
        emphasis: {
          scale: props.scaleEmphasis,
          scaleSize: 3,
        },
        labelLine: {
          show: true,
        },
        cursor: props.scaleEmphasis ? 'pointer' : 'default',
      },
    ],
  };
});
</script>
