<template>
  <div
    class="absolute bottom-5 left-5 z-10 flex h-10"
    :style="showSlider ? 'width: calc(100vw - 136px)' : ''"
  >
    <ButtonEl
      icon="update"
      :color="showSlider ? 'color1' : 'color2'"
      :class="[
        {
          'rounded-r-none': showSlider,
        },
        'standard-elevation-2 h-10 rounded-md',
      ]"
      @click="mapStore.showYearSlider(!showSlider)"
    />
    <div
      class="standard-elevation-2 flex rounded-r-[6px]"
      :class="
        showSlider
          ? 'extended-controls visible'
          : 'extended-controls-no-transition'
      "
    >
      <YearSlider class="rounded-l-none" :years="years" :map-store />
    </div>
  </div>
</template>
<script setup>
import YearSlider from '@/components/year-slider/YearSlider.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { storeToRefs } from 'pinia';

const props = defineProps({
  years: Array,
  mapStore: {
    type: Object,
    required: true,
  },
});

const { showSlider } = storeToRefs(props.mapStore);
</script>

<style scoped lang="scss">
.extended-controls {
  overflow: hidden;
  max-width: 0;
  transition: max-width 0.25s ease;
}

.extended-controls-no-transition {
  overflow: hidden;
  max-width: 0;
}

.visible {
  width: 100%;
  max-width: calc(100vw - 175px);
}
</style>
