<template>
  <div>
    <div ref="refActivator">
      <slot name="activator" />
    </div>
    <Transition name="fadeTooltip" mode="out-in">
      <Teleport to="body">
        <div
          v-if="modelValue"
          ref="refTooltip"
          :style="positionStyles"
          :class="{
            'moving-popup': baseStyle,
            'z-1000': true,
            [customTooltipClasses]: true,
          }"
        >
          <slot name="content" />
        </div>
      </Teleport>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: String,
      default: null,
      validator: (value) => ['x', 'y', 'all', null].includes(value),
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    baseStyle: {
      type: Boolean,
      default: false,
    },
    activatorBounds: {
      type: Object,
      default: null,
    },
    customTooltipClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['position'],
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      tooltipWidth: 0,
      tooltipHeight: 0,
      computedActivatorBounds: null,
    };
  },
  computed: {
    positionStyles() {
      const baseStyle = {
        position: 'absolute',
        pointerEvents: 'none',
      };

      if (this.computedActivatorBounds) {
        const screenWidth = window.innerWidth;
        // works only for width (x-axis)
        const doesTooltipFitsScreen =
          screenWidth -
            (this.fixed === 'all'
              ? this.computedActivatorBounds.left
              : this.mouseX) >
          this.tooltipWidth / 2;

        switch (this.fixed) {
          case 'x':
            baseStyle.left = `${
              this.computedActivatorBounds.left -
              this.tooltipWidth -
              this.offsetX
            }px`;
            baseStyle.top = `${
              this.mouseY - this.tooltipHeight / 2 - this.offsetY
            }px`;
            break;
          case 'y':
            baseStyle.left = `${
              this.mouseX -
              this.offsetX -
              (doesTooltipFitsScreen
                ? this.tooltipWidth / 2
                : this.tooltipWidth)
            }px`;
            baseStyle.top = `${
              this.computedActivatorBounds.top -
              this.tooltipHeight -
              this.offsetY
            }px`; // Vertically above the activator
            break;
          case 'all':
            baseStyle.left = `${
              this.computedActivatorBounds.left -
              this.tooltipWidth / 2 -
              this.offsetX -
              (doesTooltipFitsScreen
                ? 0
                : screenWidth - this.computedActivatorBounds.left)
            }px`;
            baseStyle.top = `${
              this.computedActivatorBounds.top -
              this.tooltipHeight -
              this.offsetY
            }px`;
            break;
          default:
            baseStyle.left = `${
              this.mouseX - this.tooltipWidth / 2 - this.offsetX
            }px`;
            baseStyle.top = `${
              this.mouseY - this.tooltipHeight - this.offsetY
            }px`;
        }
      }
      return baseStyle;
    },
  },
  watch: {
    modelValue: {
      handler(val) {
        if (val === true) {
          this.$nextTick(() => {
            this.tooltipWidth = this.$refs.refTooltip.offsetWidth;
            this.tooltipHeight = this.$refs.refTooltip.offsetHeight;
          });
        }
      },
      immediate: true,
    },
    positionStyles: {
      handler(val) {
        if (val) {
          this.$emit('position', val);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.attachMouseMoveEvent();
      const refActivator = this.$refs.refActivator;

      if (refActivator) {
        if (this.activatorBounds) {
          this.computedActivatorBounds = this.activatorBounds;
        } else {
          this.computedActivatorBounds = refActivator.getBoundingClientRect();
        }
      }
    });
  },

  beforeUnmount() {
    this.removeMouseMoveEvent();
  },
  methods: {
    handleMouseMove(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    attachMouseMoveEvent() {
      document.addEventListener('mousemove', this.handleMouseMove);
    },
    removeMouseMoveEvent() {
      document.removeEventListener('mousemove', this.handleMouseMove);
    },
  },
};
</script>

<style lang="scss">
.moving-popup {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
}

.fadeTooltip-enter-active,
.fadeTooltip-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fadeTooltip-enter-from,
.fadeTooltip-leave-to {
  opacity: 0;
}
</style>
